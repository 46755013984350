import { reactive } from 'vue';
import { DEFAULT_USER_AGENT, extractDevices } from '@airpaz/apz-js';
import { defineNuxtPlugin, useRequestHeaders } from '#app';

const makeFlags = (nuxtApp: any) => {
  let userAgent = DEFAULT_USER_AGENT;
  let mobileAppVersion;

  if (nuxtApp.ssrContext) {
    const headers = useRequestHeaders();

    userAgent = headers['user-agent'] || DEFAULT_USER_AGENT;
  } else if (typeof navigator !== 'undefined') {
    userAgent = navigator.userAgent || DEFAULT_USER_AGENT;
  }

  // incase useragent returns falsey value
  if (!userAgent) {
    userAgent = DEFAULT_USER_AGENT;
  }

  const extractedDevices = extractDevices(userAgent);

  if (extractedDevices.isAirpazWebView) {
    const versionMatch = userAgent.match(/.*(apzAndroid|apzIos)\/(\d+\.\d+\.\d+).*/);

    if (versionMatch && versionMatch.length > 2) {
      mobileAppVersion = versionMatch[2];
    }
  }

  return {
    userAgent,
    mobileAppVersion,
    ...extractedDevices,
    isCrawler: useAppConfig().noScripts || extractedDevices.isCrawler,
  };
};

export default defineNuxtPlugin((nuxtApp) => {
  const flags = reactive(makeFlags(nuxtApp));

  if (typeof window !== 'undefined') {
    window.addEventListener('resize', () => {
      setTimeout(() => {
        const newFlags = extractDevices(navigator.userAgent || DEFAULT_USER_AGENT);
        Object.entries(newFlags).forEach((entry) => {
          const [key, value] = entry;
          // @ts-expect-error
          flags[key] = value;
        });
      }, 50);
    });
  }

  return {
    provide: {
      device: flags,
    },
  };
});
