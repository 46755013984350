
// @ts-nocheck


export const localeCodes =  [
  "en",
  "id",
  "tl",
  "zh",
  "zh-tw",
  "th",
  "ja",
  "ms",
  "ko",
  "vi",
  "it",
  "de",
  "es",
  "hi",
  "fr",
  "ru",
  "bn",
  "pt",
  "km"
]

export const localeMessages = {
  "en": [{ key: "../locales/en.json", load: () => import("../locales/en.json" /* webpackChunkName: "locale__builds_apzfront_forerunner_next_apps_forerunner_locales_en_json" */), cache: true }],
  "id": [{ key: "../locales/id.json", load: () => import("../locales/id.json" /* webpackChunkName: "locale__builds_apzfront_forerunner_next_apps_forerunner_locales_id_json" */), cache: true }],
  "tl": [{ key: "../locales/tl.json", load: () => import("../locales/tl.json" /* webpackChunkName: "locale__builds_apzfront_forerunner_next_apps_forerunner_locales_tl_json" */), cache: true }],
  "zh": [{ key: "../locales/zh.json", load: () => import("../locales/zh.json" /* webpackChunkName: "locale__builds_apzfront_forerunner_next_apps_forerunner_locales_zh_json" */), cache: true }],
  "zh-tw": [{ key: "../locales/zh-tw.json", load: () => import("../locales/zh-tw.json" /* webpackChunkName: "locale__builds_apzfront_forerunner_next_apps_forerunner_locales_zh_tw_json" */), cache: true }],
  "th": [{ key: "../locales/th.json", load: () => import("../locales/th.json" /* webpackChunkName: "locale__builds_apzfront_forerunner_next_apps_forerunner_locales_th_json" */), cache: true }],
  "ja": [{ key: "../locales/ja.json", load: () => import("../locales/ja.json" /* webpackChunkName: "locale__builds_apzfront_forerunner_next_apps_forerunner_locales_ja_json" */), cache: true }],
  "ms": [{ key: "../locales/ms.json", load: () => import("../locales/ms.json" /* webpackChunkName: "locale__builds_apzfront_forerunner_next_apps_forerunner_locales_ms_json" */), cache: true }],
  "ko": [{ key: "../locales/ko.json", load: () => import("../locales/ko.json" /* webpackChunkName: "locale__builds_apzfront_forerunner_next_apps_forerunner_locales_ko_json" */), cache: true }],
  "vi": [{ key: "../locales/vi.json", load: () => import("../locales/vi.json" /* webpackChunkName: "locale__builds_apzfront_forerunner_next_apps_forerunner_locales_vi_json" */), cache: true }],
  "it": [{ key: "../locales/it.json", load: () => import("../locales/it.json" /* webpackChunkName: "locale__builds_apzfront_forerunner_next_apps_forerunner_locales_it_json" */), cache: true }],
  "de": [{ key: "../locales/de.json", load: () => import("../locales/de.json" /* webpackChunkName: "locale__builds_apzfront_forerunner_next_apps_forerunner_locales_de_json" */), cache: true }],
  "es": [{ key: "../locales/es.json", load: () => import("../locales/es.json" /* webpackChunkName: "locale__builds_apzfront_forerunner_next_apps_forerunner_locales_es_json" */), cache: true }],
  "hi": [{ key: "../locales/hi.json", load: () => import("../locales/hi.json" /* webpackChunkName: "locale__builds_apzfront_forerunner_next_apps_forerunner_locales_hi_json" */), cache: true }],
  "fr": [{ key: "../locales/fr.json", load: () => import("../locales/fr.json" /* webpackChunkName: "locale__builds_apzfront_forerunner_next_apps_forerunner_locales_fr_json" */), cache: true }],
  "ru": [{ key: "../locales/ru.json", load: () => import("../locales/ru.json" /* webpackChunkName: "locale__builds_apzfront_forerunner_next_apps_forerunner_locales_ru_json" */), cache: true }],
  "bn": [{ key: "../locales/bn.json", load: () => import("../locales/bn.json" /* webpackChunkName: "locale__builds_apzfront_forerunner_next_apps_forerunner_locales_bn_json" */), cache: true }],
  "pt": [{ key: "../locales/pt.json", load: () => import("../locales/pt.json" /* webpackChunkName: "locale__builds_apzfront_forerunner_next_apps_forerunner_locales_pt_json" */), cache: true }],
  "km": [{ key: "../locales/km.json", load: () => import("../locales/km.json" /* webpackChunkName: "locale__builds_apzfront_forerunner_next_apps_forerunner_locales_km_json" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: "i18n_config_bffaebcb" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": ""
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": false,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "en",
      "name": "English",
      "iso": "en-US",
      "active": true,
      "hreflang": "en",
      "flagCode": "gb",
      "files": [
        "locales/en.json"
      ]
    },
    {
      "code": "id",
      "name": "Indonesia",
      "iso": "id-ID",
      "active": true,
      "hreflang": "id",
      "flagCode": "id",
      "files": [
        "locales/id.json"
      ]
    },
    {
      "code": "tl",
      "name": "Tagalog",
      "iso": "tl-PH",
      "active": true,
      "hreflang": "tl",
      "flagCode": "ph",
      "files": [
        "locales/tl.json"
      ]
    },
    {
      "code": "zh",
      "name": "简体中文",
      "iso": "zh-CN",
      "active": true,
      "hreflang": "zh-Hans",
      "flagCode": "cn",
      "files": [
        "locales/zh.json"
      ]
    },
    {
      "code": "zh-tw",
      "name": "繁體中文",
      "iso": "zh-TW",
      "active": true,
      "hreflang": "zh-Hant",
      "flagCode": "tw",
      "files": [
        "locales/zh-tw.json"
      ]
    },
    {
      "code": "th",
      "name": "ภาษาไทย",
      "iso": "th-TH",
      "active": true,
      "hreflang": "th",
      "flagCode": "th",
      "files": [
        "locales/th.json"
      ]
    },
    {
      "code": "ja",
      "name": "日本語",
      "iso": "ja-JP",
      "active": true,
      "hreflang": "ja",
      "flagCode": "jp",
      "files": [
        "locales/ja.json"
      ]
    },
    {
      "code": "ms",
      "name": "Malay",
      "iso": "ms-MY",
      "active": true,
      "hreflang": "ms",
      "flagCode": "my",
      "files": [
        "locales/ms.json"
      ]
    },
    {
      "code": "ko",
      "name": "한국어",
      "iso": "ko-KR",
      "active": true,
      "hreflang": "ko",
      "flagCode": "kr",
      "files": [
        "locales/ko.json"
      ]
    },
    {
      "code": "vi",
      "name": "Tiếng Việt",
      "iso": "vi-VI",
      "active": true,
      "hreflang": "vi",
      "flagCode": "vn",
      "files": [
        "locales/vi.json"
      ]
    },
    {
      "code": "it",
      "name": "Italiano",
      "iso": "it-IT",
      "active": true,
      "hreflang": "it",
      "flagCode": "it",
      "files": [
        "locales/it.json"
      ]
    },
    {
      "code": "de",
      "name": "Deutsch",
      "iso": "de-DE",
      "active": true,
      "hreflang": "de",
      "flagCode": "de",
      "files": [
        "locales/de.json"
      ]
    },
    {
      "code": "es",
      "name": "Español",
      "iso": "es-ES",
      "active": true,
      "hreflang": "es",
      "flagCode": "es",
      "files": [
        "locales/es.json"
      ]
    },
    {
      "code": "hi",
      "name": "हिन्दी",
      "iso": "hi-IN",
      "active": true,
      "hreflang": "hi",
      "flagCode": "in",
      "files": [
        "locales/hi.json"
      ]
    },
    {
      "code": "fr",
      "name": "Français",
      "iso": "fr-FR",
      "active": true,
      "hreflang": "fr",
      "flagCode": "fr",
      "files": [
        "locales/fr.json"
      ]
    },
    {
      "code": "ru",
      "name": "Русский",
      "iso": "ru-RU",
      "active": true,
      "hreflang": "ru",
      "flagCode": "ru",
      "files": [
        "locales/ru.json"
      ]
    },
    {
      "code": "bn",
      "name": "বাংলা",
      "iso": "bn-BN",
      "active": true,
      "hreflang": "bn",
      "flagCode": "bd",
      "files": [
        "locales/bn.json"
      ]
    },
    {
      "code": "pt",
      "name": "Português",
      "iso": "pt-PT",
      "active": true,
      "hreflang": "pt",
      "flagCode": "pt",
      "files": [
        "locales/pt.json"
      ]
    },
    {
      "code": "km",
      "name": "ភាសាខ្មែរ",
      "iso": "km-KH",
      "active": true,
      "hreflang": "km",
      "flagCode": "kh",
      "files": [
        "locales/km.json"
      ]
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix",
  "lazy": true,
  "langDir": "locales",
  "rootRedirect": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "Language_code",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const nuxtI18nOptionsDefault = {
  "experimental": {
    "localeDetector": ""
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [],
  "defaultLocale": "",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": null,
  "rootRedirect": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false
}

export const nuxtI18nInternalOptions = {
  "__normalizedLocales": [
    {
      "code": "en",
      "name": "English",
      "iso": "en-US",
      "active": true,
      "hreflang": "en",
      "flagCode": "gb",
      "files": [
        {
          "path": "locales/en.json"
        }
      ]
    },
    {
      "code": "id",
      "name": "Indonesia",
      "iso": "id-ID",
      "active": true,
      "hreflang": "id",
      "flagCode": "id",
      "files": [
        {
          "path": "locales/id.json"
        }
      ]
    },
    {
      "code": "tl",
      "name": "Tagalog",
      "iso": "tl-PH",
      "active": true,
      "hreflang": "tl",
      "flagCode": "ph",
      "files": [
        {
          "path": "locales/tl.json"
        }
      ]
    },
    {
      "code": "zh",
      "name": "简体中文",
      "iso": "zh-CN",
      "active": true,
      "hreflang": "zh-Hans",
      "flagCode": "cn",
      "files": [
        {
          "path": "locales/zh.json"
        }
      ]
    },
    {
      "code": "zh-tw",
      "name": "繁體中文",
      "iso": "zh-TW",
      "active": true,
      "hreflang": "zh-Hant",
      "flagCode": "tw",
      "files": [
        {
          "path": "locales/zh-tw.json"
        }
      ]
    },
    {
      "code": "th",
      "name": "ภาษาไทย",
      "iso": "th-TH",
      "active": true,
      "hreflang": "th",
      "flagCode": "th",
      "files": [
        {
          "path": "locales/th.json"
        }
      ]
    },
    {
      "code": "ja",
      "name": "日本語",
      "iso": "ja-JP",
      "active": true,
      "hreflang": "ja",
      "flagCode": "jp",
      "files": [
        {
          "path": "locales/ja.json"
        }
      ]
    },
    {
      "code": "ms",
      "name": "Malay",
      "iso": "ms-MY",
      "active": true,
      "hreflang": "ms",
      "flagCode": "my",
      "files": [
        {
          "path": "locales/ms.json"
        }
      ]
    },
    {
      "code": "ko",
      "name": "한국어",
      "iso": "ko-KR",
      "active": true,
      "hreflang": "ko",
      "flagCode": "kr",
      "files": [
        {
          "path": "locales/ko.json"
        }
      ]
    },
    {
      "code": "vi",
      "name": "Tiếng Việt",
      "iso": "vi-VI",
      "active": true,
      "hreflang": "vi",
      "flagCode": "vn",
      "files": [
        {
          "path": "locales/vi.json"
        }
      ]
    },
    {
      "code": "it",
      "name": "Italiano",
      "iso": "it-IT",
      "active": true,
      "hreflang": "it",
      "flagCode": "it",
      "files": [
        {
          "path": "locales/it.json"
        }
      ]
    },
    {
      "code": "de",
      "name": "Deutsch",
      "iso": "de-DE",
      "active": true,
      "hreflang": "de",
      "flagCode": "de",
      "files": [
        {
          "path": "locales/de.json"
        }
      ]
    },
    {
      "code": "es",
      "name": "Español",
      "iso": "es-ES",
      "active": true,
      "hreflang": "es",
      "flagCode": "es",
      "files": [
        {
          "path": "locales/es.json"
        }
      ]
    },
    {
      "code": "hi",
      "name": "हिन्दी",
      "iso": "hi-IN",
      "active": true,
      "hreflang": "hi",
      "flagCode": "in",
      "files": [
        {
          "path": "locales/hi.json"
        }
      ]
    },
    {
      "code": "fr",
      "name": "Français",
      "iso": "fr-FR",
      "active": true,
      "hreflang": "fr",
      "flagCode": "fr",
      "files": [
        {
          "path": "locales/fr.json"
        }
      ]
    },
    {
      "code": "ru",
      "name": "Русский",
      "iso": "ru-RU",
      "active": true,
      "hreflang": "ru",
      "flagCode": "ru",
      "files": [
        {
          "path": "locales/ru.json"
        }
      ]
    },
    {
      "code": "bn",
      "name": "বাংলা",
      "iso": "bn-BN",
      "active": true,
      "hreflang": "bn",
      "flagCode": "bd",
      "files": [
        {
          "path": "locales/bn.json"
        }
      ]
    },
    {
      "code": "pt",
      "name": "Português",
      "iso": "pt-PT",
      "active": true,
      "hreflang": "pt",
      "flagCode": "pt",
      "files": [
        {
          "path": "locales/pt.json"
        }
      ]
    },
    {
      "code": "km",
      "name": "ភាសាខ្មែរ",
      "iso": "km-KH",
      "active": true,
      "hreflang": "km",
      "flagCode": "kh",
      "files": [
        {
          "path": "locales/km.json"
        }
      ]
    }
  ]
}

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

