import { default as bindpdCQS5y7TsMeta } from "/builds/apzfront/forerunner-next/apps/forerunner/pages/account/bind.vue?macro=true";
import { default as contactIiSWtlHrFLMeta } from "/builds/apzfront/forerunner-next/apps/forerunner/pages/account/contact.vue?macro=true";
import { default as indexgu9WIN7ZNpMeta } from "/builds/apzfront/forerunner-next/apps/forerunner/pages/account/index.vue?macro=true";
import { default as _91id_93c7oXkpS8NkMeta } from "/builds/apzfront/forerunner-next/apps/forerunner/pages/account/order/[id].vue?macro=true";
import { default as index86Eynsn1ZEMeta } from "/builds/apzfront/forerunner-next/apps/forerunner/pages/account/order/index.vue?macro=true";
import { default as passengerfYuXKjcNLXMeta } from "/builds/apzfront/forerunner-next/apps/forerunner/pages/account/passenger.vue?macro=true";
import { default as passwordLk94Ld215CMeta } from "/builds/apzfront/forerunner-next/apps/forerunner/pages/account/password.vue?macro=true";
import { default as profilePWDGVhZiJnMeta } from "/builds/apzfront/forerunner-next/apps/forerunner/pages/account/profile.vue?macro=true";
import { default as verifyCn5M7XrWpdMeta } from "/builds/apzfront/forerunner-next/apps/forerunner/pages/account/verify.vue?macro=true";
import { default as searchWnar8loo4vMeta } from "/builds/apzfront/forerunner-next/apps/forerunner/pages/aftersales/change-flight/submission/[id]/search.vue?macro=true";
import { default as callbackb3kNIrFnr3Meta } from "/builds/apzfront/forerunner-next/apps/forerunner/pages/callback.vue?macro=true";
import { default as _91slug_93JrpukDr55PMeta } from "/builds/apzfront/forerunner-next/apps/forerunner/pages/campaign/[slug].vue?macro=true";
import { default as choose_45countrypzaL9LRzvcMeta } from "/builds/apzfront/forerunner-next/apps/forerunner/pages/choose-country.vue?macro=true";
import { default as contactVUzjXKaIJBMeta } from "/builds/apzfront/forerunner-next/apps/forerunner/pages/contact.vue?macro=true";
import { default as covid19Lv4bLhZsLoMeta } from "/builds/apzfront/forerunner-next/apps/forerunner/pages/covid19.vue?macro=true";
import { default as feedback_45formN32B0TDKx7Meta } from "/builds/apzfront/forerunner-next/apps/forerunner/pages/feedback-form.vue?macro=true";
import { default as _91product_93W4po7kMCUGMeta } from "/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/add-ons/[bookId]/[product].vue?macro=true";
import { default as addonsxGvSqqpWMJMeta } from "/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/book/[bookId]/addons.vue?macro=true";
import { default as _91bookId_931qcML42IWFMeta } from "/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/book/[bookId].vue?macro=true";
import { default as confirmXVvIoONM9TMeta } from "/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/confirm.vue?macro=true";
import { default as index9mIihp7HJuMeta } from "/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/index.vue?macro=true";
import { default as searchAWx19CheQIMeta } from "/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/search.vue?macro=true";
import { default as indexHhCswK9muAMeta } from "/builds/apzfront/forerunner-next/apps/forerunner/pages/hotel/index.vue?macro=true";
import { default as searchkKqQkgrNV4Meta } from "/builds/apzfront/forerunner-next/apps/forerunner/pages/hotel/search.vue?macro=true";
import { default as indexSRNzu9hqO1Meta } from "/builds/apzfront/forerunner-next/apps/forerunner/pages/index.vue?macro=true";
import { default as loginzoZaqp9bwhMeta } from "/builds/apzfront/forerunner-next/apps/forerunner/pages/login.vue?macro=true";
import { default as change_45language88w8UPIbuSMeta } from "/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/change-language.vue?macro=true";
import { default as choose_45languageCQ2OAWMLzMMeta } from "/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/choose-language.vue?macro=true";
import { default as confirmGmI15blOX4Meta } from "/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/confirm.vue?macro=true";
import { default as unsubscribebGe4WqbNPqMeta } from "/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/unsubscribe.vue?macro=true";
import { default as _91id_93QnZQgI7asXMeta } from "/builds/apzfront/forerunner-next/apps/forerunner/pages/order/[id].vue?macro=true";
import { default as indexZtmiVKQgnmMeta } from "/builds/apzfront/forerunner-next/apps/forerunner/pages/order/index.vue?macro=true";
import { default as chooseVxa9axX1xlMeta } from "/builds/apzfront/forerunner-next/apps/forerunner/pages/pay/[trxId]/choose.vue?macro=true";
import { default as confirmationOPKl7x3dpeMeta } from "/builds/apzfront/forerunner-next/apps/forerunner/pages/pay/[trxId]/confirmation.vue?macro=true";
import { default as index45SXYRSvQJMeta } from "/builds/apzfront/forerunner-next/apps/forerunner/pages/promo/index.vue?macro=true";
import { default as _91slug_93ZdlPfNpBvoMeta } from "/builds/apzfront/forerunner-next/apps/forerunner/pages/promo/view/[slug].vue?macro=true";
import { default as special_45dealsnsdu79FbGBMeta } from "/builds/apzfront/forerunner-next/apps/forerunner/pages/promo/view/special-deals.vue?macro=true";
import { default as indexc5deQbmapLMeta } from "/builds/apzfront/forerunner-next/apps/forerunner/pages/reset/index.vue?macro=true";
import { default as _91id_93d32fzd0qB7Meta } from "/builds/apzfront/forerunner-next/apps/forerunner/pages/reset/pass/[id].vue?macro=true";
import { default as verifyb8gtl2X2mrMeta } from "/builds/apzfront/forerunner-next/apps/forerunner/pages/reset/verify.vue?macro=true";
import { default as indexI7wQ2nd8wsMeta } from "/builds/apzfront/forerunner-next/apps/forerunner/pages/retrieve/index.vue?macro=true";
import { default as listpGuopMuAdqMeta } from "/builds/apzfront/forerunner-next/apps/forerunner/pages/retrieve/list.vue?macro=true";
import { default as indexaQHliZDDu0Meta } from "/builds/apzfront/forerunner-next/apps/forerunner/pages/signup/index.vue?macro=true";
import { default as setup_45accountNy6ihyASIcMeta } from "/builds/apzfront/forerunner-next/apps/forerunner/pages/signup/setup-account.vue?macro=true";
import { default as verifyTSeTbB3oi1Meta } from "/builds/apzfront/forerunner-next/apps/forerunner/pages/signup/verify.vue?macro=true";
import { default as conditionOyyjgMj6mtMeta } from "/builds/apzfront/forerunner-next/apps/forerunner/pages/terms/condition.vue?macro=true";
import { default as uses5hATvK7yDoMeta } from "/builds/apzfront/forerunner-next/apps/forerunner/pages/terms/uses.vue?macro=true";
export default [
  {
    name: bindpdCQS5y7TsMeta?.name ?? "account-bind___en",
    path: bindpdCQS5y7TsMeta?.path ?? "/en/account/bind",
    meta: bindpdCQS5y7TsMeta || {},
    alias: bindpdCQS5y7TsMeta?.alias || [],
    redirect: bindpdCQS5y7TsMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/bind.vue").then(m => m.default || m)
  },
  {
    name: bindpdCQS5y7TsMeta?.name ?? "account-bind___id",
    path: bindpdCQS5y7TsMeta?.path ?? "/id/account/bind",
    meta: bindpdCQS5y7TsMeta || {},
    alias: bindpdCQS5y7TsMeta?.alias || [],
    redirect: bindpdCQS5y7TsMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/bind.vue").then(m => m.default || m)
  },
  {
    name: bindpdCQS5y7TsMeta?.name ?? "account-bind___tl",
    path: bindpdCQS5y7TsMeta?.path ?? "/tl/account/bind",
    meta: bindpdCQS5y7TsMeta || {},
    alias: bindpdCQS5y7TsMeta?.alias || [],
    redirect: bindpdCQS5y7TsMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/bind.vue").then(m => m.default || m)
  },
  {
    name: bindpdCQS5y7TsMeta?.name ?? "account-bind___zh",
    path: bindpdCQS5y7TsMeta?.path ?? "/zh/account/bind",
    meta: bindpdCQS5y7TsMeta || {},
    alias: bindpdCQS5y7TsMeta?.alias || [],
    redirect: bindpdCQS5y7TsMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/bind.vue").then(m => m.default || m)
  },
  {
    name: bindpdCQS5y7TsMeta?.name ?? "account-bind___zh-tw",
    path: bindpdCQS5y7TsMeta?.path ?? "/zh-tw/account/bind",
    meta: bindpdCQS5y7TsMeta || {},
    alias: bindpdCQS5y7TsMeta?.alias || [],
    redirect: bindpdCQS5y7TsMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/bind.vue").then(m => m.default || m)
  },
  {
    name: bindpdCQS5y7TsMeta?.name ?? "account-bind___th",
    path: bindpdCQS5y7TsMeta?.path ?? "/th/account/bind",
    meta: bindpdCQS5y7TsMeta || {},
    alias: bindpdCQS5y7TsMeta?.alias || [],
    redirect: bindpdCQS5y7TsMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/bind.vue").then(m => m.default || m)
  },
  {
    name: bindpdCQS5y7TsMeta?.name ?? "account-bind___ja",
    path: bindpdCQS5y7TsMeta?.path ?? "/ja/account/bind",
    meta: bindpdCQS5y7TsMeta || {},
    alias: bindpdCQS5y7TsMeta?.alias || [],
    redirect: bindpdCQS5y7TsMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/bind.vue").then(m => m.default || m)
  },
  {
    name: bindpdCQS5y7TsMeta?.name ?? "account-bind___ms",
    path: bindpdCQS5y7TsMeta?.path ?? "/ms/account/bind",
    meta: bindpdCQS5y7TsMeta || {},
    alias: bindpdCQS5y7TsMeta?.alias || [],
    redirect: bindpdCQS5y7TsMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/bind.vue").then(m => m.default || m)
  },
  {
    name: bindpdCQS5y7TsMeta?.name ?? "account-bind___ko",
    path: bindpdCQS5y7TsMeta?.path ?? "/ko/account/bind",
    meta: bindpdCQS5y7TsMeta || {},
    alias: bindpdCQS5y7TsMeta?.alias || [],
    redirect: bindpdCQS5y7TsMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/bind.vue").then(m => m.default || m)
  },
  {
    name: bindpdCQS5y7TsMeta?.name ?? "account-bind___vi",
    path: bindpdCQS5y7TsMeta?.path ?? "/vi/account/bind",
    meta: bindpdCQS5y7TsMeta || {},
    alias: bindpdCQS5y7TsMeta?.alias || [],
    redirect: bindpdCQS5y7TsMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/bind.vue").then(m => m.default || m)
  },
  {
    name: bindpdCQS5y7TsMeta?.name ?? "account-bind___it",
    path: bindpdCQS5y7TsMeta?.path ?? "/it/account/bind",
    meta: bindpdCQS5y7TsMeta || {},
    alias: bindpdCQS5y7TsMeta?.alias || [],
    redirect: bindpdCQS5y7TsMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/bind.vue").then(m => m.default || m)
  },
  {
    name: bindpdCQS5y7TsMeta?.name ?? "account-bind___de",
    path: bindpdCQS5y7TsMeta?.path ?? "/de/account/bind",
    meta: bindpdCQS5y7TsMeta || {},
    alias: bindpdCQS5y7TsMeta?.alias || [],
    redirect: bindpdCQS5y7TsMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/bind.vue").then(m => m.default || m)
  },
  {
    name: bindpdCQS5y7TsMeta?.name ?? "account-bind___es",
    path: bindpdCQS5y7TsMeta?.path ?? "/es/account/bind",
    meta: bindpdCQS5y7TsMeta || {},
    alias: bindpdCQS5y7TsMeta?.alias || [],
    redirect: bindpdCQS5y7TsMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/bind.vue").then(m => m.default || m)
  },
  {
    name: bindpdCQS5y7TsMeta?.name ?? "account-bind___hi",
    path: bindpdCQS5y7TsMeta?.path ?? "/hi/account/bind",
    meta: bindpdCQS5y7TsMeta || {},
    alias: bindpdCQS5y7TsMeta?.alias || [],
    redirect: bindpdCQS5y7TsMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/bind.vue").then(m => m.default || m)
  },
  {
    name: bindpdCQS5y7TsMeta?.name ?? "account-bind___fr",
    path: bindpdCQS5y7TsMeta?.path ?? "/fr/account/bind",
    meta: bindpdCQS5y7TsMeta || {},
    alias: bindpdCQS5y7TsMeta?.alias || [],
    redirect: bindpdCQS5y7TsMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/bind.vue").then(m => m.default || m)
  },
  {
    name: bindpdCQS5y7TsMeta?.name ?? "account-bind___ru",
    path: bindpdCQS5y7TsMeta?.path ?? "/ru/account/bind",
    meta: bindpdCQS5y7TsMeta || {},
    alias: bindpdCQS5y7TsMeta?.alias || [],
    redirect: bindpdCQS5y7TsMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/bind.vue").then(m => m.default || m)
  },
  {
    name: bindpdCQS5y7TsMeta?.name ?? "account-bind___bn",
    path: bindpdCQS5y7TsMeta?.path ?? "/bn/account/bind",
    meta: bindpdCQS5y7TsMeta || {},
    alias: bindpdCQS5y7TsMeta?.alias || [],
    redirect: bindpdCQS5y7TsMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/bind.vue").then(m => m.default || m)
  },
  {
    name: bindpdCQS5y7TsMeta?.name ?? "account-bind___pt",
    path: bindpdCQS5y7TsMeta?.path ?? "/pt/account/bind",
    meta: bindpdCQS5y7TsMeta || {},
    alias: bindpdCQS5y7TsMeta?.alias || [],
    redirect: bindpdCQS5y7TsMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/bind.vue").then(m => m.default || m)
  },
  {
    name: bindpdCQS5y7TsMeta?.name ?? "account-bind___km",
    path: bindpdCQS5y7TsMeta?.path ?? "/km/account/bind",
    meta: bindpdCQS5y7TsMeta || {},
    alias: bindpdCQS5y7TsMeta?.alias || [],
    redirect: bindpdCQS5y7TsMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/bind.vue").then(m => m.default || m)
  },
  {
    name: contactIiSWtlHrFLMeta?.name ?? "account-contact___en",
    path: contactIiSWtlHrFLMeta?.path ?? "/en/account/contact",
    meta: contactIiSWtlHrFLMeta || {},
    alias: contactIiSWtlHrFLMeta?.alias || [],
    redirect: contactIiSWtlHrFLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/contact.vue").then(m => m.default || m)
  },
  {
    name: contactIiSWtlHrFLMeta?.name ?? "account-contact___id",
    path: contactIiSWtlHrFLMeta?.path ?? "/id/account/contact",
    meta: contactIiSWtlHrFLMeta || {},
    alias: contactIiSWtlHrFLMeta?.alias || [],
    redirect: contactIiSWtlHrFLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/contact.vue").then(m => m.default || m)
  },
  {
    name: contactIiSWtlHrFLMeta?.name ?? "account-contact___tl",
    path: contactIiSWtlHrFLMeta?.path ?? "/tl/account/contact",
    meta: contactIiSWtlHrFLMeta || {},
    alias: contactIiSWtlHrFLMeta?.alias || [],
    redirect: contactIiSWtlHrFLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/contact.vue").then(m => m.default || m)
  },
  {
    name: contactIiSWtlHrFLMeta?.name ?? "account-contact___zh",
    path: contactIiSWtlHrFLMeta?.path ?? "/zh/account/contact",
    meta: contactIiSWtlHrFLMeta || {},
    alias: contactIiSWtlHrFLMeta?.alias || [],
    redirect: contactIiSWtlHrFLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/contact.vue").then(m => m.default || m)
  },
  {
    name: contactIiSWtlHrFLMeta?.name ?? "account-contact___zh-tw",
    path: contactIiSWtlHrFLMeta?.path ?? "/zh-tw/account/contact",
    meta: contactIiSWtlHrFLMeta || {},
    alias: contactIiSWtlHrFLMeta?.alias || [],
    redirect: contactIiSWtlHrFLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/contact.vue").then(m => m.default || m)
  },
  {
    name: contactIiSWtlHrFLMeta?.name ?? "account-contact___th",
    path: contactIiSWtlHrFLMeta?.path ?? "/th/account/contact",
    meta: contactIiSWtlHrFLMeta || {},
    alias: contactIiSWtlHrFLMeta?.alias || [],
    redirect: contactIiSWtlHrFLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/contact.vue").then(m => m.default || m)
  },
  {
    name: contactIiSWtlHrFLMeta?.name ?? "account-contact___ja",
    path: contactIiSWtlHrFLMeta?.path ?? "/ja/account/contact",
    meta: contactIiSWtlHrFLMeta || {},
    alias: contactIiSWtlHrFLMeta?.alias || [],
    redirect: contactIiSWtlHrFLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/contact.vue").then(m => m.default || m)
  },
  {
    name: contactIiSWtlHrFLMeta?.name ?? "account-contact___ms",
    path: contactIiSWtlHrFLMeta?.path ?? "/ms/account/contact",
    meta: contactIiSWtlHrFLMeta || {},
    alias: contactIiSWtlHrFLMeta?.alias || [],
    redirect: contactIiSWtlHrFLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/contact.vue").then(m => m.default || m)
  },
  {
    name: contactIiSWtlHrFLMeta?.name ?? "account-contact___ko",
    path: contactIiSWtlHrFLMeta?.path ?? "/ko/account/contact",
    meta: contactIiSWtlHrFLMeta || {},
    alias: contactIiSWtlHrFLMeta?.alias || [],
    redirect: contactIiSWtlHrFLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/contact.vue").then(m => m.default || m)
  },
  {
    name: contactIiSWtlHrFLMeta?.name ?? "account-contact___vi",
    path: contactIiSWtlHrFLMeta?.path ?? "/vi/account/contact",
    meta: contactIiSWtlHrFLMeta || {},
    alias: contactIiSWtlHrFLMeta?.alias || [],
    redirect: contactIiSWtlHrFLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/contact.vue").then(m => m.default || m)
  },
  {
    name: contactIiSWtlHrFLMeta?.name ?? "account-contact___it",
    path: contactIiSWtlHrFLMeta?.path ?? "/it/account/contact",
    meta: contactIiSWtlHrFLMeta || {},
    alias: contactIiSWtlHrFLMeta?.alias || [],
    redirect: contactIiSWtlHrFLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/contact.vue").then(m => m.default || m)
  },
  {
    name: contactIiSWtlHrFLMeta?.name ?? "account-contact___de",
    path: contactIiSWtlHrFLMeta?.path ?? "/de/account/contact",
    meta: contactIiSWtlHrFLMeta || {},
    alias: contactIiSWtlHrFLMeta?.alias || [],
    redirect: contactIiSWtlHrFLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/contact.vue").then(m => m.default || m)
  },
  {
    name: contactIiSWtlHrFLMeta?.name ?? "account-contact___es",
    path: contactIiSWtlHrFLMeta?.path ?? "/es/account/contact",
    meta: contactIiSWtlHrFLMeta || {},
    alias: contactIiSWtlHrFLMeta?.alias || [],
    redirect: contactIiSWtlHrFLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/contact.vue").then(m => m.default || m)
  },
  {
    name: contactIiSWtlHrFLMeta?.name ?? "account-contact___hi",
    path: contactIiSWtlHrFLMeta?.path ?? "/hi/account/contact",
    meta: contactIiSWtlHrFLMeta || {},
    alias: contactIiSWtlHrFLMeta?.alias || [],
    redirect: contactIiSWtlHrFLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/contact.vue").then(m => m.default || m)
  },
  {
    name: contactIiSWtlHrFLMeta?.name ?? "account-contact___fr",
    path: contactIiSWtlHrFLMeta?.path ?? "/fr/account/contact",
    meta: contactIiSWtlHrFLMeta || {},
    alias: contactIiSWtlHrFLMeta?.alias || [],
    redirect: contactIiSWtlHrFLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/contact.vue").then(m => m.default || m)
  },
  {
    name: contactIiSWtlHrFLMeta?.name ?? "account-contact___ru",
    path: contactIiSWtlHrFLMeta?.path ?? "/ru/account/contact",
    meta: contactIiSWtlHrFLMeta || {},
    alias: contactIiSWtlHrFLMeta?.alias || [],
    redirect: contactIiSWtlHrFLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/contact.vue").then(m => m.default || m)
  },
  {
    name: contactIiSWtlHrFLMeta?.name ?? "account-contact___bn",
    path: contactIiSWtlHrFLMeta?.path ?? "/bn/account/contact",
    meta: contactIiSWtlHrFLMeta || {},
    alias: contactIiSWtlHrFLMeta?.alias || [],
    redirect: contactIiSWtlHrFLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/contact.vue").then(m => m.default || m)
  },
  {
    name: contactIiSWtlHrFLMeta?.name ?? "account-contact___pt",
    path: contactIiSWtlHrFLMeta?.path ?? "/pt/account/contact",
    meta: contactIiSWtlHrFLMeta || {},
    alias: contactIiSWtlHrFLMeta?.alias || [],
    redirect: contactIiSWtlHrFLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/contact.vue").then(m => m.default || m)
  },
  {
    name: contactIiSWtlHrFLMeta?.name ?? "account-contact___km",
    path: contactIiSWtlHrFLMeta?.path ?? "/km/account/contact",
    meta: contactIiSWtlHrFLMeta || {},
    alias: contactIiSWtlHrFLMeta?.alias || [],
    redirect: contactIiSWtlHrFLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/contact.vue").then(m => m.default || m)
  },
  {
    name: indexgu9WIN7ZNpMeta?.name ?? "account___en",
    path: indexgu9WIN7ZNpMeta?.path ?? "/en/account",
    meta: indexgu9WIN7ZNpMeta || {},
    alias: indexgu9WIN7ZNpMeta?.alias || [],
    redirect: indexgu9WIN7ZNpMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexgu9WIN7ZNpMeta?.name ?? "account___id",
    path: indexgu9WIN7ZNpMeta?.path ?? "/id/account",
    meta: indexgu9WIN7ZNpMeta || {},
    alias: indexgu9WIN7ZNpMeta?.alias || [],
    redirect: indexgu9WIN7ZNpMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexgu9WIN7ZNpMeta?.name ?? "account___tl",
    path: indexgu9WIN7ZNpMeta?.path ?? "/tl/account",
    meta: indexgu9WIN7ZNpMeta || {},
    alias: indexgu9WIN7ZNpMeta?.alias || [],
    redirect: indexgu9WIN7ZNpMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexgu9WIN7ZNpMeta?.name ?? "account___zh",
    path: indexgu9WIN7ZNpMeta?.path ?? "/zh/account",
    meta: indexgu9WIN7ZNpMeta || {},
    alias: indexgu9WIN7ZNpMeta?.alias || [],
    redirect: indexgu9WIN7ZNpMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexgu9WIN7ZNpMeta?.name ?? "account___zh-tw",
    path: indexgu9WIN7ZNpMeta?.path ?? "/zh-tw/account",
    meta: indexgu9WIN7ZNpMeta || {},
    alias: indexgu9WIN7ZNpMeta?.alias || [],
    redirect: indexgu9WIN7ZNpMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexgu9WIN7ZNpMeta?.name ?? "account___th",
    path: indexgu9WIN7ZNpMeta?.path ?? "/th/account",
    meta: indexgu9WIN7ZNpMeta || {},
    alias: indexgu9WIN7ZNpMeta?.alias || [],
    redirect: indexgu9WIN7ZNpMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexgu9WIN7ZNpMeta?.name ?? "account___ja",
    path: indexgu9WIN7ZNpMeta?.path ?? "/ja/account",
    meta: indexgu9WIN7ZNpMeta || {},
    alias: indexgu9WIN7ZNpMeta?.alias || [],
    redirect: indexgu9WIN7ZNpMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexgu9WIN7ZNpMeta?.name ?? "account___ms",
    path: indexgu9WIN7ZNpMeta?.path ?? "/ms/account",
    meta: indexgu9WIN7ZNpMeta || {},
    alias: indexgu9WIN7ZNpMeta?.alias || [],
    redirect: indexgu9WIN7ZNpMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexgu9WIN7ZNpMeta?.name ?? "account___ko",
    path: indexgu9WIN7ZNpMeta?.path ?? "/ko/account",
    meta: indexgu9WIN7ZNpMeta || {},
    alias: indexgu9WIN7ZNpMeta?.alias || [],
    redirect: indexgu9WIN7ZNpMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexgu9WIN7ZNpMeta?.name ?? "account___vi",
    path: indexgu9WIN7ZNpMeta?.path ?? "/vi/account",
    meta: indexgu9WIN7ZNpMeta || {},
    alias: indexgu9WIN7ZNpMeta?.alias || [],
    redirect: indexgu9WIN7ZNpMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexgu9WIN7ZNpMeta?.name ?? "account___it",
    path: indexgu9WIN7ZNpMeta?.path ?? "/it/account",
    meta: indexgu9WIN7ZNpMeta || {},
    alias: indexgu9WIN7ZNpMeta?.alias || [],
    redirect: indexgu9WIN7ZNpMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexgu9WIN7ZNpMeta?.name ?? "account___de",
    path: indexgu9WIN7ZNpMeta?.path ?? "/de/account",
    meta: indexgu9WIN7ZNpMeta || {},
    alias: indexgu9WIN7ZNpMeta?.alias || [],
    redirect: indexgu9WIN7ZNpMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexgu9WIN7ZNpMeta?.name ?? "account___es",
    path: indexgu9WIN7ZNpMeta?.path ?? "/es/account",
    meta: indexgu9WIN7ZNpMeta || {},
    alias: indexgu9WIN7ZNpMeta?.alias || [],
    redirect: indexgu9WIN7ZNpMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexgu9WIN7ZNpMeta?.name ?? "account___hi",
    path: indexgu9WIN7ZNpMeta?.path ?? "/hi/account",
    meta: indexgu9WIN7ZNpMeta || {},
    alias: indexgu9WIN7ZNpMeta?.alias || [],
    redirect: indexgu9WIN7ZNpMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexgu9WIN7ZNpMeta?.name ?? "account___fr",
    path: indexgu9WIN7ZNpMeta?.path ?? "/fr/account",
    meta: indexgu9WIN7ZNpMeta || {},
    alias: indexgu9WIN7ZNpMeta?.alias || [],
    redirect: indexgu9WIN7ZNpMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexgu9WIN7ZNpMeta?.name ?? "account___ru",
    path: indexgu9WIN7ZNpMeta?.path ?? "/ru/account",
    meta: indexgu9WIN7ZNpMeta || {},
    alias: indexgu9WIN7ZNpMeta?.alias || [],
    redirect: indexgu9WIN7ZNpMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexgu9WIN7ZNpMeta?.name ?? "account___bn",
    path: indexgu9WIN7ZNpMeta?.path ?? "/bn/account",
    meta: indexgu9WIN7ZNpMeta || {},
    alias: indexgu9WIN7ZNpMeta?.alias || [],
    redirect: indexgu9WIN7ZNpMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexgu9WIN7ZNpMeta?.name ?? "account___pt",
    path: indexgu9WIN7ZNpMeta?.path ?? "/pt/account",
    meta: indexgu9WIN7ZNpMeta || {},
    alias: indexgu9WIN7ZNpMeta?.alias || [],
    redirect: indexgu9WIN7ZNpMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexgu9WIN7ZNpMeta?.name ?? "account___km",
    path: indexgu9WIN7ZNpMeta?.path ?? "/km/account",
    meta: indexgu9WIN7ZNpMeta || {},
    alias: indexgu9WIN7ZNpMeta?.alias || [],
    redirect: indexgu9WIN7ZNpMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93c7oXkpS8NkMeta?.name ?? "account-order-id___en",
    path: _91id_93c7oXkpS8NkMeta?.path ?? "/en/account/order/:id()",
    meta: _91id_93c7oXkpS8NkMeta || {},
    alias: _91id_93c7oXkpS8NkMeta?.alias || [],
    redirect: _91id_93c7oXkpS8NkMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/order/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93c7oXkpS8NkMeta?.name ?? "account-order-id___id",
    path: _91id_93c7oXkpS8NkMeta?.path ?? "/id/account/order/:id()",
    meta: _91id_93c7oXkpS8NkMeta || {},
    alias: _91id_93c7oXkpS8NkMeta?.alias || [],
    redirect: _91id_93c7oXkpS8NkMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/order/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93c7oXkpS8NkMeta?.name ?? "account-order-id___tl",
    path: _91id_93c7oXkpS8NkMeta?.path ?? "/tl/account/order/:id()",
    meta: _91id_93c7oXkpS8NkMeta || {},
    alias: _91id_93c7oXkpS8NkMeta?.alias || [],
    redirect: _91id_93c7oXkpS8NkMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/order/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93c7oXkpS8NkMeta?.name ?? "account-order-id___zh",
    path: _91id_93c7oXkpS8NkMeta?.path ?? "/zh/account/order/:id()",
    meta: _91id_93c7oXkpS8NkMeta || {},
    alias: _91id_93c7oXkpS8NkMeta?.alias || [],
    redirect: _91id_93c7oXkpS8NkMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/order/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93c7oXkpS8NkMeta?.name ?? "account-order-id___zh-tw",
    path: _91id_93c7oXkpS8NkMeta?.path ?? "/zh-tw/account/order/:id()",
    meta: _91id_93c7oXkpS8NkMeta || {},
    alias: _91id_93c7oXkpS8NkMeta?.alias || [],
    redirect: _91id_93c7oXkpS8NkMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/order/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93c7oXkpS8NkMeta?.name ?? "account-order-id___th",
    path: _91id_93c7oXkpS8NkMeta?.path ?? "/th/account/order/:id()",
    meta: _91id_93c7oXkpS8NkMeta || {},
    alias: _91id_93c7oXkpS8NkMeta?.alias || [],
    redirect: _91id_93c7oXkpS8NkMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/order/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93c7oXkpS8NkMeta?.name ?? "account-order-id___ja",
    path: _91id_93c7oXkpS8NkMeta?.path ?? "/ja/account/order/:id()",
    meta: _91id_93c7oXkpS8NkMeta || {},
    alias: _91id_93c7oXkpS8NkMeta?.alias || [],
    redirect: _91id_93c7oXkpS8NkMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/order/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93c7oXkpS8NkMeta?.name ?? "account-order-id___ms",
    path: _91id_93c7oXkpS8NkMeta?.path ?? "/ms/account/order/:id()",
    meta: _91id_93c7oXkpS8NkMeta || {},
    alias: _91id_93c7oXkpS8NkMeta?.alias || [],
    redirect: _91id_93c7oXkpS8NkMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/order/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93c7oXkpS8NkMeta?.name ?? "account-order-id___ko",
    path: _91id_93c7oXkpS8NkMeta?.path ?? "/ko/account/order/:id()",
    meta: _91id_93c7oXkpS8NkMeta || {},
    alias: _91id_93c7oXkpS8NkMeta?.alias || [],
    redirect: _91id_93c7oXkpS8NkMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/order/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93c7oXkpS8NkMeta?.name ?? "account-order-id___vi",
    path: _91id_93c7oXkpS8NkMeta?.path ?? "/vi/account/order/:id()",
    meta: _91id_93c7oXkpS8NkMeta || {},
    alias: _91id_93c7oXkpS8NkMeta?.alias || [],
    redirect: _91id_93c7oXkpS8NkMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/order/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93c7oXkpS8NkMeta?.name ?? "account-order-id___it",
    path: _91id_93c7oXkpS8NkMeta?.path ?? "/it/account/order/:id()",
    meta: _91id_93c7oXkpS8NkMeta || {},
    alias: _91id_93c7oXkpS8NkMeta?.alias || [],
    redirect: _91id_93c7oXkpS8NkMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/order/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93c7oXkpS8NkMeta?.name ?? "account-order-id___de",
    path: _91id_93c7oXkpS8NkMeta?.path ?? "/de/account/order/:id()",
    meta: _91id_93c7oXkpS8NkMeta || {},
    alias: _91id_93c7oXkpS8NkMeta?.alias || [],
    redirect: _91id_93c7oXkpS8NkMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/order/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93c7oXkpS8NkMeta?.name ?? "account-order-id___es",
    path: _91id_93c7oXkpS8NkMeta?.path ?? "/es/account/order/:id()",
    meta: _91id_93c7oXkpS8NkMeta || {},
    alias: _91id_93c7oXkpS8NkMeta?.alias || [],
    redirect: _91id_93c7oXkpS8NkMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/order/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93c7oXkpS8NkMeta?.name ?? "account-order-id___hi",
    path: _91id_93c7oXkpS8NkMeta?.path ?? "/hi/account/order/:id()",
    meta: _91id_93c7oXkpS8NkMeta || {},
    alias: _91id_93c7oXkpS8NkMeta?.alias || [],
    redirect: _91id_93c7oXkpS8NkMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/order/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93c7oXkpS8NkMeta?.name ?? "account-order-id___fr",
    path: _91id_93c7oXkpS8NkMeta?.path ?? "/fr/account/order/:id()",
    meta: _91id_93c7oXkpS8NkMeta || {},
    alias: _91id_93c7oXkpS8NkMeta?.alias || [],
    redirect: _91id_93c7oXkpS8NkMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/order/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93c7oXkpS8NkMeta?.name ?? "account-order-id___ru",
    path: _91id_93c7oXkpS8NkMeta?.path ?? "/ru/account/order/:id()",
    meta: _91id_93c7oXkpS8NkMeta || {},
    alias: _91id_93c7oXkpS8NkMeta?.alias || [],
    redirect: _91id_93c7oXkpS8NkMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/order/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93c7oXkpS8NkMeta?.name ?? "account-order-id___bn",
    path: _91id_93c7oXkpS8NkMeta?.path ?? "/bn/account/order/:id()",
    meta: _91id_93c7oXkpS8NkMeta || {},
    alias: _91id_93c7oXkpS8NkMeta?.alias || [],
    redirect: _91id_93c7oXkpS8NkMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/order/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93c7oXkpS8NkMeta?.name ?? "account-order-id___pt",
    path: _91id_93c7oXkpS8NkMeta?.path ?? "/pt/account/order/:id()",
    meta: _91id_93c7oXkpS8NkMeta || {},
    alias: _91id_93c7oXkpS8NkMeta?.alias || [],
    redirect: _91id_93c7oXkpS8NkMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/order/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93c7oXkpS8NkMeta?.name ?? "account-order-id___km",
    path: _91id_93c7oXkpS8NkMeta?.path ?? "/km/account/order/:id()",
    meta: _91id_93c7oXkpS8NkMeta || {},
    alias: _91id_93c7oXkpS8NkMeta?.alias || [],
    redirect: _91id_93c7oXkpS8NkMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/order/[id].vue").then(m => m.default || m)
  },
  {
    name: index86Eynsn1ZEMeta?.name ?? "account-order___en",
    path: index86Eynsn1ZEMeta?.path ?? "/en/account/order",
    meta: index86Eynsn1ZEMeta || {},
    alias: index86Eynsn1ZEMeta?.alias || [],
    redirect: index86Eynsn1ZEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/order/index.vue").then(m => m.default || m)
  },
  {
    name: index86Eynsn1ZEMeta?.name ?? "account-order___id",
    path: index86Eynsn1ZEMeta?.path ?? "/id/account/order",
    meta: index86Eynsn1ZEMeta || {},
    alias: index86Eynsn1ZEMeta?.alias || [],
    redirect: index86Eynsn1ZEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/order/index.vue").then(m => m.default || m)
  },
  {
    name: index86Eynsn1ZEMeta?.name ?? "account-order___tl",
    path: index86Eynsn1ZEMeta?.path ?? "/tl/account/order",
    meta: index86Eynsn1ZEMeta || {},
    alias: index86Eynsn1ZEMeta?.alias || [],
    redirect: index86Eynsn1ZEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/order/index.vue").then(m => m.default || m)
  },
  {
    name: index86Eynsn1ZEMeta?.name ?? "account-order___zh",
    path: index86Eynsn1ZEMeta?.path ?? "/zh/account/order",
    meta: index86Eynsn1ZEMeta || {},
    alias: index86Eynsn1ZEMeta?.alias || [],
    redirect: index86Eynsn1ZEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/order/index.vue").then(m => m.default || m)
  },
  {
    name: index86Eynsn1ZEMeta?.name ?? "account-order___zh-tw",
    path: index86Eynsn1ZEMeta?.path ?? "/zh-tw/account/order",
    meta: index86Eynsn1ZEMeta || {},
    alias: index86Eynsn1ZEMeta?.alias || [],
    redirect: index86Eynsn1ZEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/order/index.vue").then(m => m.default || m)
  },
  {
    name: index86Eynsn1ZEMeta?.name ?? "account-order___th",
    path: index86Eynsn1ZEMeta?.path ?? "/th/account/order",
    meta: index86Eynsn1ZEMeta || {},
    alias: index86Eynsn1ZEMeta?.alias || [],
    redirect: index86Eynsn1ZEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/order/index.vue").then(m => m.default || m)
  },
  {
    name: index86Eynsn1ZEMeta?.name ?? "account-order___ja",
    path: index86Eynsn1ZEMeta?.path ?? "/ja/account/order",
    meta: index86Eynsn1ZEMeta || {},
    alias: index86Eynsn1ZEMeta?.alias || [],
    redirect: index86Eynsn1ZEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/order/index.vue").then(m => m.default || m)
  },
  {
    name: index86Eynsn1ZEMeta?.name ?? "account-order___ms",
    path: index86Eynsn1ZEMeta?.path ?? "/ms/account/order",
    meta: index86Eynsn1ZEMeta || {},
    alias: index86Eynsn1ZEMeta?.alias || [],
    redirect: index86Eynsn1ZEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/order/index.vue").then(m => m.default || m)
  },
  {
    name: index86Eynsn1ZEMeta?.name ?? "account-order___ko",
    path: index86Eynsn1ZEMeta?.path ?? "/ko/account/order",
    meta: index86Eynsn1ZEMeta || {},
    alias: index86Eynsn1ZEMeta?.alias || [],
    redirect: index86Eynsn1ZEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/order/index.vue").then(m => m.default || m)
  },
  {
    name: index86Eynsn1ZEMeta?.name ?? "account-order___vi",
    path: index86Eynsn1ZEMeta?.path ?? "/vi/account/order",
    meta: index86Eynsn1ZEMeta || {},
    alias: index86Eynsn1ZEMeta?.alias || [],
    redirect: index86Eynsn1ZEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/order/index.vue").then(m => m.default || m)
  },
  {
    name: index86Eynsn1ZEMeta?.name ?? "account-order___it",
    path: index86Eynsn1ZEMeta?.path ?? "/it/account/order",
    meta: index86Eynsn1ZEMeta || {},
    alias: index86Eynsn1ZEMeta?.alias || [],
    redirect: index86Eynsn1ZEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/order/index.vue").then(m => m.default || m)
  },
  {
    name: index86Eynsn1ZEMeta?.name ?? "account-order___de",
    path: index86Eynsn1ZEMeta?.path ?? "/de/account/order",
    meta: index86Eynsn1ZEMeta || {},
    alias: index86Eynsn1ZEMeta?.alias || [],
    redirect: index86Eynsn1ZEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/order/index.vue").then(m => m.default || m)
  },
  {
    name: index86Eynsn1ZEMeta?.name ?? "account-order___es",
    path: index86Eynsn1ZEMeta?.path ?? "/es/account/order",
    meta: index86Eynsn1ZEMeta || {},
    alias: index86Eynsn1ZEMeta?.alias || [],
    redirect: index86Eynsn1ZEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/order/index.vue").then(m => m.default || m)
  },
  {
    name: index86Eynsn1ZEMeta?.name ?? "account-order___hi",
    path: index86Eynsn1ZEMeta?.path ?? "/hi/account/order",
    meta: index86Eynsn1ZEMeta || {},
    alias: index86Eynsn1ZEMeta?.alias || [],
    redirect: index86Eynsn1ZEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/order/index.vue").then(m => m.default || m)
  },
  {
    name: index86Eynsn1ZEMeta?.name ?? "account-order___fr",
    path: index86Eynsn1ZEMeta?.path ?? "/fr/account/order",
    meta: index86Eynsn1ZEMeta || {},
    alias: index86Eynsn1ZEMeta?.alias || [],
    redirect: index86Eynsn1ZEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/order/index.vue").then(m => m.default || m)
  },
  {
    name: index86Eynsn1ZEMeta?.name ?? "account-order___ru",
    path: index86Eynsn1ZEMeta?.path ?? "/ru/account/order",
    meta: index86Eynsn1ZEMeta || {},
    alias: index86Eynsn1ZEMeta?.alias || [],
    redirect: index86Eynsn1ZEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/order/index.vue").then(m => m.default || m)
  },
  {
    name: index86Eynsn1ZEMeta?.name ?? "account-order___bn",
    path: index86Eynsn1ZEMeta?.path ?? "/bn/account/order",
    meta: index86Eynsn1ZEMeta || {},
    alias: index86Eynsn1ZEMeta?.alias || [],
    redirect: index86Eynsn1ZEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/order/index.vue").then(m => m.default || m)
  },
  {
    name: index86Eynsn1ZEMeta?.name ?? "account-order___pt",
    path: index86Eynsn1ZEMeta?.path ?? "/pt/account/order",
    meta: index86Eynsn1ZEMeta || {},
    alias: index86Eynsn1ZEMeta?.alias || [],
    redirect: index86Eynsn1ZEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/order/index.vue").then(m => m.default || m)
  },
  {
    name: index86Eynsn1ZEMeta?.name ?? "account-order___km",
    path: index86Eynsn1ZEMeta?.path ?? "/km/account/order",
    meta: index86Eynsn1ZEMeta || {},
    alias: index86Eynsn1ZEMeta?.alias || [],
    redirect: index86Eynsn1ZEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/order/index.vue").then(m => m.default || m)
  },
  {
    name: passengerfYuXKjcNLXMeta?.name ?? "account-passenger___en",
    path: passengerfYuXKjcNLXMeta?.path ?? "/en/account/passenger",
    meta: passengerfYuXKjcNLXMeta || {},
    alias: passengerfYuXKjcNLXMeta?.alias || [],
    redirect: passengerfYuXKjcNLXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/passenger.vue").then(m => m.default || m)
  },
  {
    name: passengerfYuXKjcNLXMeta?.name ?? "account-passenger___id",
    path: passengerfYuXKjcNLXMeta?.path ?? "/id/account/passenger",
    meta: passengerfYuXKjcNLXMeta || {},
    alias: passengerfYuXKjcNLXMeta?.alias || [],
    redirect: passengerfYuXKjcNLXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/passenger.vue").then(m => m.default || m)
  },
  {
    name: passengerfYuXKjcNLXMeta?.name ?? "account-passenger___tl",
    path: passengerfYuXKjcNLXMeta?.path ?? "/tl/account/passenger",
    meta: passengerfYuXKjcNLXMeta || {},
    alias: passengerfYuXKjcNLXMeta?.alias || [],
    redirect: passengerfYuXKjcNLXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/passenger.vue").then(m => m.default || m)
  },
  {
    name: passengerfYuXKjcNLXMeta?.name ?? "account-passenger___zh",
    path: passengerfYuXKjcNLXMeta?.path ?? "/zh/account/passenger",
    meta: passengerfYuXKjcNLXMeta || {},
    alias: passengerfYuXKjcNLXMeta?.alias || [],
    redirect: passengerfYuXKjcNLXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/passenger.vue").then(m => m.default || m)
  },
  {
    name: passengerfYuXKjcNLXMeta?.name ?? "account-passenger___zh-tw",
    path: passengerfYuXKjcNLXMeta?.path ?? "/zh-tw/account/passenger",
    meta: passengerfYuXKjcNLXMeta || {},
    alias: passengerfYuXKjcNLXMeta?.alias || [],
    redirect: passengerfYuXKjcNLXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/passenger.vue").then(m => m.default || m)
  },
  {
    name: passengerfYuXKjcNLXMeta?.name ?? "account-passenger___th",
    path: passengerfYuXKjcNLXMeta?.path ?? "/th/account/passenger",
    meta: passengerfYuXKjcNLXMeta || {},
    alias: passengerfYuXKjcNLXMeta?.alias || [],
    redirect: passengerfYuXKjcNLXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/passenger.vue").then(m => m.default || m)
  },
  {
    name: passengerfYuXKjcNLXMeta?.name ?? "account-passenger___ja",
    path: passengerfYuXKjcNLXMeta?.path ?? "/ja/account/passenger",
    meta: passengerfYuXKjcNLXMeta || {},
    alias: passengerfYuXKjcNLXMeta?.alias || [],
    redirect: passengerfYuXKjcNLXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/passenger.vue").then(m => m.default || m)
  },
  {
    name: passengerfYuXKjcNLXMeta?.name ?? "account-passenger___ms",
    path: passengerfYuXKjcNLXMeta?.path ?? "/ms/account/passenger",
    meta: passengerfYuXKjcNLXMeta || {},
    alias: passengerfYuXKjcNLXMeta?.alias || [],
    redirect: passengerfYuXKjcNLXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/passenger.vue").then(m => m.default || m)
  },
  {
    name: passengerfYuXKjcNLXMeta?.name ?? "account-passenger___ko",
    path: passengerfYuXKjcNLXMeta?.path ?? "/ko/account/passenger",
    meta: passengerfYuXKjcNLXMeta || {},
    alias: passengerfYuXKjcNLXMeta?.alias || [],
    redirect: passengerfYuXKjcNLXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/passenger.vue").then(m => m.default || m)
  },
  {
    name: passengerfYuXKjcNLXMeta?.name ?? "account-passenger___vi",
    path: passengerfYuXKjcNLXMeta?.path ?? "/vi/account/passenger",
    meta: passengerfYuXKjcNLXMeta || {},
    alias: passengerfYuXKjcNLXMeta?.alias || [],
    redirect: passengerfYuXKjcNLXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/passenger.vue").then(m => m.default || m)
  },
  {
    name: passengerfYuXKjcNLXMeta?.name ?? "account-passenger___it",
    path: passengerfYuXKjcNLXMeta?.path ?? "/it/account/passenger",
    meta: passengerfYuXKjcNLXMeta || {},
    alias: passengerfYuXKjcNLXMeta?.alias || [],
    redirect: passengerfYuXKjcNLXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/passenger.vue").then(m => m.default || m)
  },
  {
    name: passengerfYuXKjcNLXMeta?.name ?? "account-passenger___de",
    path: passengerfYuXKjcNLXMeta?.path ?? "/de/account/passenger",
    meta: passengerfYuXKjcNLXMeta || {},
    alias: passengerfYuXKjcNLXMeta?.alias || [],
    redirect: passengerfYuXKjcNLXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/passenger.vue").then(m => m.default || m)
  },
  {
    name: passengerfYuXKjcNLXMeta?.name ?? "account-passenger___es",
    path: passengerfYuXKjcNLXMeta?.path ?? "/es/account/passenger",
    meta: passengerfYuXKjcNLXMeta || {},
    alias: passengerfYuXKjcNLXMeta?.alias || [],
    redirect: passengerfYuXKjcNLXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/passenger.vue").then(m => m.default || m)
  },
  {
    name: passengerfYuXKjcNLXMeta?.name ?? "account-passenger___hi",
    path: passengerfYuXKjcNLXMeta?.path ?? "/hi/account/passenger",
    meta: passengerfYuXKjcNLXMeta || {},
    alias: passengerfYuXKjcNLXMeta?.alias || [],
    redirect: passengerfYuXKjcNLXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/passenger.vue").then(m => m.default || m)
  },
  {
    name: passengerfYuXKjcNLXMeta?.name ?? "account-passenger___fr",
    path: passengerfYuXKjcNLXMeta?.path ?? "/fr/account/passenger",
    meta: passengerfYuXKjcNLXMeta || {},
    alias: passengerfYuXKjcNLXMeta?.alias || [],
    redirect: passengerfYuXKjcNLXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/passenger.vue").then(m => m.default || m)
  },
  {
    name: passengerfYuXKjcNLXMeta?.name ?? "account-passenger___ru",
    path: passengerfYuXKjcNLXMeta?.path ?? "/ru/account/passenger",
    meta: passengerfYuXKjcNLXMeta || {},
    alias: passengerfYuXKjcNLXMeta?.alias || [],
    redirect: passengerfYuXKjcNLXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/passenger.vue").then(m => m.default || m)
  },
  {
    name: passengerfYuXKjcNLXMeta?.name ?? "account-passenger___bn",
    path: passengerfYuXKjcNLXMeta?.path ?? "/bn/account/passenger",
    meta: passengerfYuXKjcNLXMeta || {},
    alias: passengerfYuXKjcNLXMeta?.alias || [],
    redirect: passengerfYuXKjcNLXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/passenger.vue").then(m => m.default || m)
  },
  {
    name: passengerfYuXKjcNLXMeta?.name ?? "account-passenger___pt",
    path: passengerfYuXKjcNLXMeta?.path ?? "/pt/account/passenger",
    meta: passengerfYuXKjcNLXMeta || {},
    alias: passengerfYuXKjcNLXMeta?.alias || [],
    redirect: passengerfYuXKjcNLXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/passenger.vue").then(m => m.default || m)
  },
  {
    name: passengerfYuXKjcNLXMeta?.name ?? "account-passenger___km",
    path: passengerfYuXKjcNLXMeta?.path ?? "/km/account/passenger",
    meta: passengerfYuXKjcNLXMeta || {},
    alias: passengerfYuXKjcNLXMeta?.alias || [],
    redirect: passengerfYuXKjcNLXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/passenger.vue").then(m => m.default || m)
  },
  {
    name: passwordLk94Ld215CMeta?.name ?? "account-password___en",
    path: passwordLk94Ld215CMeta?.path ?? "/en/account/password",
    meta: passwordLk94Ld215CMeta || {},
    alias: passwordLk94Ld215CMeta?.alias || [],
    redirect: passwordLk94Ld215CMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/password.vue").then(m => m.default || m)
  },
  {
    name: passwordLk94Ld215CMeta?.name ?? "account-password___id",
    path: passwordLk94Ld215CMeta?.path ?? "/id/account/password",
    meta: passwordLk94Ld215CMeta || {},
    alias: passwordLk94Ld215CMeta?.alias || [],
    redirect: passwordLk94Ld215CMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/password.vue").then(m => m.default || m)
  },
  {
    name: passwordLk94Ld215CMeta?.name ?? "account-password___tl",
    path: passwordLk94Ld215CMeta?.path ?? "/tl/account/password",
    meta: passwordLk94Ld215CMeta || {},
    alias: passwordLk94Ld215CMeta?.alias || [],
    redirect: passwordLk94Ld215CMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/password.vue").then(m => m.default || m)
  },
  {
    name: passwordLk94Ld215CMeta?.name ?? "account-password___zh",
    path: passwordLk94Ld215CMeta?.path ?? "/zh/account/password",
    meta: passwordLk94Ld215CMeta || {},
    alias: passwordLk94Ld215CMeta?.alias || [],
    redirect: passwordLk94Ld215CMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/password.vue").then(m => m.default || m)
  },
  {
    name: passwordLk94Ld215CMeta?.name ?? "account-password___zh-tw",
    path: passwordLk94Ld215CMeta?.path ?? "/zh-tw/account/password",
    meta: passwordLk94Ld215CMeta || {},
    alias: passwordLk94Ld215CMeta?.alias || [],
    redirect: passwordLk94Ld215CMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/password.vue").then(m => m.default || m)
  },
  {
    name: passwordLk94Ld215CMeta?.name ?? "account-password___th",
    path: passwordLk94Ld215CMeta?.path ?? "/th/account/password",
    meta: passwordLk94Ld215CMeta || {},
    alias: passwordLk94Ld215CMeta?.alias || [],
    redirect: passwordLk94Ld215CMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/password.vue").then(m => m.default || m)
  },
  {
    name: passwordLk94Ld215CMeta?.name ?? "account-password___ja",
    path: passwordLk94Ld215CMeta?.path ?? "/ja/account/password",
    meta: passwordLk94Ld215CMeta || {},
    alias: passwordLk94Ld215CMeta?.alias || [],
    redirect: passwordLk94Ld215CMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/password.vue").then(m => m.default || m)
  },
  {
    name: passwordLk94Ld215CMeta?.name ?? "account-password___ms",
    path: passwordLk94Ld215CMeta?.path ?? "/ms/account/password",
    meta: passwordLk94Ld215CMeta || {},
    alias: passwordLk94Ld215CMeta?.alias || [],
    redirect: passwordLk94Ld215CMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/password.vue").then(m => m.default || m)
  },
  {
    name: passwordLk94Ld215CMeta?.name ?? "account-password___ko",
    path: passwordLk94Ld215CMeta?.path ?? "/ko/account/password",
    meta: passwordLk94Ld215CMeta || {},
    alias: passwordLk94Ld215CMeta?.alias || [],
    redirect: passwordLk94Ld215CMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/password.vue").then(m => m.default || m)
  },
  {
    name: passwordLk94Ld215CMeta?.name ?? "account-password___vi",
    path: passwordLk94Ld215CMeta?.path ?? "/vi/account/password",
    meta: passwordLk94Ld215CMeta || {},
    alias: passwordLk94Ld215CMeta?.alias || [],
    redirect: passwordLk94Ld215CMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/password.vue").then(m => m.default || m)
  },
  {
    name: passwordLk94Ld215CMeta?.name ?? "account-password___it",
    path: passwordLk94Ld215CMeta?.path ?? "/it/account/password",
    meta: passwordLk94Ld215CMeta || {},
    alias: passwordLk94Ld215CMeta?.alias || [],
    redirect: passwordLk94Ld215CMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/password.vue").then(m => m.default || m)
  },
  {
    name: passwordLk94Ld215CMeta?.name ?? "account-password___de",
    path: passwordLk94Ld215CMeta?.path ?? "/de/account/password",
    meta: passwordLk94Ld215CMeta || {},
    alias: passwordLk94Ld215CMeta?.alias || [],
    redirect: passwordLk94Ld215CMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/password.vue").then(m => m.default || m)
  },
  {
    name: passwordLk94Ld215CMeta?.name ?? "account-password___es",
    path: passwordLk94Ld215CMeta?.path ?? "/es/account/password",
    meta: passwordLk94Ld215CMeta || {},
    alias: passwordLk94Ld215CMeta?.alias || [],
    redirect: passwordLk94Ld215CMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/password.vue").then(m => m.default || m)
  },
  {
    name: passwordLk94Ld215CMeta?.name ?? "account-password___hi",
    path: passwordLk94Ld215CMeta?.path ?? "/hi/account/password",
    meta: passwordLk94Ld215CMeta || {},
    alias: passwordLk94Ld215CMeta?.alias || [],
    redirect: passwordLk94Ld215CMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/password.vue").then(m => m.default || m)
  },
  {
    name: passwordLk94Ld215CMeta?.name ?? "account-password___fr",
    path: passwordLk94Ld215CMeta?.path ?? "/fr/account/password",
    meta: passwordLk94Ld215CMeta || {},
    alias: passwordLk94Ld215CMeta?.alias || [],
    redirect: passwordLk94Ld215CMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/password.vue").then(m => m.default || m)
  },
  {
    name: passwordLk94Ld215CMeta?.name ?? "account-password___ru",
    path: passwordLk94Ld215CMeta?.path ?? "/ru/account/password",
    meta: passwordLk94Ld215CMeta || {},
    alias: passwordLk94Ld215CMeta?.alias || [],
    redirect: passwordLk94Ld215CMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/password.vue").then(m => m.default || m)
  },
  {
    name: passwordLk94Ld215CMeta?.name ?? "account-password___bn",
    path: passwordLk94Ld215CMeta?.path ?? "/bn/account/password",
    meta: passwordLk94Ld215CMeta || {},
    alias: passwordLk94Ld215CMeta?.alias || [],
    redirect: passwordLk94Ld215CMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/password.vue").then(m => m.default || m)
  },
  {
    name: passwordLk94Ld215CMeta?.name ?? "account-password___pt",
    path: passwordLk94Ld215CMeta?.path ?? "/pt/account/password",
    meta: passwordLk94Ld215CMeta || {},
    alias: passwordLk94Ld215CMeta?.alias || [],
    redirect: passwordLk94Ld215CMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/password.vue").then(m => m.default || m)
  },
  {
    name: passwordLk94Ld215CMeta?.name ?? "account-password___km",
    path: passwordLk94Ld215CMeta?.path ?? "/km/account/password",
    meta: passwordLk94Ld215CMeta || {},
    alias: passwordLk94Ld215CMeta?.alias || [],
    redirect: passwordLk94Ld215CMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/password.vue").then(m => m.default || m)
  },
  {
    name: profilePWDGVhZiJnMeta?.name ?? "account-profile___en",
    path: profilePWDGVhZiJnMeta?.path ?? "/en/account/profile",
    meta: profilePWDGVhZiJnMeta || {},
    alias: profilePWDGVhZiJnMeta?.alias || [],
    redirect: profilePWDGVhZiJnMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profilePWDGVhZiJnMeta?.name ?? "account-profile___id",
    path: profilePWDGVhZiJnMeta?.path ?? "/id/account/profile",
    meta: profilePWDGVhZiJnMeta || {},
    alias: profilePWDGVhZiJnMeta?.alias || [],
    redirect: profilePWDGVhZiJnMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profilePWDGVhZiJnMeta?.name ?? "account-profile___tl",
    path: profilePWDGVhZiJnMeta?.path ?? "/tl/account/profile",
    meta: profilePWDGVhZiJnMeta || {},
    alias: profilePWDGVhZiJnMeta?.alias || [],
    redirect: profilePWDGVhZiJnMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profilePWDGVhZiJnMeta?.name ?? "account-profile___zh",
    path: profilePWDGVhZiJnMeta?.path ?? "/zh/account/profile",
    meta: profilePWDGVhZiJnMeta || {},
    alias: profilePWDGVhZiJnMeta?.alias || [],
    redirect: profilePWDGVhZiJnMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profilePWDGVhZiJnMeta?.name ?? "account-profile___zh-tw",
    path: profilePWDGVhZiJnMeta?.path ?? "/zh-tw/account/profile",
    meta: profilePWDGVhZiJnMeta || {},
    alias: profilePWDGVhZiJnMeta?.alias || [],
    redirect: profilePWDGVhZiJnMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profilePWDGVhZiJnMeta?.name ?? "account-profile___th",
    path: profilePWDGVhZiJnMeta?.path ?? "/th/account/profile",
    meta: profilePWDGVhZiJnMeta || {},
    alias: profilePWDGVhZiJnMeta?.alias || [],
    redirect: profilePWDGVhZiJnMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profilePWDGVhZiJnMeta?.name ?? "account-profile___ja",
    path: profilePWDGVhZiJnMeta?.path ?? "/ja/account/profile",
    meta: profilePWDGVhZiJnMeta || {},
    alias: profilePWDGVhZiJnMeta?.alias || [],
    redirect: profilePWDGVhZiJnMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profilePWDGVhZiJnMeta?.name ?? "account-profile___ms",
    path: profilePWDGVhZiJnMeta?.path ?? "/ms/account/profile",
    meta: profilePWDGVhZiJnMeta || {},
    alias: profilePWDGVhZiJnMeta?.alias || [],
    redirect: profilePWDGVhZiJnMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profilePWDGVhZiJnMeta?.name ?? "account-profile___ko",
    path: profilePWDGVhZiJnMeta?.path ?? "/ko/account/profile",
    meta: profilePWDGVhZiJnMeta || {},
    alias: profilePWDGVhZiJnMeta?.alias || [],
    redirect: profilePWDGVhZiJnMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profilePWDGVhZiJnMeta?.name ?? "account-profile___vi",
    path: profilePWDGVhZiJnMeta?.path ?? "/vi/account/profile",
    meta: profilePWDGVhZiJnMeta || {},
    alias: profilePWDGVhZiJnMeta?.alias || [],
    redirect: profilePWDGVhZiJnMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profilePWDGVhZiJnMeta?.name ?? "account-profile___it",
    path: profilePWDGVhZiJnMeta?.path ?? "/it/account/profile",
    meta: profilePWDGVhZiJnMeta || {},
    alias: profilePWDGVhZiJnMeta?.alias || [],
    redirect: profilePWDGVhZiJnMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profilePWDGVhZiJnMeta?.name ?? "account-profile___de",
    path: profilePWDGVhZiJnMeta?.path ?? "/de/account/profile",
    meta: profilePWDGVhZiJnMeta || {},
    alias: profilePWDGVhZiJnMeta?.alias || [],
    redirect: profilePWDGVhZiJnMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profilePWDGVhZiJnMeta?.name ?? "account-profile___es",
    path: profilePWDGVhZiJnMeta?.path ?? "/es/account/profile",
    meta: profilePWDGVhZiJnMeta || {},
    alias: profilePWDGVhZiJnMeta?.alias || [],
    redirect: profilePWDGVhZiJnMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profilePWDGVhZiJnMeta?.name ?? "account-profile___hi",
    path: profilePWDGVhZiJnMeta?.path ?? "/hi/account/profile",
    meta: profilePWDGVhZiJnMeta || {},
    alias: profilePWDGVhZiJnMeta?.alias || [],
    redirect: profilePWDGVhZiJnMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profilePWDGVhZiJnMeta?.name ?? "account-profile___fr",
    path: profilePWDGVhZiJnMeta?.path ?? "/fr/account/profile",
    meta: profilePWDGVhZiJnMeta || {},
    alias: profilePWDGVhZiJnMeta?.alias || [],
    redirect: profilePWDGVhZiJnMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profilePWDGVhZiJnMeta?.name ?? "account-profile___ru",
    path: profilePWDGVhZiJnMeta?.path ?? "/ru/account/profile",
    meta: profilePWDGVhZiJnMeta || {},
    alias: profilePWDGVhZiJnMeta?.alias || [],
    redirect: profilePWDGVhZiJnMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profilePWDGVhZiJnMeta?.name ?? "account-profile___bn",
    path: profilePWDGVhZiJnMeta?.path ?? "/bn/account/profile",
    meta: profilePWDGVhZiJnMeta || {},
    alias: profilePWDGVhZiJnMeta?.alias || [],
    redirect: profilePWDGVhZiJnMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profilePWDGVhZiJnMeta?.name ?? "account-profile___pt",
    path: profilePWDGVhZiJnMeta?.path ?? "/pt/account/profile",
    meta: profilePWDGVhZiJnMeta || {},
    alias: profilePWDGVhZiJnMeta?.alias || [],
    redirect: profilePWDGVhZiJnMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profilePWDGVhZiJnMeta?.name ?? "account-profile___km",
    path: profilePWDGVhZiJnMeta?.path ?? "/km/account/profile",
    meta: profilePWDGVhZiJnMeta || {},
    alias: profilePWDGVhZiJnMeta?.alias || [],
    redirect: profilePWDGVhZiJnMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: verifyCn5M7XrWpdMeta?.name ?? "account-verify___en",
    path: verifyCn5M7XrWpdMeta?.path ?? "/en/account/verify",
    meta: verifyCn5M7XrWpdMeta || {},
    alias: verifyCn5M7XrWpdMeta?.alias || [],
    redirect: verifyCn5M7XrWpdMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyCn5M7XrWpdMeta?.name ?? "account-verify___id",
    path: verifyCn5M7XrWpdMeta?.path ?? "/id/account/verify",
    meta: verifyCn5M7XrWpdMeta || {},
    alias: verifyCn5M7XrWpdMeta?.alias || [],
    redirect: verifyCn5M7XrWpdMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyCn5M7XrWpdMeta?.name ?? "account-verify___tl",
    path: verifyCn5M7XrWpdMeta?.path ?? "/tl/account/verify",
    meta: verifyCn5M7XrWpdMeta || {},
    alias: verifyCn5M7XrWpdMeta?.alias || [],
    redirect: verifyCn5M7XrWpdMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyCn5M7XrWpdMeta?.name ?? "account-verify___zh",
    path: verifyCn5M7XrWpdMeta?.path ?? "/zh/account/verify",
    meta: verifyCn5M7XrWpdMeta || {},
    alias: verifyCn5M7XrWpdMeta?.alias || [],
    redirect: verifyCn5M7XrWpdMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyCn5M7XrWpdMeta?.name ?? "account-verify___zh-tw",
    path: verifyCn5M7XrWpdMeta?.path ?? "/zh-tw/account/verify",
    meta: verifyCn5M7XrWpdMeta || {},
    alias: verifyCn5M7XrWpdMeta?.alias || [],
    redirect: verifyCn5M7XrWpdMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyCn5M7XrWpdMeta?.name ?? "account-verify___th",
    path: verifyCn5M7XrWpdMeta?.path ?? "/th/account/verify",
    meta: verifyCn5M7XrWpdMeta || {},
    alias: verifyCn5M7XrWpdMeta?.alias || [],
    redirect: verifyCn5M7XrWpdMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyCn5M7XrWpdMeta?.name ?? "account-verify___ja",
    path: verifyCn5M7XrWpdMeta?.path ?? "/ja/account/verify",
    meta: verifyCn5M7XrWpdMeta || {},
    alias: verifyCn5M7XrWpdMeta?.alias || [],
    redirect: verifyCn5M7XrWpdMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyCn5M7XrWpdMeta?.name ?? "account-verify___ms",
    path: verifyCn5M7XrWpdMeta?.path ?? "/ms/account/verify",
    meta: verifyCn5M7XrWpdMeta || {},
    alias: verifyCn5M7XrWpdMeta?.alias || [],
    redirect: verifyCn5M7XrWpdMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyCn5M7XrWpdMeta?.name ?? "account-verify___ko",
    path: verifyCn5M7XrWpdMeta?.path ?? "/ko/account/verify",
    meta: verifyCn5M7XrWpdMeta || {},
    alias: verifyCn5M7XrWpdMeta?.alias || [],
    redirect: verifyCn5M7XrWpdMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyCn5M7XrWpdMeta?.name ?? "account-verify___vi",
    path: verifyCn5M7XrWpdMeta?.path ?? "/vi/account/verify",
    meta: verifyCn5M7XrWpdMeta || {},
    alias: verifyCn5M7XrWpdMeta?.alias || [],
    redirect: verifyCn5M7XrWpdMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyCn5M7XrWpdMeta?.name ?? "account-verify___it",
    path: verifyCn5M7XrWpdMeta?.path ?? "/it/account/verify",
    meta: verifyCn5M7XrWpdMeta || {},
    alias: verifyCn5M7XrWpdMeta?.alias || [],
    redirect: verifyCn5M7XrWpdMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyCn5M7XrWpdMeta?.name ?? "account-verify___de",
    path: verifyCn5M7XrWpdMeta?.path ?? "/de/account/verify",
    meta: verifyCn5M7XrWpdMeta || {},
    alias: verifyCn5M7XrWpdMeta?.alias || [],
    redirect: verifyCn5M7XrWpdMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyCn5M7XrWpdMeta?.name ?? "account-verify___es",
    path: verifyCn5M7XrWpdMeta?.path ?? "/es/account/verify",
    meta: verifyCn5M7XrWpdMeta || {},
    alias: verifyCn5M7XrWpdMeta?.alias || [],
    redirect: verifyCn5M7XrWpdMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyCn5M7XrWpdMeta?.name ?? "account-verify___hi",
    path: verifyCn5M7XrWpdMeta?.path ?? "/hi/account/verify",
    meta: verifyCn5M7XrWpdMeta || {},
    alias: verifyCn5M7XrWpdMeta?.alias || [],
    redirect: verifyCn5M7XrWpdMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyCn5M7XrWpdMeta?.name ?? "account-verify___fr",
    path: verifyCn5M7XrWpdMeta?.path ?? "/fr/account/verify",
    meta: verifyCn5M7XrWpdMeta || {},
    alias: verifyCn5M7XrWpdMeta?.alias || [],
    redirect: verifyCn5M7XrWpdMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyCn5M7XrWpdMeta?.name ?? "account-verify___ru",
    path: verifyCn5M7XrWpdMeta?.path ?? "/ru/account/verify",
    meta: verifyCn5M7XrWpdMeta || {},
    alias: verifyCn5M7XrWpdMeta?.alias || [],
    redirect: verifyCn5M7XrWpdMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyCn5M7XrWpdMeta?.name ?? "account-verify___bn",
    path: verifyCn5M7XrWpdMeta?.path ?? "/bn/account/verify",
    meta: verifyCn5M7XrWpdMeta || {},
    alias: verifyCn5M7XrWpdMeta?.alias || [],
    redirect: verifyCn5M7XrWpdMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyCn5M7XrWpdMeta?.name ?? "account-verify___pt",
    path: verifyCn5M7XrWpdMeta?.path ?? "/pt/account/verify",
    meta: verifyCn5M7XrWpdMeta || {},
    alias: verifyCn5M7XrWpdMeta?.alias || [],
    redirect: verifyCn5M7XrWpdMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyCn5M7XrWpdMeta?.name ?? "account-verify___km",
    path: verifyCn5M7XrWpdMeta?.path ?? "/km/account/verify",
    meta: verifyCn5M7XrWpdMeta || {},
    alias: verifyCn5M7XrWpdMeta?.alias || [],
    redirect: verifyCn5M7XrWpdMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/account/verify.vue").then(m => m.default || m)
  },
  {
    name: searchWnar8loo4vMeta?.name ?? "aftersales-change-flight-submission-id-search___en",
    path: searchWnar8loo4vMeta?.path ?? "/en/aftersales/change-flight/submission/:id()/search",
    meta: searchWnar8loo4vMeta || {},
    alias: searchWnar8loo4vMeta?.alias || [],
    redirect: searchWnar8loo4vMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/aftersales/change-flight/submission/[id]/search.vue").then(m => m.default || m)
  },
  {
    name: searchWnar8loo4vMeta?.name ?? "aftersales-change-flight-submission-id-search___id",
    path: searchWnar8loo4vMeta?.path ?? "/id/aftersales/change-flight/submission/:id()/search",
    meta: searchWnar8loo4vMeta || {},
    alias: searchWnar8loo4vMeta?.alias || [],
    redirect: searchWnar8loo4vMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/aftersales/change-flight/submission/[id]/search.vue").then(m => m.default || m)
  },
  {
    name: searchWnar8loo4vMeta?.name ?? "aftersales-change-flight-submission-id-search___tl",
    path: searchWnar8loo4vMeta?.path ?? "/tl/aftersales/change-flight/submission/:id()/search",
    meta: searchWnar8loo4vMeta || {},
    alias: searchWnar8loo4vMeta?.alias || [],
    redirect: searchWnar8loo4vMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/aftersales/change-flight/submission/[id]/search.vue").then(m => m.default || m)
  },
  {
    name: searchWnar8loo4vMeta?.name ?? "aftersales-change-flight-submission-id-search___zh",
    path: searchWnar8loo4vMeta?.path ?? "/zh/aftersales/change-flight/submission/:id()/search",
    meta: searchWnar8loo4vMeta || {},
    alias: searchWnar8loo4vMeta?.alias || [],
    redirect: searchWnar8loo4vMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/aftersales/change-flight/submission/[id]/search.vue").then(m => m.default || m)
  },
  {
    name: searchWnar8loo4vMeta?.name ?? "aftersales-change-flight-submission-id-search___zh-tw",
    path: searchWnar8loo4vMeta?.path ?? "/zh-tw/aftersales/change-flight/submission/:id()/search",
    meta: searchWnar8loo4vMeta || {},
    alias: searchWnar8loo4vMeta?.alias || [],
    redirect: searchWnar8loo4vMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/aftersales/change-flight/submission/[id]/search.vue").then(m => m.default || m)
  },
  {
    name: searchWnar8loo4vMeta?.name ?? "aftersales-change-flight-submission-id-search___th",
    path: searchWnar8loo4vMeta?.path ?? "/th/aftersales/change-flight/submission/:id()/search",
    meta: searchWnar8loo4vMeta || {},
    alias: searchWnar8loo4vMeta?.alias || [],
    redirect: searchWnar8loo4vMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/aftersales/change-flight/submission/[id]/search.vue").then(m => m.default || m)
  },
  {
    name: searchWnar8loo4vMeta?.name ?? "aftersales-change-flight-submission-id-search___ja",
    path: searchWnar8loo4vMeta?.path ?? "/ja/aftersales/change-flight/submission/:id()/search",
    meta: searchWnar8loo4vMeta || {},
    alias: searchWnar8loo4vMeta?.alias || [],
    redirect: searchWnar8loo4vMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/aftersales/change-flight/submission/[id]/search.vue").then(m => m.default || m)
  },
  {
    name: searchWnar8loo4vMeta?.name ?? "aftersales-change-flight-submission-id-search___ms",
    path: searchWnar8loo4vMeta?.path ?? "/ms/aftersales/change-flight/submission/:id()/search",
    meta: searchWnar8loo4vMeta || {},
    alias: searchWnar8loo4vMeta?.alias || [],
    redirect: searchWnar8loo4vMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/aftersales/change-flight/submission/[id]/search.vue").then(m => m.default || m)
  },
  {
    name: searchWnar8loo4vMeta?.name ?? "aftersales-change-flight-submission-id-search___ko",
    path: searchWnar8loo4vMeta?.path ?? "/ko/aftersales/change-flight/submission/:id()/search",
    meta: searchWnar8loo4vMeta || {},
    alias: searchWnar8loo4vMeta?.alias || [],
    redirect: searchWnar8loo4vMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/aftersales/change-flight/submission/[id]/search.vue").then(m => m.default || m)
  },
  {
    name: searchWnar8loo4vMeta?.name ?? "aftersales-change-flight-submission-id-search___vi",
    path: searchWnar8loo4vMeta?.path ?? "/vi/aftersales/change-flight/submission/:id()/search",
    meta: searchWnar8loo4vMeta || {},
    alias: searchWnar8loo4vMeta?.alias || [],
    redirect: searchWnar8loo4vMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/aftersales/change-flight/submission/[id]/search.vue").then(m => m.default || m)
  },
  {
    name: searchWnar8loo4vMeta?.name ?? "aftersales-change-flight-submission-id-search___it",
    path: searchWnar8loo4vMeta?.path ?? "/it/aftersales/change-flight/submission/:id()/search",
    meta: searchWnar8loo4vMeta || {},
    alias: searchWnar8loo4vMeta?.alias || [],
    redirect: searchWnar8loo4vMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/aftersales/change-flight/submission/[id]/search.vue").then(m => m.default || m)
  },
  {
    name: searchWnar8loo4vMeta?.name ?? "aftersales-change-flight-submission-id-search___de",
    path: searchWnar8loo4vMeta?.path ?? "/de/aftersales/change-flight/submission/:id()/search",
    meta: searchWnar8loo4vMeta || {},
    alias: searchWnar8loo4vMeta?.alias || [],
    redirect: searchWnar8loo4vMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/aftersales/change-flight/submission/[id]/search.vue").then(m => m.default || m)
  },
  {
    name: searchWnar8loo4vMeta?.name ?? "aftersales-change-flight-submission-id-search___es",
    path: searchWnar8loo4vMeta?.path ?? "/es/aftersales/change-flight/submission/:id()/search",
    meta: searchWnar8loo4vMeta || {},
    alias: searchWnar8loo4vMeta?.alias || [],
    redirect: searchWnar8loo4vMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/aftersales/change-flight/submission/[id]/search.vue").then(m => m.default || m)
  },
  {
    name: searchWnar8loo4vMeta?.name ?? "aftersales-change-flight-submission-id-search___hi",
    path: searchWnar8loo4vMeta?.path ?? "/hi/aftersales/change-flight/submission/:id()/search",
    meta: searchWnar8loo4vMeta || {},
    alias: searchWnar8loo4vMeta?.alias || [],
    redirect: searchWnar8loo4vMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/aftersales/change-flight/submission/[id]/search.vue").then(m => m.default || m)
  },
  {
    name: searchWnar8loo4vMeta?.name ?? "aftersales-change-flight-submission-id-search___fr",
    path: searchWnar8loo4vMeta?.path ?? "/fr/aftersales/change-flight/submission/:id()/search",
    meta: searchWnar8loo4vMeta || {},
    alias: searchWnar8loo4vMeta?.alias || [],
    redirect: searchWnar8loo4vMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/aftersales/change-flight/submission/[id]/search.vue").then(m => m.default || m)
  },
  {
    name: searchWnar8loo4vMeta?.name ?? "aftersales-change-flight-submission-id-search___ru",
    path: searchWnar8loo4vMeta?.path ?? "/ru/aftersales/change-flight/submission/:id()/search",
    meta: searchWnar8loo4vMeta || {},
    alias: searchWnar8loo4vMeta?.alias || [],
    redirect: searchWnar8loo4vMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/aftersales/change-flight/submission/[id]/search.vue").then(m => m.default || m)
  },
  {
    name: searchWnar8loo4vMeta?.name ?? "aftersales-change-flight-submission-id-search___bn",
    path: searchWnar8loo4vMeta?.path ?? "/bn/aftersales/change-flight/submission/:id()/search",
    meta: searchWnar8loo4vMeta || {},
    alias: searchWnar8loo4vMeta?.alias || [],
    redirect: searchWnar8loo4vMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/aftersales/change-flight/submission/[id]/search.vue").then(m => m.default || m)
  },
  {
    name: searchWnar8loo4vMeta?.name ?? "aftersales-change-flight-submission-id-search___pt",
    path: searchWnar8loo4vMeta?.path ?? "/pt/aftersales/change-flight/submission/:id()/search",
    meta: searchWnar8loo4vMeta || {},
    alias: searchWnar8loo4vMeta?.alias || [],
    redirect: searchWnar8loo4vMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/aftersales/change-flight/submission/[id]/search.vue").then(m => m.default || m)
  },
  {
    name: searchWnar8loo4vMeta?.name ?? "aftersales-change-flight-submission-id-search___km",
    path: searchWnar8loo4vMeta?.path ?? "/km/aftersales/change-flight/submission/:id()/search",
    meta: searchWnar8loo4vMeta || {},
    alias: searchWnar8loo4vMeta?.alias || [],
    redirect: searchWnar8loo4vMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/aftersales/change-flight/submission/[id]/search.vue").then(m => m.default || m)
  },
  {
    name: callbackb3kNIrFnr3Meta?.name ?? "callback",
    path: callbackb3kNIrFnr3Meta?.path ?? "/callback",
    meta: callbackb3kNIrFnr3Meta || {},
    alias: callbackb3kNIrFnr3Meta?.alias || [],
    redirect: callbackb3kNIrFnr3Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/callback.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93JrpukDr55PMeta?.name ?? "campaign-slug___en",
    path: _91slug_93JrpukDr55PMeta?.path ?? "/en/campaign/:slug()",
    meta: _91slug_93JrpukDr55PMeta || {},
    alias: _91slug_93JrpukDr55PMeta?.alias || [],
    redirect: _91slug_93JrpukDr55PMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/campaign/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93JrpukDr55PMeta?.name ?? "campaign-slug___id",
    path: _91slug_93JrpukDr55PMeta?.path ?? "/id/campaign/:slug()",
    meta: _91slug_93JrpukDr55PMeta || {},
    alias: _91slug_93JrpukDr55PMeta?.alias || [],
    redirect: _91slug_93JrpukDr55PMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/campaign/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93JrpukDr55PMeta?.name ?? "campaign-slug___tl",
    path: _91slug_93JrpukDr55PMeta?.path ?? "/tl/campaign/:slug()",
    meta: _91slug_93JrpukDr55PMeta || {},
    alias: _91slug_93JrpukDr55PMeta?.alias || [],
    redirect: _91slug_93JrpukDr55PMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/campaign/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93JrpukDr55PMeta?.name ?? "campaign-slug___zh",
    path: _91slug_93JrpukDr55PMeta?.path ?? "/zh/campaign/:slug()",
    meta: _91slug_93JrpukDr55PMeta || {},
    alias: _91slug_93JrpukDr55PMeta?.alias || [],
    redirect: _91slug_93JrpukDr55PMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/campaign/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93JrpukDr55PMeta?.name ?? "campaign-slug___zh-tw",
    path: _91slug_93JrpukDr55PMeta?.path ?? "/zh-tw/campaign/:slug()",
    meta: _91slug_93JrpukDr55PMeta || {},
    alias: _91slug_93JrpukDr55PMeta?.alias || [],
    redirect: _91slug_93JrpukDr55PMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/campaign/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93JrpukDr55PMeta?.name ?? "campaign-slug___th",
    path: _91slug_93JrpukDr55PMeta?.path ?? "/th/campaign/:slug()",
    meta: _91slug_93JrpukDr55PMeta || {},
    alias: _91slug_93JrpukDr55PMeta?.alias || [],
    redirect: _91slug_93JrpukDr55PMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/campaign/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93JrpukDr55PMeta?.name ?? "campaign-slug___ja",
    path: _91slug_93JrpukDr55PMeta?.path ?? "/ja/campaign/:slug()",
    meta: _91slug_93JrpukDr55PMeta || {},
    alias: _91slug_93JrpukDr55PMeta?.alias || [],
    redirect: _91slug_93JrpukDr55PMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/campaign/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93JrpukDr55PMeta?.name ?? "campaign-slug___ms",
    path: _91slug_93JrpukDr55PMeta?.path ?? "/ms/campaign/:slug()",
    meta: _91slug_93JrpukDr55PMeta || {},
    alias: _91slug_93JrpukDr55PMeta?.alias || [],
    redirect: _91slug_93JrpukDr55PMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/campaign/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93JrpukDr55PMeta?.name ?? "campaign-slug___ko",
    path: _91slug_93JrpukDr55PMeta?.path ?? "/ko/campaign/:slug()",
    meta: _91slug_93JrpukDr55PMeta || {},
    alias: _91slug_93JrpukDr55PMeta?.alias || [],
    redirect: _91slug_93JrpukDr55PMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/campaign/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93JrpukDr55PMeta?.name ?? "campaign-slug___vi",
    path: _91slug_93JrpukDr55PMeta?.path ?? "/vi/campaign/:slug()",
    meta: _91slug_93JrpukDr55PMeta || {},
    alias: _91slug_93JrpukDr55PMeta?.alias || [],
    redirect: _91slug_93JrpukDr55PMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/campaign/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93JrpukDr55PMeta?.name ?? "campaign-slug___it",
    path: _91slug_93JrpukDr55PMeta?.path ?? "/it/campaign/:slug()",
    meta: _91slug_93JrpukDr55PMeta || {},
    alias: _91slug_93JrpukDr55PMeta?.alias || [],
    redirect: _91slug_93JrpukDr55PMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/campaign/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93JrpukDr55PMeta?.name ?? "campaign-slug___de",
    path: _91slug_93JrpukDr55PMeta?.path ?? "/de/campaign/:slug()",
    meta: _91slug_93JrpukDr55PMeta || {},
    alias: _91slug_93JrpukDr55PMeta?.alias || [],
    redirect: _91slug_93JrpukDr55PMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/campaign/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93JrpukDr55PMeta?.name ?? "campaign-slug___es",
    path: _91slug_93JrpukDr55PMeta?.path ?? "/es/campaign/:slug()",
    meta: _91slug_93JrpukDr55PMeta || {},
    alias: _91slug_93JrpukDr55PMeta?.alias || [],
    redirect: _91slug_93JrpukDr55PMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/campaign/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93JrpukDr55PMeta?.name ?? "campaign-slug___hi",
    path: _91slug_93JrpukDr55PMeta?.path ?? "/hi/campaign/:slug()",
    meta: _91slug_93JrpukDr55PMeta || {},
    alias: _91slug_93JrpukDr55PMeta?.alias || [],
    redirect: _91slug_93JrpukDr55PMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/campaign/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93JrpukDr55PMeta?.name ?? "campaign-slug___fr",
    path: _91slug_93JrpukDr55PMeta?.path ?? "/fr/campaign/:slug()",
    meta: _91slug_93JrpukDr55PMeta || {},
    alias: _91slug_93JrpukDr55PMeta?.alias || [],
    redirect: _91slug_93JrpukDr55PMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/campaign/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93JrpukDr55PMeta?.name ?? "campaign-slug___ru",
    path: _91slug_93JrpukDr55PMeta?.path ?? "/ru/campaign/:slug()",
    meta: _91slug_93JrpukDr55PMeta || {},
    alias: _91slug_93JrpukDr55PMeta?.alias || [],
    redirect: _91slug_93JrpukDr55PMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/campaign/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93JrpukDr55PMeta?.name ?? "campaign-slug___bn",
    path: _91slug_93JrpukDr55PMeta?.path ?? "/bn/campaign/:slug()",
    meta: _91slug_93JrpukDr55PMeta || {},
    alias: _91slug_93JrpukDr55PMeta?.alias || [],
    redirect: _91slug_93JrpukDr55PMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/campaign/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93JrpukDr55PMeta?.name ?? "campaign-slug___pt",
    path: _91slug_93JrpukDr55PMeta?.path ?? "/pt/campaign/:slug()",
    meta: _91slug_93JrpukDr55PMeta || {},
    alias: _91slug_93JrpukDr55PMeta?.alias || [],
    redirect: _91slug_93JrpukDr55PMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/campaign/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93JrpukDr55PMeta?.name ?? "campaign-slug___km",
    path: _91slug_93JrpukDr55PMeta?.path ?? "/km/campaign/:slug()",
    meta: _91slug_93JrpukDr55PMeta || {},
    alias: _91slug_93JrpukDr55PMeta?.alias || [],
    redirect: _91slug_93JrpukDr55PMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/campaign/[slug].vue").then(m => m.default || m)
  },
  {
    name: choose_45countrypzaL9LRzvcMeta?.name ?? "choose-country___en",
    path: choose_45countrypzaL9LRzvcMeta?.path ?? "/en/choose-country",
    meta: choose_45countrypzaL9LRzvcMeta || {},
    alias: choose_45countrypzaL9LRzvcMeta?.alias || [],
    redirect: choose_45countrypzaL9LRzvcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/choose-country.vue").then(m => m.default || m)
  },
  {
    name: choose_45countrypzaL9LRzvcMeta?.name ?? "choose-country___id",
    path: choose_45countrypzaL9LRzvcMeta?.path ?? "/id/choose-country",
    meta: choose_45countrypzaL9LRzvcMeta || {},
    alias: choose_45countrypzaL9LRzvcMeta?.alias || [],
    redirect: choose_45countrypzaL9LRzvcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/choose-country.vue").then(m => m.default || m)
  },
  {
    name: choose_45countrypzaL9LRzvcMeta?.name ?? "choose-country___tl",
    path: choose_45countrypzaL9LRzvcMeta?.path ?? "/tl/choose-country",
    meta: choose_45countrypzaL9LRzvcMeta || {},
    alias: choose_45countrypzaL9LRzvcMeta?.alias || [],
    redirect: choose_45countrypzaL9LRzvcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/choose-country.vue").then(m => m.default || m)
  },
  {
    name: choose_45countrypzaL9LRzvcMeta?.name ?? "choose-country___zh",
    path: choose_45countrypzaL9LRzvcMeta?.path ?? "/zh/choose-country",
    meta: choose_45countrypzaL9LRzvcMeta || {},
    alias: choose_45countrypzaL9LRzvcMeta?.alias || [],
    redirect: choose_45countrypzaL9LRzvcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/choose-country.vue").then(m => m.default || m)
  },
  {
    name: choose_45countrypzaL9LRzvcMeta?.name ?? "choose-country___zh-tw",
    path: choose_45countrypzaL9LRzvcMeta?.path ?? "/zh-tw/choose-country",
    meta: choose_45countrypzaL9LRzvcMeta || {},
    alias: choose_45countrypzaL9LRzvcMeta?.alias || [],
    redirect: choose_45countrypzaL9LRzvcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/choose-country.vue").then(m => m.default || m)
  },
  {
    name: choose_45countrypzaL9LRzvcMeta?.name ?? "choose-country___th",
    path: choose_45countrypzaL9LRzvcMeta?.path ?? "/th/choose-country",
    meta: choose_45countrypzaL9LRzvcMeta || {},
    alias: choose_45countrypzaL9LRzvcMeta?.alias || [],
    redirect: choose_45countrypzaL9LRzvcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/choose-country.vue").then(m => m.default || m)
  },
  {
    name: choose_45countrypzaL9LRzvcMeta?.name ?? "choose-country___ja",
    path: choose_45countrypzaL9LRzvcMeta?.path ?? "/ja/choose-country",
    meta: choose_45countrypzaL9LRzvcMeta || {},
    alias: choose_45countrypzaL9LRzvcMeta?.alias || [],
    redirect: choose_45countrypzaL9LRzvcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/choose-country.vue").then(m => m.default || m)
  },
  {
    name: choose_45countrypzaL9LRzvcMeta?.name ?? "choose-country___ms",
    path: choose_45countrypzaL9LRzvcMeta?.path ?? "/ms/choose-country",
    meta: choose_45countrypzaL9LRzvcMeta || {},
    alias: choose_45countrypzaL9LRzvcMeta?.alias || [],
    redirect: choose_45countrypzaL9LRzvcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/choose-country.vue").then(m => m.default || m)
  },
  {
    name: choose_45countrypzaL9LRzvcMeta?.name ?? "choose-country___ko",
    path: choose_45countrypzaL9LRzvcMeta?.path ?? "/ko/choose-country",
    meta: choose_45countrypzaL9LRzvcMeta || {},
    alias: choose_45countrypzaL9LRzvcMeta?.alias || [],
    redirect: choose_45countrypzaL9LRzvcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/choose-country.vue").then(m => m.default || m)
  },
  {
    name: choose_45countrypzaL9LRzvcMeta?.name ?? "choose-country___vi",
    path: choose_45countrypzaL9LRzvcMeta?.path ?? "/vi/choose-country",
    meta: choose_45countrypzaL9LRzvcMeta || {},
    alias: choose_45countrypzaL9LRzvcMeta?.alias || [],
    redirect: choose_45countrypzaL9LRzvcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/choose-country.vue").then(m => m.default || m)
  },
  {
    name: choose_45countrypzaL9LRzvcMeta?.name ?? "choose-country___it",
    path: choose_45countrypzaL9LRzvcMeta?.path ?? "/it/choose-country",
    meta: choose_45countrypzaL9LRzvcMeta || {},
    alias: choose_45countrypzaL9LRzvcMeta?.alias || [],
    redirect: choose_45countrypzaL9LRzvcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/choose-country.vue").then(m => m.default || m)
  },
  {
    name: choose_45countrypzaL9LRzvcMeta?.name ?? "choose-country___de",
    path: choose_45countrypzaL9LRzvcMeta?.path ?? "/de/choose-country",
    meta: choose_45countrypzaL9LRzvcMeta || {},
    alias: choose_45countrypzaL9LRzvcMeta?.alias || [],
    redirect: choose_45countrypzaL9LRzvcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/choose-country.vue").then(m => m.default || m)
  },
  {
    name: choose_45countrypzaL9LRzvcMeta?.name ?? "choose-country___es",
    path: choose_45countrypzaL9LRzvcMeta?.path ?? "/es/choose-country",
    meta: choose_45countrypzaL9LRzvcMeta || {},
    alias: choose_45countrypzaL9LRzvcMeta?.alias || [],
    redirect: choose_45countrypzaL9LRzvcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/choose-country.vue").then(m => m.default || m)
  },
  {
    name: choose_45countrypzaL9LRzvcMeta?.name ?? "choose-country___hi",
    path: choose_45countrypzaL9LRzvcMeta?.path ?? "/hi/choose-country",
    meta: choose_45countrypzaL9LRzvcMeta || {},
    alias: choose_45countrypzaL9LRzvcMeta?.alias || [],
    redirect: choose_45countrypzaL9LRzvcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/choose-country.vue").then(m => m.default || m)
  },
  {
    name: choose_45countrypzaL9LRzvcMeta?.name ?? "choose-country___fr",
    path: choose_45countrypzaL9LRzvcMeta?.path ?? "/fr/choose-country",
    meta: choose_45countrypzaL9LRzvcMeta || {},
    alias: choose_45countrypzaL9LRzvcMeta?.alias || [],
    redirect: choose_45countrypzaL9LRzvcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/choose-country.vue").then(m => m.default || m)
  },
  {
    name: choose_45countrypzaL9LRzvcMeta?.name ?? "choose-country___ru",
    path: choose_45countrypzaL9LRzvcMeta?.path ?? "/ru/choose-country",
    meta: choose_45countrypzaL9LRzvcMeta || {},
    alias: choose_45countrypzaL9LRzvcMeta?.alias || [],
    redirect: choose_45countrypzaL9LRzvcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/choose-country.vue").then(m => m.default || m)
  },
  {
    name: choose_45countrypzaL9LRzvcMeta?.name ?? "choose-country___bn",
    path: choose_45countrypzaL9LRzvcMeta?.path ?? "/bn/choose-country",
    meta: choose_45countrypzaL9LRzvcMeta || {},
    alias: choose_45countrypzaL9LRzvcMeta?.alias || [],
    redirect: choose_45countrypzaL9LRzvcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/choose-country.vue").then(m => m.default || m)
  },
  {
    name: choose_45countrypzaL9LRzvcMeta?.name ?? "choose-country___pt",
    path: choose_45countrypzaL9LRzvcMeta?.path ?? "/pt/choose-country",
    meta: choose_45countrypzaL9LRzvcMeta || {},
    alias: choose_45countrypzaL9LRzvcMeta?.alias || [],
    redirect: choose_45countrypzaL9LRzvcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/choose-country.vue").then(m => m.default || m)
  },
  {
    name: choose_45countrypzaL9LRzvcMeta?.name ?? "choose-country___km",
    path: choose_45countrypzaL9LRzvcMeta?.path ?? "/km/choose-country",
    meta: choose_45countrypzaL9LRzvcMeta || {},
    alias: choose_45countrypzaL9LRzvcMeta?.alias || [],
    redirect: choose_45countrypzaL9LRzvcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/choose-country.vue").then(m => m.default || m)
  },
  {
    name: contactVUzjXKaIJBMeta?.name ?? "contact___en",
    path: contactVUzjXKaIJBMeta?.path ?? "/en/contact",
    meta: contactVUzjXKaIJBMeta || {},
    alias: contactVUzjXKaIJBMeta?.alias || [],
    redirect: contactVUzjXKaIJBMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactVUzjXKaIJBMeta?.name ?? "contact___id",
    path: contactVUzjXKaIJBMeta?.path ?? "/id/contact",
    meta: contactVUzjXKaIJBMeta || {},
    alias: contactVUzjXKaIJBMeta?.alias || [],
    redirect: contactVUzjXKaIJBMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactVUzjXKaIJBMeta?.name ?? "contact___tl",
    path: contactVUzjXKaIJBMeta?.path ?? "/tl/contact",
    meta: contactVUzjXKaIJBMeta || {},
    alias: contactVUzjXKaIJBMeta?.alias || [],
    redirect: contactVUzjXKaIJBMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactVUzjXKaIJBMeta?.name ?? "contact___zh",
    path: contactVUzjXKaIJBMeta?.path ?? "/zh/contact",
    meta: contactVUzjXKaIJBMeta || {},
    alias: contactVUzjXKaIJBMeta?.alias || [],
    redirect: contactVUzjXKaIJBMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactVUzjXKaIJBMeta?.name ?? "contact___zh-tw",
    path: contactVUzjXKaIJBMeta?.path ?? "/zh-tw/contact",
    meta: contactVUzjXKaIJBMeta || {},
    alias: contactVUzjXKaIJBMeta?.alias || [],
    redirect: contactVUzjXKaIJBMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactVUzjXKaIJBMeta?.name ?? "contact___th",
    path: contactVUzjXKaIJBMeta?.path ?? "/th/contact",
    meta: contactVUzjXKaIJBMeta || {},
    alias: contactVUzjXKaIJBMeta?.alias || [],
    redirect: contactVUzjXKaIJBMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactVUzjXKaIJBMeta?.name ?? "contact___ja",
    path: contactVUzjXKaIJBMeta?.path ?? "/ja/contact",
    meta: contactVUzjXKaIJBMeta || {},
    alias: contactVUzjXKaIJBMeta?.alias || [],
    redirect: contactVUzjXKaIJBMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactVUzjXKaIJBMeta?.name ?? "contact___ms",
    path: contactVUzjXKaIJBMeta?.path ?? "/ms/contact",
    meta: contactVUzjXKaIJBMeta || {},
    alias: contactVUzjXKaIJBMeta?.alias || [],
    redirect: contactVUzjXKaIJBMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactVUzjXKaIJBMeta?.name ?? "contact___ko",
    path: contactVUzjXKaIJBMeta?.path ?? "/ko/contact",
    meta: contactVUzjXKaIJBMeta || {},
    alias: contactVUzjXKaIJBMeta?.alias || [],
    redirect: contactVUzjXKaIJBMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactVUzjXKaIJBMeta?.name ?? "contact___vi",
    path: contactVUzjXKaIJBMeta?.path ?? "/vi/contact",
    meta: contactVUzjXKaIJBMeta || {},
    alias: contactVUzjXKaIJBMeta?.alias || [],
    redirect: contactVUzjXKaIJBMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactVUzjXKaIJBMeta?.name ?? "contact___it",
    path: contactVUzjXKaIJBMeta?.path ?? "/it/contact",
    meta: contactVUzjXKaIJBMeta || {},
    alias: contactVUzjXKaIJBMeta?.alias || [],
    redirect: contactVUzjXKaIJBMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactVUzjXKaIJBMeta?.name ?? "contact___de",
    path: contactVUzjXKaIJBMeta?.path ?? "/de/contact",
    meta: contactVUzjXKaIJBMeta || {},
    alias: contactVUzjXKaIJBMeta?.alias || [],
    redirect: contactVUzjXKaIJBMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactVUzjXKaIJBMeta?.name ?? "contact___es",
    path: contactVUzjXKaIJBMeta?.path ?? "/es/contact",
    meta: contactVUzjXKaIJBMeta || {},
    alias: contactVUzjXKaIJBMeta?.alias || [],
    redirect: contactVUzjXKaIJBMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactVUzjXKaIJBMeta?.name ?? "contact___hi",
    path: contactVUzjXKaIJBMeta?.path ?? "/hi/contact",
    meta: contactVUzjXKaIJBMeta || {},
    alias: contactVUzjXKaIJBMeta?.alias || [],
    redirect: contactVUzjXKaIJBMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactVUzjXKaIJBMeta?.name ?? "contact___fr",
    path: contactVUzjXKaIJBMeta?.path ?? "/fr/contact",
    meta: contactVUzjXKaIJBMeta || {},
    alias: contactVUzjXKaIJBMeta?.alias || [],
    redirect: contactVUzjXKaIJBMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactVUzjXKaIJBMeta?.name ?? "contact___ru",
    path: contactVUzjXKaIJBMeta?.path ?? "/ru/contact",
    meta: contactVUzjXKaIJBMeta || {},
    alias: contactVUzjXKaIJBMeta?.alias || [],
    redirect: contactVUzjXKaIJBMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactVUzjXKaIJBMeta?.name ?? "contact___bn",
    path: contactVUzjXKaIJBMeta?.path ?? "/bn/contact",
    meta: contactVUzjXKaIJBMeta || {},
    alias: contactVUzjXKaIJBMeta?.alias || [],
    redirect: contactVUzjXKaIJBMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactVUzjXKaIJBMeta?.name ?? "contact___pt",
    path: contactVUzjXKaIJBMeta?.path ?? "/pt/contact",
    meta: contactVUzjXKaIJBMeta || {},
    alias: contactVUzjXKaIJBMeta?.alias || [],
    redirect: contactVUzjXKaIJBMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactVUzjXKaIJBMeta?.name ?? "contact___km",
    path: contactVUzjXKaIJBMeta?.path ?? "/km/contact",
    meta: contactVUzjXKaIJBMeta || {},
    alias: contactVUzjXKaIJBMeta?.alias || [],
    redirect: contactVUzjXKaIJBMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: covid19Lv4bLhZsLoMeta?.name ?? "covid19___en",
    path: covid19Lv4bLhZsLoMeta?.path ?? "/en/covid19",
    meta: covid19Lv4bLhZsLoMeta || {},
    alias: covid19Lv4bLhZsLoMeta?.alias || [],
    redirect: covid19Lv4bLhZsLoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/covid19.vue").then(m => m.default || m)
  },
  {
    name: covid19Lv4bLhZsLoMeta?.name ?? "covid19___id",
    path: covid19Lv4bLhZsLoMeta?.path ?? "/id/covid19",
    meta: covid19Lv4bLhZsLoMeta || {},
    alias: covid19Lv4bLhZsLoMeta?.alias || [],
    redirect: covid19Lv4bLhZsLoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/covid19.vue").then(m => m.default || m)
  },
  {
    name: covid19Lv4bLhZsLoMeta?.name ?? "covid19___tl",
    path: covid19Lv4bLhZsLoMeta?.path ?? "/tl/covid19",
    meta: covid19Lv4bLhZsLoMeta || {},
    alias: covid19Lv4bLhZsLoMeta?.alias || [],
    redirect: covid19Lv4bLhZsLoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/covid19.vue").then(m => m.default || m)
  },
  {
    name: covid19Lv4bLhZsLoMeta?.name ?? "covid19___zh",
    path: covid19Lv4bLhZsLoMeta?.path ?? "/zh/covid19",
    meta: covid19Lv4bLhZsLoMeta || {},
    alias: covid19Lv4bLhZsLoMeta?.alias || [],
    redirect: covid19Lv4bLhZsLoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/covid19.vue").then(m => m.default || m)
  },
  {
    name: covid19Lv4bLhZsLoMeta?.name ?? "covid19___zh-tw",
    path: covid19Lv4bLhZsLoMeta?.path ?? "/zh-tw/covid19",
    meta: covid19Lv4bLhZsLoMeta || {},
    alias: covid19Lv4bLhZsLoMeta?.alias || [],
    redirect: covid19Lv4bLhZsLoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/covid19.vue").then(m => m.default || m)
  },
  {
    name: covid19Lv4bLhZsLoMeta?.name ?? "covid19___th",
    path: covid19Lv4bLhZsLoMeta?.path ?? "/th/covid19",
    meta: covid19Lv4bLhZsLoMeta || {},
    alias: covid19Lv4bLhZsLoMeta?.alias || [],
    redirect: covid19Lv4bLhZsLoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/covid19.vue").then(m => m.default || m)
  },
  {
    name: covid19Lv4bLhZsLoMeta?.name ?? "covid19___ja",
    path: covid19Lv4bLhZsLoMeta?.path ?? "/ja/covid19",
    meta: covid19Lv4bLhZsLoMeta || {},
    alias: covid19Lv4bLhZsLoMeta?.alias || [],
    redirect: covid19Lv4bLhZsLoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/covid19.vue").then(m => m.default || m)
  },
  {
    name: covid19Lv4bLhZsLoMeta?.name ?? "covid19___ms",
    path: covid19Lv4bLhZsLoMeta?.path ?? "/ms/covid19",
    meta: covid19Lv4bLhZsLoMeta || {},
    alias: covid19Lv4bLhZsLoMeta?.alias || [],
    redirect: covid19Lv4bLhZsLoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/covid19.vue").then(m => m.default || m)
  },
  {
    name: covid19Lv4bLhZsLoMeta?.name ?? "covid19___ko",
    path: covid19Lv4bLhZsLoMeta?.path ?? "/ko/covid19",
    meta: covid19Lv4bLhZsLoMeta || {},
    alias: covid19Lv4bLhZsLoMeta?.alias || [],
    redirect: covid19Lv4bLhZsLoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/covid19.vue").then(m => m.default || m)
  },
  {
    name: covid19Lv4bLhZsLoMeta?.name ?? "covid19___vi",
    path: covid19Lv4bLhZsLoMeta?.path ?? "/vi/covid19",
    meta: covid19Lv4bLhZsLoMeta || {},
    alias: covid19Lv4bLhZsLoMeta?.alias || [],
    redirect: covid19Lv4bLhZsLoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/covid19.vue").then(m => m.default || m)
  },
  {
    name: covid19Lv4bLhZsLoMeta?.name ?? "covid19___it",
    path: covid19Lv4bLhZsLoMeta?.path ?? "/it/covid19",
    meta: covid19Lv4bLhZsLoMeta || {},
    alias: covid19Lv4bLhZsLoMeta?.alias || [],
    redirect: covid19Lv4bLhZsLoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/covid19.vue").then(m => m.default || m)
  },
  {
    name: covid19Lv4bLhZsLoMeta?.name ?? "covid19___de",
    path: covid19Lv4bLhZsLoMeta?.path ?? "/de/covid19",
    meta: covid19Lv4bLhZsLoMeta || {},
    alias: covid19Lv4bLhZsLoMeta?.alias || [],
    redirect: covid19Lv4bLhZsLoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/covid19.vue").then(m => m.default || m)
  },
  {
    name: covid19Lv4bLhZsLoMeta?.name ?? "covid19___es",
    path: covid19Lv4bLhZsLoMeta?.path ?? "/es/covid19",
    meta: covid19Lv4bLhZsLoMeta || {},
    alias: covid19Lv4bLhZsLoMeta?.alias || [],
    redirect: covid19Lv4bLhZsLoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/covid19.vue").then(m => m.default || m)
  },
  {
    name: covid19Lv4bLhZsLoMeta?.name ?? "covid19___hi",
    path: covid19Lv4bLhZsLoMeta?.path ?? "/hi/covid19",
    meta: covid19Lv4bLhZsLoMeta || {},
    alias: covid19Lv4bLhZsLoMeta?.alias || [],
    redirect: covid19Lv4bLhZsLoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/covid19.vue").then(m => m.default || m)
  },
  {
    name: covid19Lv4bLhZsLoMeta?.name ?? "covid19___fr",
    path: covid19Lv4bLhZsLoMeta?.path ?? "/fr/covid19",
    meta: covid19Lv4bLhZsLoMeta || {},
    alias: covid19Lv4bLhZsLoMeta?.alias || [],
    redirect: covid19Lv4bLhZsLoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/covid19.vue").then(m => m.default || m)
  },
  {
    name: covid19Lv4bLhZsLoMeta?.name ?? "covid19___ru",
    path: covid19Lv4bLhZsLoMeta?.path ?? "/ru/covid19",
    meta: covid19Lv4bLhZsLoMeta || {},
    alias: covid19Lv4bLhZsLoMeta?.alias || [],
    redirect: covid19Lv4bLhZsLoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/covid19.vue").then(m => m.default || m)
  },
  {
    name: covid19Lv4bLhZsLoMeta?.name ?? "covid19___bn",
    path: covid19Lv4bLhZsLoMeta?.path ?? "/bn/covid19",
    meta: covid19Lv4bLhZsLoMeta || {},
    alias: covid19Lv4bLhZsLoMeta?.alias || [],
    redirect: covid19Lv4bLhZsLoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/covid19.vue").then(m => m.default || m)
  },
  {
    name: covid19Lv4bLhZsLoMeta?.name ?? "covid19___pt",
    path: covid19Lv4bLhZsLoMeta?.path ?? "/pt/covid19",
    meta: covid19Lv4bLhZsLoMeta || {},
    alias: covid19Lv4bLhZsLoMeta?.alias || [],
    redirect: covid19Lv4bLhZsLoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/covid19.vue").then(m => m.default || m)
  },
  {
    name: covid19Lv4bLhZsLoMeta?.name ?? "covid19___km",
    path: covid19Lv4bLhZsLoMeta?.path ?? "/km/covid19",
    meta: covid19Lv4bLhZsLoMeta || {},
    alias: covid19Lv4bLhZsLoMeta?.alias || [],
    redirect: covid19Lv4bLhZsLoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/covid19.vue").then(m => m.default || m)
  },
  {
    name: feedback_45formN32B0TDKx7Meta?.name ?? "feedback-form___en",
    path: feedback_45formN32B0TDKx7Meta?.path ?? "/en/feedback-form",
    meta: feedback_45formN32B0TDKx7Meta || {},
    alias: feedback_45formN32B0TDKx7Meta?.alias || [],
    redirect: feedback_45formN32B0TDKx7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/feedback-form.vue").then(m => m.default || m)
  },
  {
    name: feedback_45formN32B0TDKx7Meta?.name ?? "feedback-form___id",
    path: feedback_45formN32B0TDKx7Meta?.path ?? "/id/feedback-form",
    meta: feedback_45formN32B0TDKx7Meta || {},
    alias: feedback_45formN32B0TDKx7Meta?.alias || [],
    redirect: feedback_45formN32B0TDKx7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/feedback-form.vue").then(m => m.default || m)
  },
  {
    name: feedback_45formN32B0TDKx7Meta?.name ?? "feedback-form___tl",
    path: feedback_45formN32B0TDKx7Meta?.path ?? "/tl/feedback-form",
    meta: feedback_45formN32B0TDKx7Meta || {},
    alias: feedback_45formN32B0TDKx7Meta?.alias || [],
    redirect: feedback_45formN32B0TDKx7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/feedback-form.vue").then(m => m.default || m)
  },
  {
    name: feedback_45formN32B0TDKx7Meta?.name ?? "feedback-form___zh",
    path: feedback_45formN32B0TDKx7Meta?.path ?? "/zh/feedback-form",
    meta: feedback_45formN32B0TDKx7Meta || {},
    alias: feedback_45formN32B0TDKx7Meta?.alias || [],
    redirect: feedback_45formN32B0TDKx7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/feedback-form.vue").then(m => m.default || m)
  },
  {
    name: feedback_45formN32B0TDKx7Meta?.name ?? "feedback-form___zh-tw",
    path: feedback_45formN32B0TDKx7Meta?.path ?? "/zh-tw/feedback-form",
    meta: feedback_45formN32B0TDKx7Meta || {},
    alias: feedback_45formN32B0TDKx7Meta?.alias || [],
    redirect: feedback_45formN32B0TDKx7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/feedback-form.vue").then(m => m.default || m)
  },
  {
    name: feedback_45formN32B0TDKx7Meta?.name ?? "feedback-form___th",
    path: feedback_45formN32B0TDKx7Meta?.path ?? "/th/feedback-form",
    meta: feedback_45formN32B0TDKx7Meta || {},
    alias: feedback_45formN32B0TDKx7Meta?.alias || [],
    redirect: feedback_45formN32B0TDKx7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/feedback-form.vue").then(m => m.default || m)
  },
  {
    name: feedback_45formN32B0TDKx7Meta?.name ?? "feedback-form___ja",
    path: feedback_45formN32B0TDKx7Meta?.path ?? "/ja/feedback-form",
    meta: feedback_45formN32B0TDKx7Meta || {},
    alias: feedback_45formN32B0TDKx7Meta?.alias || [],
    redirect: feedback_45formN32B0TDKx7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/feedback-form.vue").then(m => m.default || m)
  },
  {
    name: feedback_45formN32B0TDKx7Meta?.name ?? "feedback-form___ms",
    path: feedback_45formN32B0TDKx7Meta?.path ?? "/ms/feedback-form",
    meta: feedback_45formN32B0TDKx7Meta || {},
    alias: feedback_45formN32B0TDKx7Meta?.alias || [],
    redirect: feedback_45formN32B0TDKx7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/feedback-form.vue").then(m => m.default || m)
  },
  {
    name: feedback_45formN32B0TDKx7Meta?.name ?? "feedback-form___ko",
    path: feedback_45formN32B0TDKx7Meta?.path ?? "/ko/feedback-form",
    meta: feedback_45formN32B0TDKx7Meta || {},
    alias: feedback_45formN32B0TDKx7Meta?.alias || [],
    redirect: feedback_45formN32B0TDKx7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/feedback-form.vue").then(m => m.default || m)
  },
  {
    name: feedback_45formN32B0TDKx7Meta?.name ?? "feedback-form___vi",
    path: feedback_45formN32B0TDKx7Meta?.path ?? "/vi/feedback-form",
    meta: feedback_45formN32B0TDKx7Meta || {},
    alias: feedback_45formN32B0TDKx7Meta?.alias || [],
    redirect: feedback_45formN32B0TDKx7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/feedback-form.vue").then(m => m.default || m)
  },
  {
    name: feedback_45formN32B0TDKx7Meta?.name ?? "feedback-form___it",
    path: feedback_45formN32B0TDKx7Meta?.path ?? "/it/feedback-form",
    meta: feedback_45formN32B0TDKx7Meta || {},
    alias: feedback_45formN32B0TDKx7Meta?.alias || [],
    redirect: feedback_45formN32B0TDKx7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/feedback-form.vue").then(m => m.default || m)
  },
  {
    name: feedback_45formN32B0TDKx7Meta?.name ?? "feedback-form___de",
    path: feedback_45formN32B0TDKx7Meta?.path ?? "/de/feedback-form",
    meta: feedback_45formN32B0TDKx7Meta || {},
    alias: feedback_45formN32B0TDKx7Meta?.alias || [],
    redirect: feedback_45formN32B0TDKx7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/feedback-form.vue").then(m => m.default || m)
  },
  {
    name: feedback_45formN32B0TDKx7Meta?.name ?? "feedback-form___es",
    path: feedback_45formN32B0TDKx7Meta?.path ?? "/es/feedback-form",
    meta: feedback_45formN32B0TDKx7Meta || {},
    alias: feedback_45formN32B0TDKx7Meta?.alias || [],
    redirect: feedback_45formN32B0TDKx7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/feedback-form.vue").then(m => m.default || m)
  },
  {
    name: feedback_45formN32B0TDKx7Meta?.name ?? "feedback-form___hi",
    path: feedback_45formN32B0TDKx7Meta?.path ?? "/hi/feedback-form",
    meta: feedback_45formN32B0TDKx7Meta || {},
    alias: feedback_45formN32B0TDKx7Meta?.alias || [],
    redirect: feedback_45formN32B0TDKx7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/feedback-form.vue").then(m => m.default || m)
  },
  {
    name: feedback_45formN32B0TDKx7Meta?.name ?? "feedback-form___fr",
    path: feedback_45formN32B0TDKx7Meta?.path ?? "/fr/feedback-form",
    meta: feedback_45formN32B0TDKx7Meta || {},
    alias: feedback_45formN32B0TDKx7Meta?.alias || [],
    redirect: feedback_45formN32B0TDKx7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/feedback-form.vue").then(m => m.default || m)
  },
  {
    name: feedback_45formN32B0TDKx7Meta?.name ?? "feedback-form___ru",
    path: feedback_45formN32B0TDKx7Meta?.path ?? "/ru/feedback-form",
    meta: feedback_45formN32B0TDKx7Meta || {},
    alias: feedback_45formN32B0TDKx7Meta?.alias || [],
    redirect: feedback_45formN32B0TDKx7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/feedback-form.vue").then(m => m.default || m)
  },
  {
    name: feedback_45formN32B0TDKx7Meta?.name ?? "feedback-form___bn",
    path: feedback_45formN32B0TDKx7Meta?.path ?? "/bn/feedback-form",
    meta: feedback_45formN32B0TDKx7Meta || {},
    alias: feedback_45formN32B0TDKx7Meta?.alias || [],
    redirect: feedback_45formN32B0TDKx7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/feedback-form.vue").then(m => m.default || m)
  },
  {
    name: feedback_45formN32B0TDKx7Meta?.name ?? "feedback-form___pt",
    path: feedback_45formN32B0TDKx7Meta?.path ?? "/pt/feedback-form",
    meta: feedback_45formN32B0TDKx7Meta || {},
    alias: feedback_45formN32B0TDKx7Meta?.alias || [],
    redirect: feedback_45formN32B0TDKx7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/feedback-form.vue").then(m => m.default || m)
  },
  {
    name: feedback_45formN32B0TDKx7Meta?.name ?? "feedback-form___km",
    path: feedback_45formN32B0TDKx7Meta?.path ?? "/km/feedback-form",
    meta: feedback_45formN32B0TDKx7Meta || {},
    alias: feedback_45formN32B0TDKx7Meta?.alias || [],
    redirect: feedback_45formN32B0TDKx7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/feedback-form.vue").then(m => m.default || m)
  },
  {
    name: _91product_93W4po7kMCUGMeta?.name ?? "flight-add-ons-bookId-product___en",
    path: _91product_93W4po7kMCUGMeta?.path ?? "/en/flight/add-ons/:bookId()/:product()",
    meta: _91product_93W4po7kMCUGMeta || {},
    alias: _91product_93W4po7kMCUGMeta?.alias || [],
    redirect: _91product_93W4po7kMCUGMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/add-ons/[bookId]/[product].vue").then(m => m.default || m)
  },
  {
    name: _91product_93W4po7kMCUGMeta?.name ?? "flight-add-ons-bookId-product___id",
    path: _91product_93W4po7kMCUGMeta?.path ?? "/id/flight/add-ons/:bookId()/:product()",
    meta: _91product_93W4po7kMCUGMeta || {},
    alias: _91product_93W4po7kMCUGMeta?.alias || [],
    redirect: _91product_93W4po7kMCUGMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/add-ons/[bookId]/[product].vue").then(m => m.default || m)
  },
  {
    name: _91product_93W4po7kMCUGMeta?.name ?? "flight-add-ons-bookId-product___tl",
    path: _91product_93W4po7kMCUGMeta?.path ?? "/tl/flight/add-ons/:bookId()/:product()",
    meta: _91product_93W4po7kMCUGMeta || {},
    alias: _91product_93W4po7kMCUGMeta?.alias || [],
    redirect: _91product_93W4po7kMCUGMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/add-ons/[bookId]/[product].vue").then(m => m.default || m)
  },
  {
    name: _91product_93W4po7kMCUGMeta?.name ?? "flight-add-ons-bookId-product___zh",
    path: _91product_93W4po7kMCUGMeta?.path ?? "/zh/flight/add-ons/:bookId()/:product()",
    meta: _91product_93W4po7kMCUGMeta || {},
    alias: _91product_93W4po7kMCUGMeta?.alias || [],
    redirect: _91product_93W4po7kMCUGMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/add-ons/[bookId]/[product].vue").then(m => m.default || m)
  },
  {
    name: _91product_93W4po7kMCUGMeta?.name ?? "flight-add-ons-bookId-product___zh-tw",
    path: _91product_93W4po7kMCUGMeta?.path ?? "/zh-tw/flight/add-ons/:bookId()/:product()",
    meta: _91product_93W4po7kMCUGMeta || {},
    alias: _91product_93W4po7kMCUGMeta?.alias || [],
    redirect: _91product_93W4po7kMCUGMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/add-ons/[bookId]/[product].vue").then(m => m.default || m)
  },
  {
    name: _91product_93W4po7kMCUGMeta?.name ?? "flight-add-ons-bookId-product___th",
    path: _91product_93W4po7kMCUGMeta?.path ?? "/th/flight/add-ons/:bookId()/:product()",
    meta: _91product_93W4po7kMCUGMeta || {},
    alias: _91product_93W4po7kMCUGMeta?.alias || [],
    redirect: _91product_93W4po7kMCUGMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/add-ons/[bookId]/[product].vue").then(m => m.default || m)
  },
  {
    name: _91product_93W4po7kMCUGMeta?.name ?? "flight-add-ons-bookId-product___ja",
    path: _91product_93W4po7kMCUGMeta?.path ?? "/ja/flight/add-ons/:bookId()/:product()",
    meta: _91product_93W4po7kMCUGMeta || {},
    alias: _91product_93W4po7kMCUGMeta?.alias || [],
    redirect: _91product_93W4po7kMCUGMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/add-ons/[bookId]/[product].vue").then(m => m.default || m)
  },
  {
    name: _91product_93W4po7kMCUGMeta?.name ?? "flight-add-ons-bookId-product___ms",
    path: _91product_93W4po7kMCUGMeta?.path ?? "/ms/flight/add-ons/:bookId()/:product()",
    meta: _91product_93W4po7kMCUGMeta || {},
    alias: _91product_93W4po7kMCUGMeta?.alias || [],
    redirect: _91product_93W4po7kMCUGMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/add-ons/[bookId]/[product].vue").then(m => m.default || m)
  },
  {
    name: _91product_93W4po7kMCUGMeta?.name ?? "flight-add-ons-bookId-product___ko",
    path: _91product_93W4po7kMCUGMeta?.path ?? "/ko/flight/add-ons/:bookId()/:product()",
    meta: _91product_93W4po7kMCUGMeta || {},
    alias: _91product_93W4po7kMCUGMeta?.alias || [],
    redirect: _91product_93W4po7kMCUGMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/add-ons/[bookId]/[product].vue").then(m => m.default || m)
  },
  {
    name: _91product_93W4po7kMCUGMeta?.name ?? "flight-add-ons-bookId-product___vi",
    path: _91product_93W4po7kMCUGMeta?.path ?? "/vi/flight/add-ons/:bookId()/:product()",
    meta: _91product_93W4po7kMCUGMeta || {},
    alias: _91product_93W4po7kMCUGMeta?.alias || [],
    redirect: _91product_93W4po7kMCUGMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/add-ons/[bookId]/[product].vue").then(m => m.default || m)
  },
  {
    name: _91product_93W4po7kMCUGMeta?.name ?? "flight-add-ons-bookId-product___it",
    path: _91product_93W4po7kMCUGMeta?.path ?? "/it/flight/add-ons/:bookId()/:product()",
    meta: _91product_93W4po7kMCUGMeta || {},
    alias: _91product_93W4po7kMCUGMeta?.alias || [],
    redirect: _91product_93W4po7kMCUGMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/add-ons/[bookId]/[product].vue").then(m => m.default || m)
  },
  {
    name: _91product_93W4po7kMCUGMeta?.name ?? "flight-add-ons-bookId-product___de",
    path: _91product_93W4po7kMCUGMeta?.path ?? "/de/flight/add-ons/:bookId()/:product()",
    meta: _91product_93W4po7kMCUGMeta || {},
    alias: _91product_93W4po7kMCUGMeta?.alias || [],
    redirect: _91product_93W4po7kMCUGMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/add-ons/[bookId]/[product].vue").then(m => m.default || m)
  },
  {
    name: _91product_93W4po7kMCUGMeta?.name ?? "flight-add-ons-bookId-product___es",
    path: _91product_93W4po7kMCUGMeta?.path ?? "/es/flight/add-ons/:bookId()/:product()",
    meta: _91product_93W4po7kMCUGMeta || {},
    alias: _91product_93W4po7kMCUGMeta?.alias || [],
    redirect: _91product_93W4po7kMCUGMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/add-ons/[bookId]/[product].vue").then(m => m.default || m)
  },
  {
    name: _91product_93W4po7kMCUGMeta?.name ?? "flight-add-ons-bookId-product___hi",
    path: _91product_93W4po7kMCUGMeta?.path ?? "/hi/flight/add-ons/:bookId()/:product()",
    meta: _91product_93W4po7kMCUGMeta || {},
    alias: _91product_93W4po7kMCUGMeta?.alias || [],
    redirect: _91product_93W4po7kMCUGMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/add-ons/[bookId]/[product].vue").then(m => m.default || m)
  },
  {
    name: _91product_93W4po7kMCUGMeta?.name ?? "flight-add-ons-bookId-product___fr",
    path: _91product_93W4po7kMCUGMeta?.path ?? "/fr/flight/add-ons/:bookId()/:product()",
    meta: _91product_93W4po7kMCUGMeta || {},
    alias: _91product_93W4po7kMCUGMeta?.alias || [],
    redirect: _91product_93W4po7kMCUGMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/add-ons/[bookId]/[product].vue").then(m => m.default || m)
  },
  {
    name: _91product_93W4po7kMCUGMeta?.name ?? "flight-add-ons-bookId-product___ru",
    path: _91product_93W4po7kMCUGMeta?.path ?? "/ru/flight/add-ons/:bookId()/:product()",
    meta: _91product_93W4po7kMCUGMeta || {},
    alias: _91product_93W4po7kMCUGMeta?.alias || [],
    redirect: _91product_93W4po7kMCUGMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/add-ons/[bookId]/[product].vue").then(m => m.default || m)
  },
  {
    name: _91product_93W4po7kMCUGMeta?.name ?? "flight-add-ons-bookId-product___bn",
    path: _91product_93W4po7kMCUGMeta?.path ?? "/bn/flight/add-ons/:bookId()/:product()",
    meta: _91product_93W4po7kMCUGMeta || {},
    alias: _91product_93W4po7kMCUGMeta?.alias || [],
    redirect: _91product_93W4po7kMCUGMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/add-ons/[bookId]/[product].vue").then(m => m.default || m)
  },
  {
    name: _91product_93W4po7kMCUGMeta?.name ?? "flight-add-ons-bookId-product___pt",
    path: _91product_93W4po7kMCUGMeta?.path ?? "/pt/flight/add-ons/:bookId()/:product()",
    meta: _91product_93W4po7kMCUGMeta || {},
    alias: _91product_93W4po7kMCUGMeta?.alias || [],
    redirect: _91product_93W4po7kMCUGMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/add-ons/[bookId]/[product].vue").then(m => m.default || m)
  },
  {
    name: _91product_93W4po7kMCUGMeta?.name ?? "flight-add-ons-bookId-product___km",
    path: _91product_93W4po7kMCUGMeta?.path ?? "/km/flight/add-ons/:bookId()/:product()",
    meta: _91product_93W4po7kMCUGMeta || {},
    alias: _91product_93W4po7kMCUGMeta?.alias || [],
    redirect: _91product_93W4po7kMCUGMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/add-ons/[bookId]/[product].vue").then(m => m.default || m)
  },
  {
    name: _91bookId_931qcML42IWFMeta?.name ?? "flight-book-bookId___en",
    path: _91bookId_931qcML42IWFMeta?.path ?? "/en/flight/book/:bookId()",
    children: [
  {
    name: addonsxGvSqqpWMJMeta?.name ?? "flight-book-bookId-addons___en",
    path: addonsxGvSqqpWMJMeta?.path ?? "addons",
    meta: addonsxGvSqqpWMJMeta || {},
    alias: addonsxGvSqqpWMJMeta?.alias || [],
    redirect: addonsxGvSqqpWMJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/book/[bookId]/addons.vue").then(m => m.default || m)
  }
],
    meta: _91bookId_931qcML42IWFMeta || {},
    alias: _91bookId_931qcML42IWFMeta?.alias || [],
    redirect: _91bookId_931qcML42IWFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/book/[bookId].vue").then(m => m.default || m)
  },
  {
    name: _91bookId_931qcML42IWFMeta?.name ?? "flight-book-bookId___id",
    path: _91bookId_931qcML42IWFMeta?.path ?? "/id/flight/book/:bookId()",
    children: [
  {
    name: addonsxGvSqqpWMJMeta?.name ?? "flight-book-bookId-addons___id",
    path: addonsxGvSqqpWMJMeta?.path ?? "addons",
    meta: addonsxGvSqqpWMJMeta || {},
    alias: addonsxGvSqqpWMJMeta?.alias || [],
    redirect: addonsxGvSqqpWMJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/book/[bookId]/addons.vue").then(m => m.default || m)
  }
],
    meta: _91bookId_931qcML42IWFMeta || {},
    alias: _91bookId_931qcML42IWFMeta?.alias || [],
    redirect: _91bookId_931qcML42IWFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/book/[bookId].vue").then(m => m.default || m)
  },
  {
    name: _91bookId_931qcML42IWFMeta?.name ?? "flight-book-bookId___tl",
    path: _91bookId_931qcML42IWFMeta?.path ?? "/tl/flight/book/:bookId()",
    children: [
  {
    name: addonsxGvSqqpWMJMeta?.name ?? "flight-book-bookId-addons___tl",
    path: addonsxGvSqqpWMJMeta?.path ?? "addons",
    meta: addonsxGvSqqpWMJMeta || {},
    alias: addonsxGvSqqpWMJMeta?.alias || [],
    redirect: addonsxGvSqqpWMJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/book/[bookId]/addons.vue").then(m => m.default || m)
  }
],
    meta: _91bookId_931qcML42IWFMeta || {},
    alias: _91bookId_931qcML42IWFMeta?.alias || [],
    redirect: _91bookId_931qcML42IWFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/book/[bookId].vue").then(m => m.default || m)
  },
  {
    name: _91bookId_931qcML42IWFMeta?.name ?? "flight-book-bookId___zh",
    path: _91bookId_931qcML42IWFMeta?.path ?? "/zh/flight/book/:bookId()",
    children: [
  {
    name: addonsxGvSqqpWMJMeta?.name ?? "flight-book-bookId-addons___zh",
    path: addonsxGvSqqpWMJMeta?.path ?? "addons",
    meta: addonsxGvSqqpWMJMeta || {},
    alias: addonsxGvSqqpWMJMeta?.alias || [],
    redirect: addonsxGvSqqpWMJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/book/[bookId]/addons.vue").then(m => m.default || m)
  }
],
    meta: _91bookId_931qcML42IWFMeta || {},
    alias: _91bookId_931qcML42IWFMeta?.alias || [],
    redirect: _91bookId_931qcML42IWFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/book/[bookId].vue").then(m => m.default || m)
  },
  {
    name: _91bookId_931qcML42IWFMeta?.name ?? "flight-book-bookId___zh-tw",
    path: _91bookId_931qcML42IWFMeta?.path ?? "/zh-tw/flight/book/:bookId()",
    children: [
  {
    name: addonsxGvSqqpWMJMeta?.name ?? "flight-book-bookId-addons___zh-tw",
    path: addonsxGvSqqpWMJMeta?.path ?? "addons",
    meta: addonsxGvSqqpWMJMeta || {},
    alias: addonsxGvSqqpWMJMeta?.alias || [],
    redirect: addonsxGvSqqpWMJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/book/[bookId]/addons.vue").then(m => m.default || m)
  }
],
    meta: _91bookId_931qcML42IWFMeta || {},
    alias: _91bookId_931qcML42IWFMeta?.alias || [],
    redirect: _91bookId_931qcML42IWFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/book/[bookId].vue").then(m => m.default || m)
  },
  {
    name: _91bookId_931qcML42IWFMeta?.name ?? "flight-book-bookId___th",
    path: _91bookId_931qcML42IWFMeta?.path ?? "/th/flight/book/:bookId()",
    children: [
  {
    name: addonsxGvSqqpWMJMeta?.name ?? "flight-book-bookId-addons___th",
    path: addonsxGvSqqpWMJMeta?.path ?? "addons",
    meta: addonsxGvSqqpWMJMeta || {},
    alias: addonsxGvSqqpWMJMeta?.alias || [],
    redirect: addonsxGvSqqpWMJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/book/[bookId]/addons.vue").then(m => m.default || m)
  }
],
    meta: _91bookId_931qcML42IWFMeta || {},
    alias: _91bookId_931qcML42IWFMeta?.alias || [],
    redirect: _91bookId_931qcML42IWFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/book/[bookId].vue").then(m => m.default || m)
  },
  {
    name: _91bookId_931qcML42IWFMeta?.name ?? "flight-book-bookId___ja",
    path: _91bookId_931qcML42IWFMeta?.path ?? "/ja/flight/book/:bookId()",
    children: [
  {
    name: addonsxGvSqqpWMJMeta?.name ?? "flight-book-bookId-addons___ja",
    path: addonsxGvSqqpWMJMeta?.path ?? "addons",
    meta: addonsxGvSqqpWMJMeta || {},
    alias: addonsxGvSqqpWMJMeta?.alias || [],
    redirect: addonsxGvSqqpWMJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/book/[bookId]/addons.vue").then(m => m.default || m)
  }
],
    meta: _91bookId_931qcML42IWFMeta || {},
    alias: _91bookId_931qcML42IWFMeta?.alias || [],
    redirect: _91bookId_931qcML42IWFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/book/[bookId].vue").then(m => m.default || m)
  },
  {
    name: _91bookId_931qcML42IWFMeta?.name ?? "flight-book-bookId___ms",
    path: _91bookId_931qcML42IWFMeta?.path ?? "/ms/flight/book/:bookId()",
    children: [
  {
    name: addonsxGvSqqpWMJMeta?.name ?? "flight-book-bookId-addons___ms",
    path: addonsxGvSqqpWMJMeta?.path ?? "addons",
    meta: addonsxGvSqqpWMJMeta || {},
    alias: addonsxGvSqqpWMJMeta?.alias || [],
    redirect: addonsxGvSqqpWMJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/book/[bookId]/addons.vue").then(m => m.default || m)
  }
],
    meta: _91bookId_931qcML42IWFMeta || {},
    alias: _91bookId_931qcML42IWFMeta?.alias || [],
    redirect: _91bookId_931qcML42IWFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/book/[bookId].vue").then(m => m.default || m)
  },
  {
    name: _91bookId_931qcML42IWFMeta?.name ?? "flight-book-bookId___ko",
    path: _91bookId_931qcML42IWFMeta?.path ?? "/ko/flight/book/:bookId()",
    children: [
  {
    name: addonsxGvSqqpWMJMeta?.name ?? "flight-book-bookId-addons___ko",
    path: addonsxGvSqqpWMJMeta?.path ?? "addons",
    meta: addonsxGvSqqpWMJMeta || {},
    alias: addonsxGvSqqpWMJMeta?.alias || [],
    redirect: addonsxGvSqqpWMJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/book/[bookId]/addons.vue").then(m => m.default || m)
  }
],
    meta: _91bookId_931qcML42IWFMeta || {},
    alias: _91bookId_931qcML42IWFMeta?.alias || [],
    redirect: _91bookId_931qcML42IWFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/book/[bookId].vue").then(m => m.default || m)
  },
  {
    name: _91bookId_931qcML42IWFMeta?.name ?? "flight-book-bookId___vi",
    path: _91bookId_931qcML42IWFMeta?.path ?? "/vi/flight/book/:bookId()",
    children: [
  {
    name: addonsxGvSqqpWMJMeta?.name ?? "flight-book-bookId-addons___vi",
    path: addonsxGvSqqpWMJMeta?.path ?? "addons",
    meta: addonsxGvSqqpWMJMeta || {},
    alias: addonsxGvSqqpWMJMeta?.alias || [],
    redirect: addonsxGvSqqpWMJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/book/[bookId]/addons.vue").then(m => m.default || m)
  }
],
    meta: _91bookId_931qcML42IWFMeta || {},
    alias: _91bookId_931qcML42IWFMeta?.alias || [],
    redirect: _91bookId_931qcML42IWFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/book/[bookId].vue").then(m => m.default || m)
  },
  {
    name: _91bookId_931qcML42IWFMeta?.name ?? "flight-book-bookId___it",
    path: _91bookId_931qcML42IWFMeta?.path ?? "/it/flight/book/:bookId()",
    children: [
  {
    name: addonsxGvSqqpWMJMeta?.name ?? "flight-book-bookId-addons___it",
    path: addonsxGvSqqpWMJMeta?.path ?? "addons",
    meta: addonsxGvSqqpWMJMeta || {},
    alias: addonsxGvSqqpWMJMeta?.alias || [],
    redirect: addonsxGvSqqpWMJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/book/[bookId]/addons.vue").then(m => m.default || m)
  }
],
    meta: _91bookId_931qcML42IWFMeta || {},
    alias: _91bookId_931qcML42IWFMeta?.alias || [],
    redirect: _91bookId_931qcML42IWFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/book/[bookId].vue").then(m => m.default || m)
  },
  {
    name: _91bookId_931qcML42IWFMeta?.name ?? "flight-book-bookId___de",
    path: _91bookId_931qcML42IWFMeta?.path ?? "/de/flight/book/:bookId()",
    children: [
  {
    name: addonsxGvSqqpWMJMeta?.name ?? "flight-book-bookId-addons___de",
    path: addonsxGvSqqpWMJMeta?.path ?? "addons",
    meta: addonsxGvSqqpWMJMeta || {},
    alias: addonsxGvSqqpWMJMeta?.alias || [],
    redirect: addonsxGvSqqpWMJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/book/[bookId]/addons.vue").then(m => m.default || m)
  }
],
    meta: _91bookId_931qcML42IWFMeta || {},
    alias: _91bookId_931qcML42IWFMeta?.alias || [],
    redirect: _91bookId_931qcML42IWFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/book/[bookId].vue").then(m => m.default || m)
  },
  {
    name: _91bookId_931qcML42IWFMeta?.name ?? "flight-book-bookId___es",
    path: _91bookId_931qcML42IWFMeta?.path ?? "/es/flight/book/:bookId()",
    children: [
  {
    name: addonsxGvSqqpWMJMeta?.name ?? "flight-book-bookId-addons___es",
    path: addonsxGvSqqpWMJMeta?.path ?? "addons",
    meta: addonsxGvSqqpWMJMeta || {},
    alias: addonsxGvSqqpWMJMeta?.alias || [],
    redirect: addonsxGvSqqpWMJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/book/[bookId]/addons.vue").then(m => m.default || m)
  }
],
    meta: _91bookId_931qcML42IWFMeta || {},
    alias: _91bookId_931qcML42IWFMeta?.alias || [],
    redirect: _91bookId_931qcML42IWFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/book/[bookId].vue").then(m => m.default || m)
  },
  {
    name: _91bookId_931qcML42IWFMeta?.name ?? "flight-book-bookId___hi",
    path: _91bookId_931qcML42IWFMeta?.path ?? "/hi/flight/book/:bookId()",
    children: [
  {
    name: addonsxGvSqqpWMJMeta?.name ?? "flight-book-bookId-addons___hi",
    path: addonsxGvSqqpWMJMeta?.path ?? "addons",
    meta: addonsxGvSqqpWMJMeta || {},
    alias: addonsxGvSqqpWMJMeta?.alias || [],
    redirect: addonsxGvSqqpWMJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/book/[bookId]/addons.vue").then(m => m.default || m)
  }
],
    meta: _91bookId_931qcML42IWFMeta || {},
    alias: _91bookId_931qcML42IWFMeta?.alias || [],
    redirect: _91bookId_931qcML42IWFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/book/[bookId].vue").then(m => m.default || m)
  },
  {
    name: _91bookId_931qcML42IWFMeta?.name ?? "flight-book-bookId___fr",
    path: _91bookId_931qcML42IWFMeta?.path ?? "/fr/flight/book/:bookId()",
    children: [
  {
    name: addonsxGvSqqpWMJMeta?.name ?? "flight-book-bookId-addons___fr",
    path: addonsxGvSqqpWMJMeta?.path ?? "addons",
    meta: addonsxGvSqqpWMJMeta || {},
    alias: addonsxGvSqqpWMJMeta?.alias || [],
    redirect: addonsxGvSqqpWMJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/book/[bookId]/addons.vue").then(m => m.default || m)
  }
],
    meta: _91bookId_931qcML42IWFMeta || {},
    alias: _91bookId_931qcML42IWFMeta?.alias || [],
    redirect: _91bookId_931qcML42IWFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/book/[bookId].vue").then(m => m.default || m)
  },
  {
    name: _91bookId_931qcML42IWFMeta?.name ?? "flight-book-bookId___ru",
    path: _91bookId_931qcML42IWFMeta?.path ?? "/ru/flight/book/:bookId()",
    children: [
  {
    name: addonsxGvSqqpWMJMeta?.name ?? "flight-book-bookId-addons___ru",
    path: addonsxGvSqqpWMJMeta?.path ?? "addons",
    meta: addonsxGvSqqpWMJMeta || {},
    alias: addonsxGvSqqpWMJMeta?.alias || [],
    redirect: addonsxGvSqqpWMJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/book/[bookId]/addons.vue").then(m => m.default || m)
  }
],
    meta: _91bookId_931qcML42IWFMeta || {},
    alias: _91bookId_931qcML42IWFMeta?.alias || [],
    redirect: _91bookId_931qcML42IWFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/book/[bookId].vue").then(m => m.default || m)
  },
  {
    name: _91bookId_931qcML42IWFMeta?.name ?? "flight-book-bookId___bn",
    path: _91bookId_931qcML42IWFMeta?.path ?? "/bn/flight/book/:bookId()",
    children: [
  {
    name: addonsxGvSqqpWMJMeta?.name ?? "flight-book-bookId-addons___bn",
    path: addonsxGvSqqpWMJMeta?.path ?? "addons",
    meta: addonsxGvSqqpWMJMeta || {},
    alias: addonsxGvSqqpWMJMeta?.alias || [],
    redirect: addonsxGvSqqpWMJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/book/[bookId]/addons.vue").then(m => m.default || m)
  }
],
    meta: _91bookId_931qcML42IWFMeta || {},
    alias: _91bookId_931qcML42IWFMeta?.alias || [],
    redirect: _91bookId_931qcML42IWFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/book/[bookId].vue").then(m => m.default || m)
  },
  {
    name: _91bookId_931qcML42IWFMeta?.name ?? "flight-book-bookId___pt",
    path: _91bookId_931qcML42IWFMeta?.path ?? "/pt/flight/book/:bookId()",
    children: [
  {
    name: addonsxGvSqqpWMJMeta?.name ?? "flight-book-bookId-addons___pt",
    path: addonsxGvSqqpWMJMeta?.path ?? "addons",
    meta: addonsxGvSqqpWMJMeta || {},
    alias: addonsxGvSqqpWMJMeta?.alias || [],
    redirect: addonsxGvSqqpWMJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/book/[bookId]/addons.vue").then(m => m.default || m)
  }
],
    meta: _91bookId_931qcML42IWFMeta || {},
    alias: _91bookId_931qcML42IWFMeta?.alias || [],
    redirect: _91bookId_931qcML42IWFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/book/[bookId].vue").then(m => m.default || m)
  },
  {
    name: _91bookId_931qcML42IWFMeta?.name ?? "flight-book-bookId___km",
    path: _91bookId_931qcML42IWFMeta?.path ?? "/km/flight/book/:bookId()",
    children: [
  {
    name: addonsxGvSqqpWMJMeta?.name ?? "flight-book-bookId-addons___km",
    path: addonsxGvSqqpWMJMeta?.path ?? "addons",
    meta: addonsxGvSqqpWMJMeta || {},
    alias: addonsxGvSqqpWMJMeta?.alias || [],
    redirect: addonsxGvSqqpWMJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/book/[bookId]/addons.vue").then(m => m.default || m)
  }
],
    meta: _91bookId_931qcML42IWFMeta || {},
    alias: _91bookId_931qcML42IWFMeta?.alias || [],
    redirect: _91bookId_931qcML42IWFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/book/[bookId].vue").then(m => m.default || m)
  },
  {
    name: confirmXVvIoONM9TMeta?.name ?? "flight-confirm___en",
    path: confirmXVvIoONM9TMeta?.path ?? "/en/flight/confirm",
    meta: confirmXVvIoONM9TMeta || {},
    alias: confirmXVvIoONM9TMeta?.alias || [],
    redirect: confirmXVvIoONM9TMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/confirm.vue").then(m => m.default || m)
  },
  {
    name: confirmXVvIoONM9TMeta?.name ?? "flight-confirm___id",
    path: confirmXVvIoONM9TMeta?.path ?? "/id/flight/confirm",
    meta: confirmXVvIoONM9TMeta || {},
    alias: confirmXVvIoONM9TMeta?.alias || [],
    redirect: confirmXVvIoONM9TMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/confirm.vue").then(m => m.default || m)
  },
  {
    name: confirmXVvIoONM9TMeta?.name ?? "flight-confirm___tl",
    path: confirmXVvIoONM9TMeta?.path ?? "/tl/flight/confirm",
    meta: confirmXVvIoONM9TMeta || {},
    alias: confirmXVvIoONM9TMeta?.alias || [],
    redirect: confirmXVvIoONM9TMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/confirm.vue").then(m => m.default || m)
  },
  {
    name: confirmXVvIoONM9TMeta?.name ?? "flight-confirm___zh",
    path: confirmXVvIoONM9TMeta?.path ?? "/zh/flight/confirm",
    meta: confirmXVvIoONM9TMeta || {},
    alias: confirmXVvIoONM9TMeta?.alias || [],
    redirect: confirmXVvIoONM9TMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/confirm.vue").then(m => m.default || m)
  },
  {
    name: confirmXVvIoONM9TMeta?.name ?? "flight-confirm___zh-tw",
    path: confirmXVvIoONM9TMeta?.path ?? "/zh-tw/flight/confirm",
    meta: confirmXVvIoONM9TMeta || {},
    alias: confirmXVvIoONM9TMeta?.alias || [],
    redirect: confirmXVvIoONM9TMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/confirm.vue").then(m => m.default || m)
  },
  {
    name: confirmXVvIoONM9TMeta?.name ?? "flight-confirm___th",
    path: confirmXVvIoONM9TMeta?.path ?? "/th/flight/confirm",
    meta: confirmXVvIoONM9TMeta || {},
    alias: confirmXVvIoONM9TMeta?.alias || [],
    redirect: confirmXVvIoONM9TMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/confirm.vue").then(m => m.default || m)
  },
  {
    name: confirmXVvIoONM9TMeta?.name ?? "flight-confirm___ja",
    path: confirmXVvIoONM9TMeta?.path ?? "/ja/flight/confirm",
    meta: confirmXVvIoONM9TMeta || {},
    alias: confirmXVvIoONM9TMeta?.alias || [],
    redirect: confirmXVvIoONM9TMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/confirm.vue").then(m => m.default || m)
  },
  {
    name: confirmXVvIoONM9TMeta?.name ?? "flight-confirm___ms",
    path: confirmXVvIoONM9TMeta?.path ?? "/ms/flight/confirm",
    meta: confirmXVvIoONM9TMeta || {},
    alias: confirmXVvIoONM9TMeta?.alias || [],
    redirect: confirmXVvIoONM9TMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/confirm.vue").then(m => m.default || m)
  },
  {
    name: confirmXVvIoONM9TMeta?.name ?? "flight-confirm___ko",
    path: confirmXVvIoONM9TMeta?.path ?? "/ko/flight/confirm",
    meta: confirmXVvIoONM9TMeta || {},
    alias: confirmXVvIoONM9TMeta?.alias || [],
    redirect: confirmXVvIoONM9TMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/confirm.vue").then(m => m.default || m)
  },
  {
    name: confirmXVvIoONM9TMeta?.name ?? "flight-confirm___vi",
    path: confirmXVvIoONM9TMeta?.path ?? "/vi/flight/confirm",
    meta: confirmXVvIoONM9TMeta || {},
    alias: confirmXVvIoONM9TMeta?.alias || [],
    redirect: confirmXVvIoONM9TMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/confirm.vue").then(m => m.default || m)
  },
  {
    name: confirmXVvIoONM9TMeta?.name ?? "flight-confirm___it",
    path: confirmXVvIoONM9TMeta?.path ?? "/it/flight/confirm",
    meta: confirmXVvIoONM9TMeta || {},
    alias: confirmXVvIoONM9TMeta?.alias || [],
    redirect: confirmXVvIoONM9TMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/confirm.vue").then(m => m.default || m)
  },
  {
    name: confirmXVvIoONM9TMeta?.name ?? "flight-confirm___de",
    path: confirmXVvIoONM9TMeta?.path ?? "/de/flight/confirm",
    meta: confirmXVvIoONM9TMeta || {},
    alias: confirmXVvIoONM9TMeta?.alias || [],
    redirect: confirmXVvIoONM9TMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/confirm.vue").then(m => m.default || m)
  },
  {
    name: confirmXVvIoONM9TMeta?.name ?? "flight-confirm___es",
    path: confirmXVvIoONM9TMeta?.path ?? "/es/flight/confirm",
    meta: confirmXVvIoONM9TMeta || {},
    alias: confirmXVvIoONM9TMeta?.alias || [],
    redirect: confirmXVvIoONM9TMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/confirm.vue").then(m => m.default || m)
  },
  {
    name: confirmXVvIoONM9TMeta?.name ?? "flight-confirm___hi",
    path: confirmXVvIoONM9TMeta?.path ?? "/hi/flight/confirm",
    meta: confirmXVvIoONM9TMeta || {},
    alias: confirmXVvIoONM9TMeta?.alias || [],
    redirect: confirmXVvIoONM9TMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/confirm.vue").then(m => m.default || m)
  },
  {
    name: confirmXVvIoONM9TMeta?.name ?? "flight-confirm___fr",
    path: confirmXVvIoONM9TMeta?.path ?? "/fr/flight/confirm",
    meta: confirmXVvIoONM9TMeta || {},
    alias: confirmXVvIoONM9TMeta?.alias || [],
    redirect: confirmXVvIoONM9TMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/confirm.vue").then(m => m.default || m)
  },
  {
    name: confirmXVvIoONM9TMeta?.name ?? "flight-confirm___ru",
    path: confirmXVvIoONM9TMeta?.path ?? "/ru/flight/confirm",
    meta: confirmXVvIoONM9TMeta || {},
    alias: confirmXVvIoONM9TMeta?.alias || [],
    redirect: confirmXVvIoONM9TMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/confirm.vue").then(m => m.default || m)
  },
  {
    name: confirmXVvIoONM9TMeta?.name ?? "flight-confirm___bn",
    path: confirmXVvIoONM9TMeta?.path ?? "/bn/flight/confirm",
    meta: confirmXVvIoONM9TMeta || {},
    alias: confirmXVvIoONM9TMeta?.alias || [],
    redirect: confirmXVvIoONM9TMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/confirm.vue").then(m => m.default || m)
  },
  {
    name: confirmXVvIoONM9TMeta?.name ?? "flight-confirm___pt",
    path: confirmXVvIoONM9TMeta?.path ?? "/pt/flight/confirm",
    meta: confirmXVvIoONM9TMeta || {},
    alias: confirmXVvIoONM9TMeta?.alias || [],
    redirect: confirmXVvIoONM9TMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/confirm.vue").then(m => m.default || m)
  },
  {
    name: confirmXVvIoONM9TMeta?.name ?? "flight-confirm___km",
    path: confirmXVvIoONM9TMeta?.path ?? "/km/flight/confirm",
    meta: confirmXVvIoONM9TMeta || {},
    alias: confirmXVvIoONM9TMeta?.alias || [],
    redirect: confirmXVvIoONM9TMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/confirm.vue").then(m => m.default || m)
  },
  {
    name: index9mIihp7HJuMeta?.name ?? "flight___en",
    path: index9mIihp7HJuMeta?.path ?? "/en/flight",
    meta: index9mIihp7HJuMeta || {},
    alias: index9mIihp7HJuMeta?.alias || [],
    redirect: index9mIihp7HJuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/index.vue").then(m => m.default || m)
  },
  {
    name: index9mIihp7HJuMeta?.name ?? "flight___id",
    path: index9mIihp7HJuMeta?.path ?? "/id/flight",
    meta: index9mIihp7HJuMeta || {},
    alias: index9mIihp7HJuMeta?.alias || [],
    redirect: index9mIihp7HJuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/index.vue").then(m => m.default || m)
  },
  {
    name: index9mIihp7HJuMeta?.name ?? "flight___tl",
    path: index9mIihp7HJuMeta?.path ?? "/tl/flight",
    meta: index9mIihp7HJuMeta || {},
    alias: index9mIihp7HJuMeta?.alias || [],
    redirect: index9mIihp7HJuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/index.vue").then(m => m.default || m)
  },
  {
    name: index9mIihp7HJuMeta?.name ?? "flight___zh",
    path: index9mIihp7HJuMeta?.path ?? "/zh/flight",
    meta: index9mIihp7HJuMeta || {},
    alias: index9mIihp7HJuMeta?.alias || [],
    redirect: index9mIihp7HJuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/index.vue").then(m => m.default || m)
  },
  {
    name: index9mIihp7HJuMeta?.name ?? "flight___zh-tw",
    path: index9mIihp7HJuMeta?.path ?? "/zh-tw/flight",
    meta: index9mIihp7HJuMeta || {},
    alias: index9mIihp7HJuMeta?.alias || [],
    redirect: index9mIihp7HJuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/index.vue").then(m => m.default || m)
  },
  {
    name: index9mIihp7HJuMeta?.name ?? "flight___th",
    path: index9mIihp7HJuMeta?.path ?? "/th/flight",
    meta: index9mIihp7HJuMeta || {},
    alias: index9mIihp7HJuMeta?.alias || [],
    redirect: index9mIihp7HJuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/index.vue").then(m => m.default || m)
  },
  {
    name: index9mIihp7HJuMeta?.name ?? "flight___ja",
    path: index9mIihp7HJuMeta?.path ?? "/ja/flight",
    meta: index9mIihp7HJuMeta || {},
    alias: index9mIihp7HJuMeta?.alias || [],
    redirect: index9mIihp7HJuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/index.vue").then(m => m.default || m)
  },
  {
    name: index9mIihp7HJuMeta?.name ?? "flight___ms",
    path: index9mIihp7HJuMeta?.path ?? "/ms/flight",
    meta: index9mIihp7HJuMeta || {},
    alias: index9mIihp7HJuMeta?.alias || [],
    redirect: index9mIihp7HJuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/index.vue").then(m => m.default || m)
  },
  {
    name: index9mIihp7HJuMeta?.name ?? "flight___ko",
    path: index9mIihp7HJuMeta?.path ?? "/ko/flight",
    meta: index9mIihp7HJuMeta || {},
    alias: index9mIihp7HJuMeta?.alias || [],
    redirect: index9mIihp7HJuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/index.vue").then(m => m.default || m)
  },
  {
    name: index9mIihp7HJuMeta?.name ?? "flight___vi",
    path: index9mIihp7HJuMeta?.path ?? "/vi/flight",
    meta: index9mIihp7HJuMeta || {},
    alias: index9mIihp7HJuMeta?.alias || [],
    redirect: index9mIihp7HJuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/index.vue").then(m => m.default || m)
  },
  {
    name: index9mIihp7HJuMeta?.name ?? "flight___it",
    path: index9mIihp7HJuMeta?.path ?? "/it/flight",
    meta: index9mIihp7HJuMeta || {},
    alias: index9mIihp7HJuMeta?.alias || [],
    redirect: index9mIihp7HJuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/index.vue").then(m => m.default || m)
  },
  {
    name: index9mIihp7HJuMeta?.name ?? "flight___de",
    path: index9mIihp7HJuMeta?.path ?? "/de/flight",
    meta: index9mIihp7HJuMeta || {},
    alias: index9mIihp7HJuMeta?.alias || [],
    redirect: index9mIihp7HJuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/index.vue").then(m => m.default || m)
  },
  {
    name: index9mIihp7HJuMeta?.name ?? "flight___es",
    path: index9mIihp7HJuMeta?.path ?? "/es/flight",
    meta: index9mIihp7HJuMeta || {},
    alias: index9mIihp7HJuMeta?.alias || [],
    redirect: index9mIihp7HJuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/index.vue").then(m => m.default || m)
  },
  {
    name: index9mIihp7HJuMeta?.name ?? "flight___hi",
    path: index9mIihp7HJuMeta?.path ?? "/hi/flight",
    meta: index9mIihp7HJuMeta || {},
    alias: index9mIihp7HJuMeta?.alias || [],
    redirect: index9mIihp7HJuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/index.vue").then(m => m.default || m)
  },
  {
    name: index9mIihp7HJuMeta?.name ?? "flight___fr",
    path: index9mIihp7HJuMeta?.path ?? "/fr/flight",
    meta: index9mIihp7HJuMeta || {},
    alias: index9mIihp7HJuMeta?.alias || [],
    redirect: index9mIihp7HJuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/index.vue").then(m => m.default || m)
  },
  {
    name: index9mIihp7HJuMeta?.name ?? "flight___ru",
    path: index9mIihp7HJuMeta?.path ?? "/ru/flight",
    meta: index9mIihp7HJuMeta || {},
    alias: index9mIihp7HJuMeta?.alias || [],
    redirect: index9mIihp7HJuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/index.vue").then(m => m.default || m)
  },
  {
    name: index9mIihp7HJuMeta?.name ?? "flight___bn",
    path: index9mIihp7HJuMeta?.path ?? "/bn/flight",
    meta: index9mIihp7HJuMeta || {},
    alias: index9mIihp7HJuMeta?.alias || [],
    redirect: index9mIihp7HJuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/index.vue").then(m => m.default || m)
  },
  {
    name: index9mIihp7HJuMeta?.name ?? "flight___pt",
    path: index9mIihp7HJuMeta?.path ?? "/pt/flight",
    meta: index9mIihp7HJuMeta || {},
    alias: index9mIihp7HJuMeta?.alias || [],
    redirect: index9mIihp7HJuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/index.vue").then(m => m.default || m)
  },
  {
    name: index9mIihp7HJuMeta?.name ?? "flight___km",
    path: index9mIihp7HJuMeta?.path ?? "/km/flight",
    meta: index9mIihp7HJuMeta || {},
    alias: index9mIihp7HJuMeta?.alias || [],
    redirect: index9mIihp7HJuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/index.vue").then(m => m.default || m)
  },
  {
    name: searchAWx19CheQIMeta?.name ?? "flight-search___en",
    path: searchAWx19CheQIMeta?.path ?? "/en/flight/search",
    meta: searchAWx19CheQIMeta || {},
    alias: searchAWx19CheQIMeta?.alias || [],
    redirect: searchAWx19CheQIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/search.vue").then(m => m.default || m)
  },
  {
    name: searchAWx19CheQIMeta?.name ?? "flight-search___id",
    path: searchAWx19CheQIMeta?.path ?? "/id/flight/search",
    meta: searchAWx19CheQIMeta || {},
    alias: searchAWx19CheQIMeta?.alias || [],
    redirect: searchAWx19CheQIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/search.vue").then(m => m.default || m)
  },
  {
    name: searchAWx19CheQIMeta?.name ?? "flight-search___tl",
    path: searchAWx19CheQIMeta?.path ?? "/tl/flight/search",
    meta: searchAWx19CheQIMeta || {},
    alias: searchAWx19CheQIMeta?.alias || [],
    redirect: searchAWx19CheQIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/search.vue").then(m => m.default || m)
  },
  {
    name: searchAWx19CheQIMeta?.name ?? "flight-search___zh",
    path: searchAWx19CheQIMeta?.path ?? "/zh/flight/search",
    meta: searchAWx19CheQIMeta || {},
    alias: searchAWx19CheQIMeta?.alias || [],
    redirect: searchAWx19CheQIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/search.vue").then(m => m.default || m)
  },
  {
    name: searchAWx19CheQIMeta?.name ?? "flight-search___zh-tw",
    path: searchAWx19CheQIMeta?.path ?? "/zh-tw/flight/search",
    meta: searchAWx19CheQIMeta || {},
    alias: searchAWx19CheQIMeta?.alias || [],
    redirect: searchAWx19CheQIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/search.vue").then(m => m.default || m)
  },
  {
    name: searchAWx19CheQIMeta?.name ?? "flight-search___th",
    path: searchAWx19CheQIMeta?.path ?? "/th/flight/search",
    meta: searchAWx19CheQIMeta || {},
    alias: searchAWx19CheQIMeta?.alias || [],
    redirect: searchAWx19CheQIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/search.vue").then(m => m.default || m)
  },
  {
    name: searchAWx19CheQIMeta?.name ?? "flight-search___ja",
    path: searchAWx19CheQIMeta?.path ?? "/ja/flight/search",
    meta: searchAWx19CheQIMeta || {},
    alias: searchAWx19CheQIMeta?.alias || [],
    redirect: searchAWx19CheQIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/search.vue").then(m => m.default || m)
  },
  {
    name: searchAWx19CheQIMeta?.name ?? "flight-search___ms",
    path: searchAWx19CheQIMeta?.path ?? "/ms/flight/search",
    meta: searchAWx19CheQIMeta || {},
    alias: searchAWx19CheQIMeta?.alias || [],
    redirect: searchAWx19CheQIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/search.vue").then(m => m.default || m)
  },
  {
    name: searchAWx19CheQIMeta?.name ?? "flight-search___ko",
    path: searchAWx19CheQIMeta?.path ?? "/ko/flight/search",
    meta: searchAWx19CheQIMeta || {},
    alias: searchAWx19CheQIMeta?.alias || [],
    redirect: searchAWx19CheQIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/search.vue").then(m => m.default || m)
  },
  {
    name: searchAWx19CheQIMeta?.name ?? "flight-search___vi",
    path: searchAWx19CheQIMeta?.path ?? "/vi/flight/search",
    meta: searchAWx19CheQIMeta || {},
    alias: searchAWx19CheQIMeta?.alias || [],
    redirect: searchAWx19CheQIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/search.vue").then(m => m.default || m)
  },
  {
    name: searchAWx19CheQIMeta?.name ?? "flight-search___it",
    path: searchAWx19CheQIMeta?.path ?? "/it/flight/search",
    meta: searchAWx19CheQIMeta || {},
    alias: searchAWx19CheQIMeta?.alias || [],
    redirect: searchAWx19CheQIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/search.vue").then(m => m.default || m)
  },
  {
    name: searchAWx19CheQIMeta?.name ?? "flight-search___de",
    path: searchAWx19CheQIMeta?.path ?? "/de/flight/search",
    meta: searchAWx19CheQIMeta || {},
    alias: searchAWx19CheQIMeta?.alias || [],
    redirect: searchAWx19CheQIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/search.vue").then(m => m.default || m)
  },
  {
    name: searchAWx19CheQIMeta?.name ?? "flight-search___es",
    path: searchAWx19CheQIMeta?.path ?? "/es/flight/search",
    meta: searchAWx19CheQIMeta || {},
    alias: searchAWx19CheQIMeta?.alias || [],
    redirect: searchAWx19CheQIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/search.vue").then(m => m.default || m)
  },
  {
    name: searchAWx19CheQIMeta?.name ?? "flight-search___hi",
    path: searchAWx19CheQIMeta?.path ?? "/hi/flight/search",
    meta: searchAWx19CheQIMeta || {},
    alias: searchAWx19CheQIMeta?.alias || [],
    redirect: searchAWx19CheQIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/search.vue").then(m => m.default || m)
  },
  {
    name: searchAWx19CheQIMeta?.name ?? "flight-search___fr",
    path: searchAWx19CheQIMeta?.path ?? "/fr/flight/search",
    meta: searchAWx19CheQIMeta || {},
    alias: searchAWx19CheQIMeta?.alias || [],
    redirect: searchAWx19CheQIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/search.vue").then(m => m.default || m)
  },
  {
    name: searchAWx19CheQIMeta?.name ?? "flight-search___ru",
    path: searchAWx19CheQIMeta?.path ?? "/ru/flight/search",
    meta: searchAWx19CheQIMeta || {},
    alias: searchAWx19CheQIMeta?.alias || [],
    redirect: searchAWx19CheQIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/search.vue").then(m => m.default || m)
  },
  {
    name: searchAWx19CheQIMeta?.name ?? "flight-search___bn",
    path: searchAWx19CheQIMeta?.path ?? "/bn/flight/search",
    meta: searchAWx19CheQIMeta || {},
    alias: searchAWx19CheQIMeta?.alias || [],
    redirect: searchAWx19CheQIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/search.vue").then(m => m.default || m)
  },
  {
    name: searchAWx19CheQIMeta?.name ?? "flight-search___pt",
    path: searchAWx19CheQIMeta?.path ?? "/pt/flight/search",
    meta: searchAWx19CheQIMeta || {},
    alias: searchAWx19CheQIMeta?.alias || [],
    redirect: searchAWx19CheQIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/search.vue").then(m => m.default || m)
  },
  {
    name: searchAWx19CheQIMeta?.name ?? "flight-search___km",
    path: searchAWx19CheQIMeta?.path ?? "/km/flight/search",
    meta: searchAWx19CheQIMeta || {},
    alias: searchAWx19CheQIMeta?.alias || [],
    redirect: searchAWx19CheQIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/flight/search.vue").then(m => m.default || m)
  },
  {
    name: indexHhCswK9muAMeta?.name ?? "hotel___en",
    path: indexHhCswK9muAMeta?.path ?? "/en/hotel",
    meta: indexHhCswK9muAMeta || {},
    alias: indexHhCswK9muAMeta?.alias || [],
    redirect: indexHhCswK9muAMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/hotel/index.vue").then(m => m.default || m)
  },
  {
    name: indexHhCswK9muAMeta?.name ?? "hotel___id",
    path: indexHhCswK9muAMeta?.path ?? "/id/hotel",
    meta: indexHhCswK9muAMeta || {},
    alias: indexHhCswK9muAMeta?.alias || [],
    redirect: indexHhCswK9muAMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/hotel/index.vue").then(m => m.default || m)
  },
  {
    name: indexHhCswK9muAMeta?.name ?? "hotel___tl",
    path: indexHhCswK9muAMeta?.path ?? "/tl/hotel",
    meta: indexHhCswK9muAMeta || {},
    alias: indexHhCswK9muAMeta?.alias || [],
    redirect: indexHhCswK9muAMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/hotel/index.vue").then(m => m.default || m)
  },
  {
    name: indexHhCswK9muAMeta?.name ?? "hotel___zh",
    path: indexHhCswK9muAMeta?.path ?? "/zh/hotel",
    meta: indexHhCswK9muAMeta || {},
    alias: indexHhCswK9muAMeta?.alias || [],
    redirect: indexHhCswK9muAMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/hotel/index.vue").then(m => m.default || m)
  },
  {
    name: indexHhCswK9muAMeta?.name ?? "hotel___zh-tw",
    path: indexHhCswK9muAMeta?.path ?? "/zh-tw/hotel",
    meta: indexHhCswK9muAMeta || {},
    alias: indexHhCswK9muAMeta?.alias || [],
    redirect: indexHhCswK9muAMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/hotel/index.vue").then(m => m.default || m)
  },
  {
    name: indexHhCswK9muAMeta?.name ?? "hotel___th",
    path: indexHhCswK9muAMeta?.path ?? "/th/hotel",
    meta: indexHhCswK9muAMeta || {},
    alias: indexHhCswK9muAMeta?.alias || [],
    redirect: indexHhCswK9muAMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/hotel/index.vue").then(m => m.default || m)
  },
  {
    name: indexHhCswK9muAMeta?.name ?? "hotel___ja",
    path: indexHhCswK9muAMeta?.path ?? "/ja/hotel",
    meta: indexHhCswK9muAMeta || {},
    alias: indexHhCswK9muAMeta?.alias || [],
    redirect: indexHhCswK9muAMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/hotel/index.vue").then(m => m.default || m)
  },
  {
    name: indexHhCswK9muAMeta?.name ?? "hotel___ms",
    path: indexHhCswK9muAMeta?.path ?? "/ms/hotel",
    meta: indexHhCswK9muAMeta || {},
    alias: indexHhCswK9muAMeta?.alias || [],
    redirect: indexHhCswK9muAMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/hotel/index.vue").then(m => m.default || m)
  },
  {
    name: indexHhCswK9muAMeta?.name ?? "hotel___ko",
    path: indexHhCswK9muAMeta?.path ?? "/ko/hotel",
    meta: indexHhCswK9muAMeta || {},
    alias: indexHhCswK9muAMeta?.alias || [],
    redirect: indexHhCswK9muAMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/hotel/index.vue").then(m => m.default || m)
  },
  {
    name: indexHhCswK9muAMeta?.name ?? "hotel___vi",
    path: indexHhCswK9muAMeta?.path ?? "/vi/hotel",
    meta: indexHhCswK9muAMeta || {},
    alias: indexHhCswK9muAMeta?.alias || [],
    redirect: indexHhCswK9muAMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/hotel/index.vue").then(m => m.default || m)
  },
  {
    name: indexHhCswK9muAMeta?.name ?? "hotel___it",
    path: indexHhCswK9muAMeta?.path ?? "/it/hotel",
    meta: indexHhCswK9muAMeta || {},
    alias: indexHhCswK9muAMeta?.alias || [],
    redirect: indexHhCswK9muAMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/hotel/index.vue").then(m => m.default || m)
  },
  {
    name: indexHhCswK9muAMeta?.name ?? "hotel___de",
    path: indexHhCswK9muAMeta?.path ?? "/de/hotel",
    meta: indexHhCswK9muAMeta || {},
    alias: indexHhCswK9muAMeta?.alias || [],
    redirect: indexHhCswK9muAMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/hotel/index.vue").then(m => m.default || m)
  },
  {
    name: indexHhCswK9muAMeta?.name ?? "hotel___es",
    path: indexHhCswK9muAMeta?.path ?? "/es/hotel",
    meta: indexHhCswK9muAMeta || {},
    alias: indexHhCswK9muAMeta?.alias || [],
    redirect: indexHhCswK9muAMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/hotel/index.vue").then(m => m.default || m)
  },
  {
    name: indexHhCswK9muAMeta?.name ?? "hotel___hi",
    path: indexHhCswK9muAMeta?.path ?? "/hi/hotel",
    meta: indexHhCswK9muAMeta || {},
    alias: indexHhCswK9muAMeta?.alias || [],
    redirect: indexHhCswK9muAMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/hotel/index.vue").then(m => m.default || m)
  },
  {
    name: indexHhCswK9muAMeta?.name ?? "hotel___fr",
    path: indexHhCswK9muAMeta?.path ?? "/fr/hotel",
    meta: indexHhCswK9muAMeta || {},
    alias: indexHhCswK9muAMeta?.alias || [],
    redirect: indexHhCswK9muAMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/hotel/index.vue").then(m => m.default || m)
  },
  {
    name: indexHhCswK9muAMeta?.name ?? "hotel___ru",
    path: indexHhCswK9muAMeta?.path ?? "/ru/hotel",
    meta: indexHhCswK9muAMeta || {},
    alias: indexHhCswK9muAMeta?.alias || [],
    redirect: indexHhCswK9muAMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/hotel/index.vue").then(m => m.default || m)
  },
  {
    name: indexHhCswK9muAMeta?.name ?? "hotel___bn",
    path: indexHhCswK9muAMeta?.path ?? "/bn/hotel",
    meta: indexHhCswK9muAMeta || {},
    alias: indexHhCswK9muAMeta?.alias || [],
    redirect: indexHhCswK9muAMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/hotel/index.vue").then(m => m.default || m)
  },
  {
    name: indexHhCswK9muAMeta?.name ?? "hotel___pt",
    path: indexHhCswK9muAMeta?.path ?? "/pt/hotel",
    meta: indexHhCswK9muAMeta || {},
    alias: indexHhCswK9muAMeta?.alias || [],
    redirect: indexHhCswK9muAMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/hotel/index.vue").then(m => m.default || m)
  },
  {
    name: indexHhCswK9muAMeta?.name ?? "hotel___km",
    path: indexHhCswK9muAMeta?.path ?? "/km/hotel",
    meta: indexHhCswK9muAMeta || {},
    alias: indexHhCswK9muAMeta?.alias || [],
    redirect: indexHhCswK9muAMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/hotel/index.vue").then(m => m.default || m)
  },
  {
    name: searchkKqQkgrNV4Meta?.name ?? "hotel-search___en",
    path: searchkKqQkgrNV4Meta?.path ?? "/en/hotel/search",
    meta: searchkKqQkgrNV4Meta || {},
    alias: searchkKqQkgrNV4Meta?.alias || [],
    redirect: searchkKqQkgrNV4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/hotel/search.vue").then(m => m.default || m)
  },
  {
    name: searchkKqQkgrNV4Meta?.name ?? "hotel-search___id",
    path: searchkKqQkgrNV4Meta?.path ?? "/id/hotel/search",
    meta: searchkKqQkgrNV4Meta || {},
    alias: searchkKqQkgrNV4Meta?.alias || [],
    redirect: searchkKqQkgrNV4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/hotel/search.vue").then(m => m.default || m)
  },
  {
    name: searchkKqQkgrNV4Meta?.name ?? "hotel-search___tl",
    path: searchkKqQkgrNV4Meta?.path ?? "/tl/hotel/search",
    meta: searchkKqQkgrNV4Meta || {},
    alias: searchkKqQkgrNV4Meta?.alias || [],
    redirect: searchkKqQkgrNV4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/hotel/search.vue").then(m => m.default || m)
  },
  {
    name: searchkKqQkgrNV4Meta?.name ?? "hotel-search___zh",
    path: searchkKqQkgrNV4Meta?.path ?? "/zh/hotel/search",
    meta: searchkKqQkgrNV4Meta || {},
    alias: searchkKqQkgrNV4Meta?.alias || [],
    redirect: searchkKqQkgrNV4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/hotel/search.vue").then(m => m.default || m)
  },
  {
    name: searchkKqQkgrNV4Meta?.name ?? "hotel-search___zh-tw",
    path: searchkKqQkgrNV4Meta?.path ?? "/zh-tw/hotel/search",
    meta: searchkKqQkgrNV4Meta || {},
    alias: searchkKqQkgrNV4Meta?.alias || [],
    redirect: searchkKqQkgrNV4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/hotel/search.vue").then(m => m.default || m)
  },
  {
    name: searchkKqQkgrNV4Meta?.name ?? "hotel-search___th",
    path: searchkKqQkgrNV4Meta?.path ?? "/th/hotel/search",
    meta: searchkKqQkgrNV4Meta || {},
    alias: searchkKqQkgrNV4Meta?.alias || [],
    redirect: searchkKqQkgrNV4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/hotel/search.vue").then(m => m.default || m)
  },
  {
    name: searchkKqQkgrNV4Meta?.name ?? "hotel-search___ja",
    path: searchkKqQkgrNV4Meta?.path ?? "/ja/hotel/search",
    meta: searchkKqQkgrNV4Meta || {},
    alias: searchkKqQkgrNV4Meta?.alias || [],
    redirect: searchkKqQkgrNV4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/hotel/search.vue").then(m => m.default || m)
  },
  {
    name: searchkKqQkgrNV4Meta?.name ?? "hotel-search___ms",
    path: searchkKqQkgrNV4Meta?.path ?? "/ms/hotel/search",
    meta: searchkKqQkgrNV4Meta || {},
    alias: searchkKqQkgrNV4Meta?.alias || [],
    redirect: searchkKqQkgrNV4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/hotel/search.vue").then(m => m.default || m)
  },
  {
    name: searchkKqQkgrNV4Meta?.name ?? "hotel-search___ko",
    path: searchkKqQkgrNV4Meta?.path ?? "/ko/hotel/search",
    meta: searchkKqQkgrNV4Meta || {},
    alias: searchkKqQkgrNV4Meta?.alias || [],
    redirect: searchkKqQkgrNV4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/hotel/search.vue").then(m => m.default || m)
  },
  {
    name: searchkKqQkgrNV4Meta?.name ?? "hotel-search___vi",
    path: searchkKqQkgrNV4Meta?.path ?? "/vi/hotel/search",
    meta: searchkKqQkgrNV4Meta || {},
    alias: searchkKqQkgrNV4Meta?.alias || [],
    redirect: searchkKqQkgrNV4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/hotel/search.vue").then(m => m.default || m)
  },
  {
    name: searchkKqQkgrNV4Meta?.name ?? "hotel-search___it",
    path: searchkKqQkgrNV4Meta?.path ?? "/it/hotel/search",
    meta: searchkKqQkgrNV4Meta || {},
    alias: searchkKqQkgrNV4Meta?.alias || [],
    redirect: searchkKqQkgrNV4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/hotel/search.vue").then(m => m.default || m)
  },
  {
    name: searchkKqQkgrNV4Meta?.name ?? "hotel-search___de",
    path: searchkKqQkgrNV4Meta?.path ?? "/de/hotel/search",
    meta: searchkKqQkgrNV4Meta || {},
    alias: searchkKqQkgrNV4Meta?.alias || [],
    redirect: searchkKqQkgrNV4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/hotel/search.vue").then(m => m.default || m)
  },
  {
    name: searchkKqQkgrNV4Meta?.name ?? "hotel-search___es",
    path: searchkKqQkgrNV4Meta?.path ?? "/es/hotel/search",
    meta: searchkKqQkgrNV4Meta || {},
    alias: searchkKqQkgrNV4Meta?.alias || [],
    redirect: searchkKqQkgrNV4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/hotel/search.vue").then(m => m.default || m)
  },
  {
    name: searchkKqQkgrNV4Meta?.name ?? "hotel-search___hi",
    path: searchkKqQkgrNV4Meta?.path ?? "/hi/hotel/search",
    meta: searchkKqQkgrNV4Meta || {},
    alias: searchkKqQkgrNV4Meta?.alias || [],
    redirect: searchkKqQkgrNV4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/hotel/search.vue").then(m => m.default || m)
  },
  {
    name: searchkKqQkgrNV4Meta?.name ?? "hotel-search___fr",
    path: searchkKqQkgrNV4Meta?.path ?? "/fr/hotel/search",
    meta: searchkKqQkgrNV4Meta || {},
    alias: searchkKqQkgrNV4Meta?.alias || [],
    redirect: searchkKqQkgrNV4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/hotel/search.vue").then(m => m.default || m)
  },
  {
    name: searchkKqQkgrNV4Meta?.name ?? "hotel-search___ru",
    path: searchkKqQkgrNV4Meta?.path ?? "/ru/hotel/search",
    meta: searchkKqQkgrNV4Meta || {},
    alias: searchkKqQkgrNV4Meta?.alias || [],
    redirect: searchkKqQkgrNV4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/hotel/search.vue").then(m => m.default || m)
  },
  {
    name: searchkKqQkgrNV4Meta?.name ?? "hotel-search___bn",
    path: searchkKqQkgrNV4Meta?.path ?? "/bn/hotel/search",
    meta: searchkKqQkgrNV4Meta || {},
    alias: searchkKqQkgrNV4Meta?.alias || [],
    redirect: searchkKqQkgrNV4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/hotel/search.vue").then(m => m.default || m)
  },
  {
    name: searchkKqQkgrNV4Meta?.name ?? "hotel-search___pt",
    path: searchkKqQkgrNV4Meta?.path ?? "/pt/hotel/search",
    meta: searchkKqQkgrNV4Meta || {},
    alias: searchkKqQkgrNV4Meta?.alias || [],
    redirect: searchkKqQkgrNV4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/hotel/search.vue").then(m => m.default || m)
  },
  {
    name: searchkKqQkgrNV4Meta?.name ?? "hotel-search___km",
    path: searchkKqQkgrNV4Meta?.path ?? "/km/hotel/search",
    meta: searchkKqQkgrNV4Meta || {},
    alias: searchkKqQkgrNV4Meta?.alias || [],
    redirect: searchkKqQkgrNV4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/hotel/search.vue").then(m => m.default || m)
  },
  {
    name: indexSRNzu9hqO1Meta?.name ?? "index___en",
    path: indexSRNzu9hqO1Meta?.path ?? "/en",
    meta: indexSRNzu9hqO1Meta || {},
    alias: indexSRNzu9hqO1Meta?.alias || [],
    redirect: indexSRNzu9hqO1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexSRNzu9hqO1Meta?.name ?? "index___id",
    path: indexSRNzu9hqO1Meta?.path ?? "/id",
    meta: indexSRNzu9hqO1Meta || {},
    alias: indexSRNzu9hqO1Meta?.alias || [],
    redirect: indexSRNzu9hqO1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexSRNzu9hqO1Meta?.name ?? "index___tl",
    path: indexSRNzu9hqO1Meta?.path ?? "/tl",
    meta: indexSRNzu9hqO1Meta || {},
    alias: indexSRNzu9hqO1Meta?.alias || [],
    redirect: indexSRNzu9hqO1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexSRNzu9hqO1Meta?.name ?? "index___zh",
    path: indexSRNzu9hqO1Meta?.path ?? "/zh",
    meta: indexSRNzu9hqO1Meta || {},
    alias: indexSRNzu9hqO1Meta?.alias || [],
    redirect: indexSRNzu9hqO1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexSRNzu9hqO1Meta?.name ?? "index___zh-tw",
    path: indexSRNzu9hqO1Meta?.path ?? "/zh-tw",
    meta: indexSRNzu9hqO1Meta || {},
    alias: indexSRNzu9hqO1Meta?.alias || [],
    redirect: indexSRNzu9hqO1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexSRNzu9hqO1Meta?.name ?? "index___th",
    path: indexSRNzu9hqO1Meta?.path ?? "/th",
    meta: indexSRNzu9hqO1Meta || {},
    alias: indexSRNzu9hqO1Meta?.alias || [],
    redirect: indexSRNzu9hqO1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexSRNzu9hqO1Meta?.name ?? "index___ja",
    path: indexSRNzu9hqO1Meta?.path ?? "/ja",
    meta: indexSRNzu9hqO1Meta || {},
    alias: indexSRNzu9hqO1Meta?.alias || [],
    redirect: indexSRNzu9hqO1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexSRNzu9hqO1Meta?.name ?? "index___ms",
    path: indexSRNzu9hqO1Meta?.path ?? "/ms",
    meta: indexSRNzu9hqO1Meta || {},
    alias: indexSRNzu9hqO1Meta?.alias || [],
    redirect: indexSRNzu9hqO1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexSRNzu9hqO1Meta?.name ?? "index___ko",
    path: indexSRNzu9hqO1Meta?.path ?? "/ko",
    meta: indexSRNzu9hqO1Meta || {},
    alias: indexSRNzu9hqO1Meta?.alias || [],
    redirect: indexSRNzu9hqO1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexSRNzu9hqO1Meta?.name ?? "index___vi",
    path: indexSRNzu9hqO1Meta?.path ?? "/vi",
    meta: indexSRNzu9hqO1Meta || {},
    alias: indexSRNzu9hqO1Meta?.alias || [],
    redirect: indexSRNzu9hqO1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexSRNzu9hqO1Meta?.name ?? "index___it",
    path: indexSRNzu9hqO1Meta?.path ?? "/it",
    meta: indexSRNzu9hqO1Meta || {},
    alias: indexSRNzu9hqO1Meta?.alias || [],
    redirect: indexSRNzu9hqO1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexSRNzu9hqO1Meta?.name ?? "index___de",
    path: indexSRNzu9hqO1Meta?.path ?? "/de",
    meta: indexSRNzu9hqO1Meta || {},
    alias: indexSRNzu9hqO1Meta?.alias || [],
    redirect: indexSRNzu9hqO1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexSRNzu9hqO1Meta?.name ?? "index___es",
    path: indexSRNzu9hqO1Meta?.path ?? "/es",
    meta: indexSRNzu9hqO1Meta || {},
    alias: indexSRNzu9hqO1Meta?.alias || [],
    redirect: indexSRNzu9hqO1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexSRNzu9hqO1Meta?.name ?? "index___hi",
    path: indexSRNzu9hqO1Meta?.path ?? "/hi",
    meta: indexSRNzu9hqO1Meta || {},
    alias: indexSRNzu9hqO1Meta?.alias || [],
    redirect: indexSRNzu9hqO1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexSRNzu9hqO1Meta?.name ?? "index___fr",
    path: indexSRNzu9hqO1Meta?.path ?? "/fr",
    meta: indexSRNzu9hqO1Meta || {},
    alias: indexSRNzu9hqO1Meta?.alias || [],
    redirect: indexSRNzu9hqO1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexSRNzu9hqO1Meta?.name ?? "index___ru",
    path: indexSRNzu9hqO1Meta?.path ?? "/ru",
    meta: indexSRNzu9hqO1Meta || {},
    alias: indexSRNzu9hqO1Meta?.alias || [],
    redirect: indexSRNzu9hqO1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexSRNzu9hqO1Meta?.name ?? "index___bn",
    path: indexSRNzu9hqO1Meta?.path ?? "/bn",
    meta: indexSRNzu9hqO1Meta || {},
    alias: indexSRNzu9hqO1Meta?.alias || [],
    redirect: indexSRNzu9hqO1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexSRNzu9hqO1Meta?.name ?? "index___pt",
    path: indexSRNzu9hqO1Meta?.path ?? "/pt",
    meta: indexSRNzu9hqO1Meta || {},
    alias: indexSRNzu9hqO1Meta?.alias || [],
    redirect: indexSRNzu9hqO1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexSRNzu9hqO1Meta?.name ?? "index___km",
    path: indexSRNzu9hqO1Meta?.path ?? "/km",
    meta: indexSRNzu9hqO1Meta || {},
    alias: indexSRNzu9hqO1Meta?.alias || [],
    redirect: indexSRNzu9hqO1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginzoZaqp9bwhMeta?.name ?? "login___en",
    path: loginzoZaqp9bwhMeta?.path ?? "/en/login",
    meta: loginzoZaqp9bwhMeta || {},
    alias: loginzoZaqp9bwhMeta?.alias || [],
    redirect: loginzoZaqp9bwhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginzoZaqp9bwhMeta?.name ?? "login___id",
    path: loginzoZaqp9bwhMeta?.path ?? "/id/login",
    meta: loginzoZaqp9bwhMeta || {},
    alias: loginzoZaqp9bwhMeta?.alias || [],
    redirect: loginzoZaqp9bwhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginzoZaqp9bwhMeta?.name ?? "login___tl",
    path: loginzoZaqp9bwhMeta?.path ?? "/tl/login",
    meta: loginzoZaqp9bwhMeta || {},
    alias: loginzoZaqp9bwhMeta?.alias || [],
    redirect: loginzoZaqp9bwhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginzoZaqp9bwhMeta?.name ?? "login___zh",
    path: loginzoZaqp9bwhMeta?.path ?? "/zh/login",
    meta: loginzoZaqp9bwhMeta || {},
    alias: loginzoZaqp9bwhMeta?.alias || [],
    redirect: loginzoZaqp9bwhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginzoZaqp9bwhMeta?.name ?? "login___zh-tw",
    path: loginzoZaqp9bwhMeta?.path ?? "/zh-tw/login",
    meta: loginzoZaqp9bwhMeta || {},
    alias: loginzoZaqp9bwhMeta?.alias || [],
    redirect: loginzoZaqp9bwhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginzoZaqp9bwhMeta?.name ?? "login___th",
    path: loginzoZaqp9bwhMeta?.path ?? "/th/login",
    meta: loginzoZaqp9bwhMeta || {},
    alias: loginzoZaqp9bwhMeta?.alias || [],
    redirect: loginzoZaqp9bwhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginzoZaqp9bwhMeta?.name ?? "login___ja",
    path: loginzoZaqp9bwhMeta?.path ?? "/ja/login",
    meta: loginzoZaqp9bwhMeta || {},
    alias: loginzoZaqp9bwhMeta?.alias || [],
    redirect: loginzoZaqp9bwhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginzoZaqp9bwhMeta?.name ?? "login___ms",
    path: loginzoZaqp9bwhMeta?.path ?? "/ms/login",
    meta: loginzoZaqp9bwhMeta || {},
    alias: loginzoZaqp9bwhMeta?.alias || [],
    redirect: loginzoZaqp9bwhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginzoZaqp9bwhMeta?.name ?? "login___ko",
    path: loginzoZaqp9bwhMeta?.path ?? "/ko/login",
    meta: loginzoZaqp9bwhMeta || {},
    alias: loginzoZaqp9bwhMeta?.alias || [],
    redirect: loginzoZaqp9bwhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginzoZaqp9bwhMeta?.name ?? "login___vi",
    path: loginzoZaqp9bwhMeta?.path ?? "/vi/login",
    meta: loginzoZaqp9bwhMeta || {},
    alias: loginzoZaqp9bwhMeta?.alias || [],
    redirect: loginzoZaqp9bwhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginzoZaqp9bwhMeta?.name ?? "login___it",
    path: loginzoZaqp9bwhMeta?.path ?? "/it/login",
    meta: loginzoZaqp9bwhMeta || {},
    alias: loginzoZaqp9bwhMeta?.alias || [],
    redirect: loginzoZaqp9bwhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginzoZaqp9bwhMeta?.name ?? "login___de",
    path: loginzoZaqp9bwhMeta?.path ?? "/de/login",
    meta: loginzoZaqp9bwhMeta || {},
    alias: loginzoZaqp9bwhMeta?.alias || [],
    redirect: loginzoZaqp9bwhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginzoZaqp9bwhMeta?.name ?? "login___es",
    path: loginzoZaqp9bwhMeta?.path ?? "/es/login",
    meta: loginzoZaqp9bwhMeta || {},
    alias: loginzoZaqp9bwhMeta?.alias || [],
    redirect: loginzoZaqp9bwhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginzoZaqp9bwhMeta?.name ?? "login___hi",
    path: loginzoZaqp9bwhMeta?.path ?? "/hi/login",
    meta: loginzoZaqp9bwhMeta || {},
    alias: loginzoZaqp9bwhMeta?.alias || [],
    redirect: loginzoZaqp9bwhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginzoZaqp9bwhMeta?.name ?? "login___fr",
    path: loginzoZaqp9bwhMeta?.path ?? "/fr/login",
    meta: loginzoZaqp9bwhMeta || {},
    alias: loginzoZaqp9bwhMeta?.alias || [],
    redirect: loginzoZaqp9bwhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginzoZaqp9bwhMeta?.name ?? "login___ru",
    path: loginzoZaqp9bwhMeta?.path ?? "/ru/login",
    meta: loginzoZaqp9bwhMeta || {},
    alias: loginzoZaqp9bwhMeta?.alias || [],
    redirect: loginzoZaqp9bwhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginzoZaqp9bwhMeta?.name ?? "login___bn",
    path: loginzoZaqp9bwhMeta?.path ?? "/bn/login",
    meta: loginzoZaqp9bwhMeta || {},
    alias: loginzoZaqp9bwhMeta?.alias || [],
    redirect: loginzoZaqp9bwhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginzoZaqp9bwhMeta?.name ?? "login___pt",
    path: loginzoZaqp9bwhMeta?.path ?? "/pt/login",
    meta: loginzoZaqp9bwhMeta || {},
    alias: loginzoZaqp9bwhMeta?.alias || [],
    redirect: loginzoZaqp9bwhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginzoZaqp9bwhMeta?.name ?? "login___km",
    path: loginzoZaqp9bwhMeta?.path ?? "/km/login",
    meta: loginzoZaqp9bwhMeta || {},
    alias: loginzoZaqp9bwhMeta?.alias || [],
    redirect: loginzoZaqp9bwhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/login.vue").then(m => m.default || m)
  },
  {
    name: change_45language88w8UPIbuSMeta?.name ?? "newsletter-change-language___en",
    path: change_45language88w8UPIbuSMeta?.path ?? "/en/newsletter/change-language",
    meta: change_45language88w8UPIbuSMeta || {},
    alias: change_45language88w8UPIbuSMeta?.alias || [],
    redirect: change_45language88w8UPIbuSMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/change-language.vue").then(m => m.default || m)
  },
  {
    name: change_45language88w8UPIbuSMeta?.name ?? "newsletter-change-language___id",
    path: change_45language88w8UPIbuSMeta?.path ?? "/id/newsletter/change-language",
    meta: change_45language88w8UPIbuSMeta || {},
    alias: change_45language88w8UPIbuSMeta?.alias || [],
    redirect: change_45language88w8UPIbuSMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/change-language.vue").then(m => m.default || m)
  },
  {
    name: change_45language88w8UPIbuSMeta?.name ?? "newsletter-change-language___tl",
    path: change_45language88w8UPIbuSMeta?.path ?? "/tl/newsletter/change-language",
    meta: change_45language88w8UPIbuSMeta || {},
    alias: change_45language88w8UPIbuSMeta?.alias || [],
    redirect: change_45language88w8UPIbuSMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/change-language.vue").then(m => m.default || m)
  },
  {
    name: change_45language88w8UPIbuSMeta?.name ?? "newsletter-change-language___zh",
    path: change_45language88w8UPIbuSMeta?.path ?? "/zh/newsletter/change-language",
    meta: change_45language88w8UPIbuSMeta || {},
    alias: change_45language88w8UPIbuSMeta?.alias || [],
    redirect: change_45language88w8UPIbuSMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/change-language.vue").then(m => m.default || m)
  },
  {
    name: change_45language88w8UPIbuSMeta?.name ?? "newsletter-change-language___zh-tw",
    path: change_45language88w8UPIbuSMeta?.path ?? "/zh-tw/newsletter/change-language",
    meta: change_45language88w8UPIbuSMeta || {},
    alias: change_45language88w8UPIbuSMeta?.alias || [],
    redirect: change_45language88w8UPIbuSMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/change-language.vue").then(m => m.default || m)
  },
  {
    name: change_45language88w8UPIbuSMeta?.name ?? "newsletter-change-language___th",
    path: change_45language88w8UPIbuSMeta?.path ?? "/th/newsletter/change-language",
    meta: change_45language88w8UPIbuSMeta || {},
    alias: change_45language88w8UPIbuSMeta?.alias || [],
    redirect: change_45language88w8UPIbuSMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/change-language.vue").then(m => m.default || m)
  },
  {
    name: change_45language88w8UPIbuSMeta?.name ?? "newsletter-change-language___ja",
    path: change_45language88w8UPIbuSMeta?.path ?? "/ja/newsletter/change-language",
    meta: change_45language88w8UPIbuSMeta || {},
    alias: change_45language88w8UPIbuSMeta?.alias || [],
    redirect: change_45language88w8UPIbuSMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/change-language.vue").then(m => m.default || m)
  },
  {
    name: change_45language88w8UPIbuSMeta?.name ?? "newsletter-change-language___ms",
    path: change_45language88w8UPIbuSMeta?.path ?? "/ms/newsletter/change-language",
    meta: change_45language88w8UPIbuSMeta || {},
    alias: change_45language88w8UPIbuSMeta?.alias || [],
    redirect: change_45language88w8UPIbuSMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/change-language.vue").then(m => m.default || m)
  },
  {
    name: change_45language88w8UPIbuSMeta?.name ?? "newsletter-change-language___ko",
    path: change_45language88w8UPIbuSMeta?.path ?? "/ko/newsletter/change-language",
    meta: change_45language88w8UPIbuSMeta || {},
    alias: change_45language88w8UPIbuSMeta?.alias || [],
    redirect: change_45language88w8UPIbuSMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/change-language.vue").then(m => m.default || m)
  },
  {
    name: change_45language88w8UPIbuSMeta?.name ?? "newsletter-change-language___vi",
    path: change_45language88w8UPIbuSMeta?.path ?? "/vi/newsletter/change-language",
    meta: change_45language88w8UPIbuSMeta || {},
    alias: change_45language88w8UPIbuSMeta?.alias || [],
    redirect: change_45language88w8UPIbuSMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/change-language.vue").then(m => m.default || m)
  },
  {
    name: change_45language88w8UPIbuSMeta?.name ?? "newsletter-change-language___it",
    path: change_45language88w8UPIbuSMeta?.path ?? "/it/newsletter/change-language",
    meta: change_45language88w8UPIbuSMeta || {},
    alias: change_45language88w8UPIbuSMeta?.alias || [],
    redirect: change_45language88w8UPIbuSMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/change-language.vue").then(m => m.default || m)
  },
  {
    name: change_45language88w8UPIbuSMeta?.name ?? "newsletter-change-language___de",
    path: change_45language88w8UPIbuSMeta?.path ?? "/de/newsletter/change-language",
    meta: change_45language88w8UPIbuSMeta || {},
    alias: change_45language88w8UPIbuSMeta?.alias || [],
    redirect: change_45language88w8UPIbuSMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/change-language.vue").then(m => m.default || m)
  },
  {
    name: change_45language88w8UPIbuSMeta?.name ?? "newsletter-change-language___es",
    path: change_45language88w8UPIbuSMeta?.path ?? "/es/newsletter/change-language",
    meta: change_45language88w8UPIbuSMeta || {},
    alias: change_45language88w8UPIbuSMeta?.alias || [],
    redirect: change_45language88w8UPIbuSMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/change-language.vue").then(m => m.default || m)
  },
  {
    name: change_45language88w8UPIbuSMeta?.name ?? "newsletter-change-language___hi",
    path: change_45language88w8UPIbuSMeta?.path ?? "/hi/newsletter/change-language",
    meta: change_45language88w8UPIbuSMeta || {},
    alias: change_45language88w8UPIbuSMeta?.alias || [],
    redirect: change_45language88w8UPIbuSMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/change-language.vue").then(m => m.default || m)
  },
  {
    name: change_45language88w8UPIbuSMeta?.name ?? "newsletter-change-language___fr",
    path: change_45language88w8UPIbuSMeta?.path ?? "/fr/newsletter/change-language",
    meta: change_45language88w8UPIbuSMeta || {},
    alias: change_45language88w8UPIbuSMeta?.alias || [],
    redirect: change_45language88w8UPIbuSMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/change-language.vue").then(m => m.default || m)
  },
  {
    name: change_45language88w8UPIbuSMeta?.name ?? "newsletter-change-language___ru",
    path: change_45language88w8UPIbuSMeta?.path ?? "/ru/newsletter/change-language",
    meta: change_45language88w8UPIbuSMeta || {},
    alias: change_45language88w8UPIbuSMeta?.alias || [],
    redirect: change_45language88w8UPIbuSMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/change-language.vue").then(m => m.default || m)
  },
  {
    name: change_45language88w8UPIbuSMeta?.name ?? "newsletter-change-language___bn",
    path: change_45language88w8UPIbuSMeta?.path ?? "/bn/newsletter/change-language",
    meta: change_45language88w8UPIbuSMeta || {},
    alias: change_45language88w8UPIbuSMeta?.alias || [],
    redirect: change_45language88w8UPIbuSMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/change-language.vue").then(m => m.default || m)
  },
  {
    name: change_45language88w8UPIbuSMeta?.name ?? "newsletter-change-language___pt",
    path: change_45language88w8UPIbuSMeta?.path ?? "/pt/newsletter/change-language",
    meta: change_45language88w8UPIbuSMeta || {},
    alias: change_45language88w8UPIbuSMeta?.alias || [],
    redirect: change_45language88w8UPIbuSMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/change-language.vue").then(m => m.default || m)
  },
  {
    name: change_45language88w8UPIbuSMeta?.name ?? "newsletter-change-language___km",
    path: change_45language88w8UPIbuSMeta?.path ?? "/km/newsletter/change-language",
    meta: change_45language88w8UPIbuSMeta || {},
    alias: change_45language88w8UPIbuSMeta?.alias || [],
    redirect: change_45language88w8UPIbuSMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/change-language.vue").then(m => m.default || m)
  },
  {
    name: choose_45languageCQ2OAWMLzMMeta?.name ?? "newsletter-choose-language___en",
    path: choose_45languageCQ2OAWMLzMMeta?.path ?? "/en/newsletter/choose-language",
    meta: choose_45languageCQ2OAWMLzMMeta || {},
    alias: choose_45languageCQ2OAWMLzMMeta?.alias || [],
    redirect: choose_45languageCQ2OAWMLzMMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/choose-language.vue").then(m => m.default || m)
  },
  {
    name: choose_45languageCQ2OAWMLzMMeta?.name ?? "newsletter-choose-language___id",
    path: choose_45languageCQ2OAWMLzMMeta?.path ?? "/id/newsletter/choose-language",
    meta: choose_45languageCQ2OAWMLzMMeta || {},
    alias: choose_45languageCQ2OAWMLzMMeta?.alias || [],
    redirect: choose_45languageCQ2OAWMLzMMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/choose-language.vue").then(m => m.default || m)
  },
  {
    name: choose_45languageCQ2OAWMLzMMeta?.name ?? "newsletter-choose-language___tl",
    path: choose_45languageCQ2OAWMLzMMeta?.path ?? "/tl/newsletter/choose-language",
    meta: choose_45languageCQ2OAWMLzMMeta || {},
    alias: choose_45languageCQ2OAWMLzMMeta?.alias || [],
    redirect: choose_45languageCQ2OAWMLzMMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/choose-language.vue").then(m => m.default || m)
  },
  {
    name: choose_45languageCQ2OAWMLzMMeta?.name ?? "newsletter-choose-language___zh",
    path: choose_45languageCQ2OAWMLzMMeta?.path ?? "/zh/newsletter/choose-language",
    meta: choose_45languageCQ2OAWMLzMMeta || {},
    alias: choose_45languageCQ2OAWMLzMMeta?.alias || [],
    redirect: choose_45languageCQ2OAWMLzMMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/choose-language.vue").then(m => m.default || m)
  },
  {
    name: choose_45languageCQ2OAWMLzMMeta?.name ?? "newsletter-choose-language___zh-tw",
    path: choose_45languageCQ2OAWMLzMMeta?.path ?? "/zh-tw/newsletter/choose-language",
    meta: choose_45languageCQ2OAWMLzMMeta || {},
    alias: choose_45languageCQ2OAWMLzMMeta?.alias || [],
    redirect: choose_45languageCQ2OAWMLzMMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/choose-language.vue").then(m => m.default || m)
  },
  {
    name: choose_45languageCQ2OAWMLzMMeta?.name ?? "newsletter-choose-language___th",
    path: choose_45languageCQ2OAWMLzMMeta?.path ?? "/th/newsletter/choose-language",
    meta: choose_45languageCQ2OAWMLzMMeta || {},
    alias: choose_45languageCQ2OAWMLzMMeta?.alias || [],
    redirect: choose_45languageCQ2OAWMLzMMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/choose-language.vue").then(m => m.default || m)
  },
  {
    name: choose_45languageCQ2OAWMLzMMeta?.name ?? "newsletter-choose-language___ja",
    path: choose_45languageCQ2OAWMLzMMeta?.path ?? "/ja/newsletter/choose-language",
    meta: choose_45languageCQ2OAWMLzMMeta || {},
    alias: choose_45languageCQ2OAWMLzMMeta?.alias || [],
    redirect: choose_45languageCQ2OAWMLzMMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/choose-language.vue").then(m => m.default || m)
  },
  {
    name: choose_45languageCQ2OAWMLzMMeta?.name ?? "newsletter-choose-language___ms",
    path: choose_45languageCQ2OAWMLzMMeta?.path ?? "/ms/newsletter/choose-language",
    meta: choose_45languageCQ2OAWMLzMMeta || {},
    alias: choose_45languageCQ2OAWMLzMMeta?.alias || [],
    redirect: choose_45languageCQ2OAWMLzMMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/choose-language.vue").then(m => m.default || m)
  },
  {
    name: choose_45languageCQ2OAWMLzMMeta?.name ?? "newsletter-choose-language___ko",
    path: choose_45languageCQ2OAWMLzMMeta?.path ?? "/ko/newsletter/choose-language",
    meta: choose_45languageCQ2OAWMLzMMeta || {},
    alias: choose_45languageCQ2OAWMLzMMeta?.alias || [],
    redirect: choose_45languageCQ2OAWMLzMMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/choose-language.vue").then(m => m.default || m)
  },
  {
    name: choose_45languageCQ2OAWMLzMMeta?.name ?? "newsletter-choose-language___vi",
    path: choose_45languageCQ2OAWMLzMMeta?.path ?? "/vi/newsletter/choose-language",
    meta: choose_45languageCQ2OAWMLzMMeta || {},
    alias: choose_45languageCQ2OAWMLzMMeta?.alias || [],
    redirect: choose_45languageCQ2OAWMLzMMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/choose-language.vue").then(m => m.default || m)
  },
  {
    name: choose_45languageCQ2OAWMLzMMeta?.name ?? "newsletter-choose-language___it",
    path: choose_45languageCQ2OAWMLzMMeta?.path ?? "/it/newsletter/choose-language",
    meta: choose_45languageCQ2OAWMLzMMeta || {},
    alias: choose_45languageCQ2OAWMLzMMeta?.alias || [],
    redirect: choose_45languageCQ2OAWMLzMMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/choose-language.vue").then(m => m.default || m)
  },
  {
    name: choose_45languageCQ2OAWMLzMMeta?.name ?? "newsletter-choose-language___de",
    path: choose_45languageCQ2OAWMLzMMeta?.path ?? "/de/newsletter/choose-language",
    meta: choose_45languageCQ2OAWMLzMMeta || {},
    alias: choose_45languageCQ2OAWMLzMMeta?.alias || [],
    redirect: choose_45languageCQ2OAWMLzMMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/choose-language.vue").then(m => m.default || m)
  },
  {
    name: choose_45languageCQ2OAWMLzMMeta?.name ?? "newsletter-choose-language___es",
    path: choose_45languageCQ2OAWMLzMMeta?.path ?? "/es/newsletter/choose-language",
    meta: choose_45languageCQ2OAWMLzMMeta || {},
    alias: choose_45languageCQ2OAWMLzMMeta?.alias || [],
    redirect: choose_45languageCQ2OAWMLzMMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/choose-language.vue").then(m => m.default || m)
  },
  {
    name: choose_45languageCQ2OAWMLzMMeta?.name ?? "newsletter-choose-language___hi",
    path: choose_45languageCQ2OAWMLzMMeta?.path ?? "/hi/newsletter/choose-language",
    meta: choose_45languageCQ2OAWMLzMMeta || {},
    alias: choose_45languageCQ2OAWMLzMMeta?.alias || [],
    redirect: choose_45languageCQ2OAWMLzMMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/choose-language.vue").then(m => m.default || m)
  },
  {
    name: choose_45languageCQ2OAWMLzMMeta?.name ?? "newsletter-choose-language___fr",
    path: choose_45languageCQ2OAWMLzMMeta?.path ?? "/fr/newsletter/choose-language",
    meta: choose_45languageCQ2OAWMLzMMeta || {},
    alias: choose_45languageCQ2OAWMLzMMeta?.alias || [],
    redirect: choose_45languageCQ2OAWMLzMMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/choose-language.vue").then(m => m.default || m)
  },
  {
    name: choose_45languageCQ2OAWMLzMMeta?.name ?? "newsletter-choose-language___ru",
    path: choose_45languageCQ2OAWMLzMMeta?.path ?? "/ru/newsletter/choose-language",
    meta: choose_45languageCQ2OAWMLzMMeta || {},
    alias: choose_45languageCQ2OAWMLzMMeta?.alias || [],
    redirect: choose_45languageCQ2OAWMLzMMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/choose-language.vue").then(m => m.default || m)
  },
  {
    name: choose_45languageCQ2OAWMLzMMeta?.name ?? "newsletter-choose-language___bn",
    path: choose_45languageCQ2OAWMLzMMeta?.path ?? "/bn/newsletter/choose-language",
    meta: choose_45languageCQ2OAWMLzMMeta || {},
    alias: choose_45languageCQ2OAWMLzMMeta?.alias || [],
    redirect: choose_45languageCQ2OAWMLzMMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/choose-language.vue").then(m => m.default || m)
  },
  {
    name: choose_45languageCQ2OAWMLzMMeta?.name ?? "newsletter-choose-language___pt",
    path: choose_45languageCQ2OAWMLzMMeta?.path ?? "/pt/newsletter/choose-language",
    meta: choose_45languageCQ2OAWMLzMMeta || {},
    alias: choose_45languageCQ2OAWMLzMMeta?.alias || [],
    redirect: choose_45languageCQ2OAWMLzMMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/choose-language.vue").then(m => m.default || m)
  },
  {
    name: choose_45languageCQ2OAWMLzMMeta?.name ?? "newsletter-choose-language___km",
    path: choose_45languageCQ2OAWMLzMMeta?.path ?? "/km/newsletter/choose-language",
    meta: choose_45languageCQ2OAWMLzMMeta || {},
    alias: choose_45languageCQ2OAWMLzMMeta?.alias || [],
    redirect: choose_45languageCQ2OAWMLzMMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/choose-language.vue").then(m => m.default || m)
  },
  {
    name: confirmGmI15blOX4Meta?.name ?? "newsletter-confirm___en",
    path: confirmGmI15blOX4Meta?.path ?? "/en/newsletter/confirm",
    meta: confirmGmI15blOX4Meta || {},
    alias: confirmGmI15blOX4Meta?.alias || [],
    redirect: confirmGmI15blOX4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/confirm.vue").then(m => m.default || m)
  },
  {
    name: confirmGmI15blOX4Meta?.name ?? "newsletter-confirm___id",
    path: confirmGmI15blOX4Meta?.path ?? "/id/newsletter/confirm",
    meta: confirmGmI15blOX4Meta || {},
    alias: confirmGmI15blOX4Meta?.alias || [],
    redirect: confirmGmI15blOX4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/confirm.vue").then(m => m.default || m)
  },
  {
    name: confirmGmI15blOX4Meta?.name ?? "newsletter-confirm___tl",
    path: confirmGmI15blOX4Meta?.path ?? "/tl/newsletter/confirm",
    meta: confirmGmI15blOX4Meta || {},
    alias: confirmGmI15blOX4Meta?.alias || [],
    redirect: confirmGmI15blOX4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/confirm.vue").then(m => m.default || m)
  },
  {
    name: confirmGmI15blOX4Meta?.name ?? "newsletter-confirm___zh",
    path: confirmGmI15blOX4Meta?.path ?? "/zh/newsletter/confirm",
    meta: confirmGmI15blOX4Meta || {},
    alias: confirmGmI15blOX4Meta?.alias || [],
    redirect: confirmGmI15blOX4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/confirm.vue").then(m => m.default || m)
  },
  {
    name: confirmGmI15blOX4Meta?.name ?? "newsletter-confirm___zh-tw",
    path: confirmGmI15blOX4Meta?.path ?? "/zh-tw/newsletter/confirm",
    meta: confirmGmI15blOX4Meta || {},
    alias: confirmGmI15blOX4Meta?.alias || [],
    redirect: confirmGmI15blOX4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/confirm.vue").then(m => m.default || m)
  },
  {
    name: confirmGmI15blOX4Meta?.name ?? "newsletter-confirm___th",
    path: confirmGmI15blOX4Meta?.path ?? "/th/newsletter/confirm",
    meta: confirmGmI15blOX4Meta || {},
    alias: confirmGmI15blOX4Meta?.alias || [],
    redirect: confirmGmI15blOX4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/confirm.vue").then(m => m.default || m)
  },
  {
    name: confirmGmI15blOX4Meta?.name ?? "newsletter-confirm___ja",
    path: confirmGmI15blOX4Meta?.path ?? "/ja/newsletter/confirm",
    meta: confirmGmI15blOX4Meta || {},
    alias: confirmGmI15blOX4Meta?.alias || [],
    redirect: confirmGmI15blOX4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/confirm.vue").then(m => m.default || m)
  },
  {
    name: confirmGmI15blOX4Meta?.name ?? "newsletter-confirm___ms",
    path: confirmGmI15blOX4Meta?.path ?? "/ms/newsletter/confirm",
    meta: confirmGmI15blOX4Meta || {},
    alias: confirmGmI15blOX4Meta?.alias || [],
    redirect: confirmGmI15blOX4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/confirm.vue").then(m => m.default || m)
  },
  {
    name: confirmGmI15blOX4Meta?.name ?? "newsletter-confirm___ko",
    path: confirmGmI15blOX4Meta?.path ?? "/ko/newsletter/confirm",
    meta: confirmGmI15blOX4Meta || {},
    alias: confirmGmI15blOX4Meta?.alias || [],
    redirect: confirmGmI15blOX4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/confirm.vue").then(m => m.default || m)
  },
  {
    name: confirmGmI15blOX4Meta?.name ?? "newsletter-confirm___vi",
    path: confirmGmI15blOX4Meta?.path ?? "/vi/newsletter/confirm",
    meta: confirmGmI15blOX4Meta || {},
    alias: confirmGmI15blOX4Meta?.alias || [],
    redirect: confirmGmI15blOX4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/confirm.vue").then(m => m.default || m)
  },
  {
    name: confirmGmI15blOX4Meta?.name ?? "newsletter-confirm___it",
    path: confirmGmI15blOX4Meta?.path ?? "/it/newsletter/confirm",
    meta: confirmGmI15blOX4Meta || {},
    alias: confirmGmI15blOX4Meta?.alias || [],
    redirect: confirmGmI15blOX4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/confirm.vue").then(m => m.default || m)
  },
  {
    name: confirmGmI15blOX4Meta?.name ?? "newsletter-confirm___de",
    path: confirmGmI15blOX4Meta?.path ?? "/de/newsletter/confirm",
    meta: confirmGmI15blOX4Meta || {},
    alias: confirmGmI15blOX4Meta?.alias || [],
    redirect: confirmGmI15blOX4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/confirm.vue").then(m => m.default || m)
  },
  {
    name: confirmGmI15blOX4Meta?.name ?? "newsletter-confirm___es",
    path: confirmGmI15blOX4Meta?.path ?? "/es/newsletter/confirm",
    meta: confirmGmI15blOX4Meta || {},
    alias: confirmGmI15blOX4Meta?.alias || [],
    redirect: confirmGmI15blOX4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/confirm.vue").then(m => m.default || m)
  },
  {
    name: confirmGmI15blOX4Meta?.name ?? "newsletter-confirm___hi",
    path: confirmGmI15blOX4Meta?.path ?? "/hi/newsletter/confirm",
    meta: confirmGmI15blOX4Meta || {},
    alias: confirmGmI15blOX4Meta?.alias || [],
    redirect: confirmGmI15blOX4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/confirm.vue").then(m => m.default || m)
  },
  {
    name: confirmGmI15blOX4Meta?.name ?? "newsletter-confirm___fr",
    path: confirmGmI15blOX4Meta?.path ?? "/fr/newsletter/confirm",
    meta: confirmGmI15blOX4Meta || {},
    alias: confirmGmI15blOX4Meta?.alias || [],
    redirect: confirmGmI15blOX4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/confirm.vue").then(m => m.default || m)
  },
  {
    name: confirmGmI15blOX4Meta?.name ?? "newsletter-confirm___ru",
    path: confirmGmI15blOX4Meta?.path ?? "/ru/newsletter/confirm",
    meta: confirmGmI15blOX4Meta || {},
    alias: confirmGmI15blOX4Meta?.alias || [],
    redirect: confirmGmI15blOX4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/confirm.vue").then(m => m.default || m)
  },
  {
    name: confirmGmI15blOX4Meta?.name ?? "newsletter-confirm___bn",
    path: confirmGmI15blOX4Meta?.path ?? "/bn/newsletter/confirm",
    meta: confirmGmI15blOX4Meta || {},
    alias: confirmGmI15blOX4Meta?.alias || [],
    redirect: confirmGmI15blOX4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/confirm.vue").then(m => m.default || m)
  },
  {
    name: confirmGmI15blOX4Meta?.name ?? "newsletter-confirm___pt",
    path: confirmGmI15blOX4Meta?.path ?? "/pt/newsletter/confirm",
    meta: confirmGmI15blOX4Meta || {},
    alias: confirmGmI15blOX4Meta?.alias || [],
    redirect: confirmGmI15blOX4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/confirm.vue").then(m => m.default || m)
  },
  {
    name: confirmGmI15blOX4Meta?.name ?? "newsletter-confirm___km",
    path: confirmGmI15blOX4Meta?.path ?? "/km/newsletter/confirm",
    meta: confirmGmI15blOX4Meta || {},
    alias: confirmGmI15blOX4Meta?.alias || [],
    redirect: confirmGmI15blOX4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/confirm.vue").then(m => m.default || m)
  },
  {
    name: unsubscribebGe4WqbNPqMeta?.name ?? "newsletter-unsubscribe___en",
    path: unsubscribebGe4WqbNPqMeta?.path ?? "/en/newsletter/unsubscribe",
    meta: unsubscribebGe4WqbNPqMeta || {},
    alias: unsubscribebGe4WqbNPqMeta?.alias || [],
    redirect: unsubscribebGe4WqbNPqMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: unsubscribebGe4WqbNPqMeta?.name ?? "newsletter-unsubscribe___id",
    path: unsubscribebGe4WqbNPqMeta?.path ?? "/id/newsletter/unsubscribe",
    meta: unsubscribebGe4WqbNPqMeta || {},
    alias: unsubscribebGe4WqbNPqMeta?.alias || [],
    redirect: unsubscribebGe4WqbNPqMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: unsubscribebGe4WqbNPqMeta?.name ?? "newsletter-unsubscribe___tl",
    path: unsubscribebGe4WqbNPqMeta?.path ?? "/tl/newsletter/unsubscribe",
    meta: unsubscribebGe4WqbNPqMeta || {},
    alias: unsubscribebGe4WqbNPqMeta?.alias || [],
    redirect: unsubscribebGe4WqbNPqMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: unsubscribebGe4WqbNPqMeta?.name ?? "newsletter-unsubscribe___zh",
    path: unsubscribebGe4WqbNPqMeta?.path ?? "/zh/newsletter/unsubscribe",
    meta: unsubscribebGe4WqbNPqMeta || {},
    alias: unsubscribebGe4WqbNPqMeta?.alias || [],
    redirect: unsubscribebGe4WqbNPqMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: unsubscribebGe4WqbNPqMeta?.name ?? "newsletter-unsubscribe___zh-tw",
    path: unsubscribebGe4WqbNPqMeta?.path ?? "/zh-tw/newsletter/unsubscribe",
    meta: unsubscribebGe4WqbNPqMeta || {},
    alias: unsubscribebGe4WqbNPqMeta?.alias || [],
    redirect: unsubscribebGe4WqbNPqMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: unsubscribebGe4WqbNPqMeta?.name ?? "newsletter-unsubscribe___th",
    path: unsubscribebGe4WqbNPqMeta?.path ?? "/th/newsletter/unsubscribe",
    meta: unsubscribebGe4WqbNPqMeta || {},
    alias: unsubscribebGe4WqbNPqMeta?.alias || [],
    redirect: unsubscribebGe4WqbNPqMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: unsubscribebGe4WqbNPqMeta?.name ?? "newsletter-unsubscribe___ja",
    path: unsubscribebGe4WqbNPqMeta?.path ?? "/ja/newsletter/unsubscribe",
    meta: unsubscribebGe4WqbNPqMeta || {},
    alias: unsubscribebGe4WqbNPqMeta?.alias || [],
    redirect: unsubscribebGe4WqbNPqMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: unsubscribebGe4WqbNPqMeta?.name ?? "newsletter-unsubscribe___ms",
    path: unsubscribebGe4WqbNPqMeta?.path ?? "/ms/newsletter/unsubscribe",
    meta: unsubscribebGe4WqbNPqMeta || {},
    alias: unsubscribebGe4WqbNPqMeta?.alias || [],
    redirect: unsubscribebGe4WqbNPqMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: unsubscribebGe4WqbNPqMeta?.name ?? "newsletter-unsubscribe___ko",
    path: unsubscribebGe4WqbNPqMeta?.path ?? "/ko/newsletter/unsubscribe",
    meta: unsubscribebGe4WqbNPqMeta || {},
    alias: unsubscribebGe4WqbNPqMeta?.alias || [],
    redirect: unsubscribebGe4WqbNPqMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: unsubscribebGe4WqbNPqMeta?.name ?? "newsletter-unsubscribe___vi",
    path: unsubscribebGe4WqbNPqMeta?.path ?? "/vi/newsletter/unsubscribe",
    meta: unsubscribebGe4WqbNPqMeta || {},
    alias: unsubscribebGe4WqbNPqMeta?.alias || [],
    redirect: unsubscribebGe4WqbNPqMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: unsubscribebGe4WqbNPqMeta?.name ?? "newsletter-unsubscribe___it",
    path: unsubscribebGe4WqbNPqMeta?.path ?? "/it/newsletter/unsubscribe",
    meta: unsubscribebGe4WqbNPqMeta || {},
    alias: unsubscribebGe4WqbNPqMeta?.alias || [],
    redirect: unsubscribebGe4WqbNPqMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: unsubscribebGe4WqbNPqMeta?.name ?? "newsletter-unsubscribe___de",
    path: unsubscribebGe4WqbNPqMeta?.path ?? "/de/newsletter/unsubscribe",
    meta: unsubscribebGe4WqbNPqMeta || {},
    alias: unsubscribebGe4WqbNPqMeta?.alias || [],
    redirect: unsubscribebGe4WqbNPqMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: unsubscribebGe4WqbNPqMeta?.name ?? "newsletter-unsubscribe___es",
    path: unsubscribebGe4WqbNPqMeta?.path ?? "/es/newsletter/unsubscribe",
    meta: unsubscribebGe4WqbNPqMeta || {},
    alias: unsubscribebGe4WqbNPqMeta?.alias || [],
    redirect: unsubscribebGe4WqbNPqMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: unsubscribebGe4WqbNPqMeta?.name ?? "newsletter-unsubscribe___hi",
    path: unsubscribebGe4WqbNPqMeta?.path ?? "/hi/newsletter/unsubscribe",
    meta: unsubscribebGe4WqbNPqMeta || {},
    alias: unsubscribebGe4WqbNPqMeta?.alias || [],
    redirect: unsubscribebGe4WqbNPqMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: unsubscribebGe4WqbNPqMeta?.name ?? "newsletter-unsubscribe___fr",
    path: unsubscribebGe4WqbNPqMeta?.path ?? "/fr/newsletter/unsubscribe",
    meta: unsubscribebGe4WqbNPqMeta || {},
    alias: unsubscribebGe4WqbNPqMeta?.alias || [],
    redirect: unsubscribebGe4WqbNPqMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: unsubscribebGe4WqbNPqMeta?.name ?? "newsletter-unsubscribe___ru",
    path: unsubscribebGe4WqbNPqMeta?.path ?? "/ru/newsletter/unsubscribe",
    meta: unsubscribebGe4WqbNPqMeta || {},
    alias: unsubscribebGe4WqbNPqMeta?.alias || [],
    redirect: unsubscribebGe4WqbNPqMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: unsubscribebGe4WqbNPqMeta?.name ?? "newsletter-unsubscribe___bn",
    path: unsubscribebGe4WqbNPqMeta?.path ?? "/bn/newsletter/unsubscribe",
    meta: unsubscribebGe4WqbNPqMeta || {},
    alias: unsubscribebGe4WqbNPqMeta?.alias || [],
    redirect: unsubscribebGe4WqbNPqMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: unsubscribebGe4WqbNPqMeta?.name ?? "newsletter-unsubscribe___pt",
    path: unsubscribebGe4WqbNPqMeta?.path ?? "/pt/newsletter/unsubscribe",
    meta: unsubscribebGe4WqbNPqMeta || {},
    alias: unsubscribebGe4WqbNPqMeta?.alias || [],
    redirect: unsubscribebGe4WqbNPqMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: unsubscribebGe4WqbNPqMeta?.name ?? "newsletter-unsubscribe___km",
    path: unsubscribebGe4WqbNPqMeta?.path ?? "/km/newsletter/unsubscribe",
    meta: unsubscribebGe4WqbNPqMeta || {},
    alias: unsubscribebGe4WqbNPqMeta?.alias || [],
    redirect: unsubscribebGe4WqbNPqMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/newsletter/unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: _91id_93QnZQgI7asXMeta?.name ?? "order-id___en",
    path: _91id_93QnZQgI7asXMeta?.path ?? "/en/order/:id()",
    meta: _91id_93QnZQgI7asXMeta || {},
    alias: _91id_93QnZQgI7asXMeta?.alias || [],
    redirect: _91id_93QnZQgI7asXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/order/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93QnZQgI7asXMeta?.name ?? "order-id___id",
    path: _91id_93QnZQgI7asXMeta?.path ?? "/id/order/:id()",
    meta: _91id_93QnZQgI7asXMeta || {},
    alias: _91id_93QnZQgI7asXMeta?.alias || [],
    redirect: _91id_93QnZQgI7asXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/order/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93QnZQgI7asXMeta?.name ?? "order-id___tl",
    path: _91id_93QnZQgI7asXMeta?.path ?? "/tl/order/:id()",
    meta: _91id_93QnZQgI7asXMeta || {},
    alias: _91id_93QnZQgI7asXMeta?.alias || [],
    redirect: _91id_93QnZQgI7asXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/order/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93QnZQgI7asXMeta?.name ?? "order-id___zh",
    path: _91id_93QnZQgI7asXMeta?.path ?? "/zh/order/:id()",
    meta: _91id_93QnZQgI7asXMeta || {},
    alias: _91id_93QnZQgI7asXMeta?.alias || [],
    redirect: _91id_93QnZQgI7asXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/order/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93QnZQgI7asXMeta?.name ?? "order-id___zh-tw",
    path: _91id_93QnZQgI7asXMeta?.path ?? "/zh-tw/order/:id()",
    meta: _91id_93QnZQgI7asXMeta || {},
    alias: _91id_93QnZQgI7asXMeta?.alias || [],
    redirect: _91id_93QnZQgI7asXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/order/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93QnZQgI7asXMeta?.name ?? "order-id___th",
    path: _91id_93QnZQgI7asXMeta?.path ?? "/th/order/:id()",
    meta: _91id_93QnZQgI7asXMeta || {},
    alias: _91id_93QnZQgI7asXMeta?.alias || [],
    redirect: _91id_93QnZQgI7asXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/order/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93QnZQgI7asXMeta?.name ?? "order-id___ja",
    path: _91id_93QnZQgI7asXMeta?.path ?? "/ja/order/:id()",
    meta: _91id_93QnZQgI7asXMeta || {},
    alias: _91id_93QnZQgI7asXMeta?.alias || [],
    redirect: _91id_93QnZQgI7asXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/order/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93QnZQgI7asXMeta?.name ?? "order-id___ms",
    path: _91id_93QnZQgI7asXMeta?.path ?? "/ms/order/:id()",
    meta: _91id_93QnZQgI7asXMeta || {},
    alias: _91id_93QnZQgI7asXMeta?.alias || [],
    redirect: _91id_93QnZQgI7asXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/order/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93QnZQgI7asXMeta?.name ?? "order-id___ko",
    path: _91id_93QnZQgI7asXMeta?.path ?? "/ko/order/:id()",
    meta: _91id_93QnZQgI7asXMeta || {},
    alias: _91id_93QnZQgI7asXMeta?.alias || [],
    redirect: _91id_93QnZQgI7asXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/order/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93QnZQgI7asXMeta?.name ?? "order-id___vi",
    path: _91id_93QnZQgI7asXMeta?.path ?? "/vi/order/:id()",
    meta: _91id_93QnZQgI7asXMeta || {},
    alias: _91id_93QnZQgI7asXMeta?.alias || [],
    redirect: _91id_93QnZQgI7asXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/order/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93QnZQgI7asXMeta?.name ?? "order-id___it",
    path: _91id_93QnZQgI7asXMeta?.path ?? "/it/order/:id()",
    meta: _91id_93QnZQgI7asXMeta || {},
    alias: _91id_93QnZQgI7asXMeta?.alias || [],
    redirect: _91id_93QnZQgI7asXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/order/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93QnZQgI7asXMeta?.name ?? "order-id___de",
    path: _91id_93QnZQgI7asXMeta?.path ?? "/de/order/:id()",
    meta: _91id_93QnZQgI7asXMeta || {},
    alias: _91id_93QnZQgI7asXMeta?.alias || [],
    redirect: _91id_93QnZQgI7asXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/order/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93QnZQgI7asXMeta?.name ?? "order-id___es",
    path: _91id_93QnZQgI7asXMeta?.path ?? "/es/order/:id()",
    meta: _91id_93QnZQgI7asXMeta || {},
    alias: _91id_93QnZQgI7asXMeta?.alias || [],
    redirect: _91id_93QnZQgI7asXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/order/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93QnZQgI7asXMeta?.name ?? "order-id___hi",
    path: _91id_93QnZQgI7asXMeta?.path ?? "/hi/order/:id()",
    meta: _91id_93QnZQgI7asXMeta || {},
    alias: _91id_93QnZQgI7asXMeta?.alias || [],
    redirect: _91id_93QnZQgI7asXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/order/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93QnZQgI7asXMeta?.name ?? "order-id___fr",
    path: _91id_93QnZQgI7asXMeta?.path ?? "/fr/order/:id()",
    meta: _91id_93QnZQgI7asXMeta || {},
    alias: _91id_93QnZQgI7asXMeta?.alias || [],
    redirect: _91id_93QnZQgI7asXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/order/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93QnZQgI7asXMeta?.name ?? "order-id___ru",
    path: _91id_93QnZQgI7asXMeta?.path ?? "/ru/order/:id()",
    meta: _91id_93QnZQgI7asXMeta || {},
    alias: _91id_93QnZQgI7asXMeta?.alias || [],
    redirect: _91id_93QnZQgI7asXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/order/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93QnZQgI7asXMeta?.name ?? "order-id___bn",
    path: _91id_93QnZQgI7asXMeta?.path ?? "/bn/order/:id()",
    meta: _91id_93QnZQgI7asXMeta || {},
    alias: _91id_93QnZQgI7asXMeta?.alias || [],
    redirect: _91id_93QnZQgI7asXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/order/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93QnZQgI7asXMeta?.name ?? "order-id___pt",
    path: _91id_93QnZQgI7asXMeta?.path ?? "/pt/order/:id()",
    meta: _91id_93QnZQgI7asXMeta || {},
    alias: _91id_93QnZQgI7asXMeta?.alias || [],
    redirect: _91id_93QnZQgI7asXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/order/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93QnZQgI7asXMeta?.name ?? "order-id___km",
    path: _91id_93QnZQgI7asXMeta?.path ?? "/km/order/:id()",
    meta: _91id_93QnZQgI7asXMeta || {},
    alias: _91id_93QnZQgI7asXMeta?.alias || [],
    redirect: _91id_93QnZQgI7asXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/order/[id].vue").then(m => m.default || m)
  },
  {
    name: indexZtmiVKQgnmMeta?.name ?? "order___en",
    path: indexZtmiVKQgnmMeta?.path ?? "/en/order",
    meta: indexZtmiVKQgnmMeta || {},
    alias: indexZtmiVKQgnmMeta?.alias || [],
    redirect: indexZtmiVKQgnmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/order/index.vue").then(m => m.default || m)
  },
  {
    name: indexZtmiVKQgnmMeta?.name ?? "order___id",
    path: indexZtmiVKQgnmMeta?.path ?? "/id/order",
    meta: indexZtmiVKQgnmMeta || {},
    alias: indexZtmiVKQgnmMeta?.alias || [],
    redirect: indexZtmiVKQgnmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/order/index.vue").then(m => m.default || m)
  },
  {
    name: indexZtmiVKQgnmMeta?.name ?? "order___tl",
    path: indexZtmiVKQgnmMeta?.path ?? "/tl/order",
    meta: indexZtmiVKQgnmMeta || {},
    alias: indexZtmiVKQgnmMeta?.alias || [],
    redirect: indexZtmiVKQgnmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/order/index.vue").then(m => m.default || m)
  },
  {
    name: indexZtmiVKQgnmMeta?.name ?? "order___zh",
    path: indexZtmiVKQgnmMeta?.path ?? "/zh/order",
    meta: indexZtmiVKQgnmMeta || {},
    alias: indexZtmiVKQgnmMeta?.alias || [],
    redirect: indexZtmiVKQgnmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/order/index.vue").then(m => m.default || m)
  },
  {
    name: indexZtmiVKQgnmMeta?.name ?? "order___zh-tw",
    path: indexZtmiVKQgnmMeta?.path ?? "/zh-tw/order",
    meta: indexZtmiVKQgnmMeta || {},
    alias: indexZtmiVKQgnmMeta?.alias || [],
    redirect: indexZtmiVKQgnmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/order/index.vue").then(m => m.default || m)
  },
  {
    name: indexZtmiVKQgnmMeta?.name ?? "order___th",
    path: indexZtmiVKQgnmMeta?.path ?? "/th/order",
    meta: indexZtmiVKQgnmMeta || {},
    alias: indexZtmiVKQgnmMeta?.alias || [],
    redirect: indexZtmiVKQgnmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/order/index.vue").then(m => m.default || m)
  },
  {
    name: indexZtmiVKQgnmMeta?.name ?? "order___ja",
    path: indexZtmiVKQgnmMeta?.path ?? "/ja/order",
    meta: indexZtmiVKQgnmMeta || {},
    alias: indexZtmiVKQgnmMeta?.alias || [],
    redirect: indexZtmiVKQgnmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/order/index.vue").then(m => m.default || m)
  },
  {
    name: indexZtmiVKQgnmMeta?.name ?? "order___ms",
    path: indexZtmiVKQgnmMeta?.path ?? "/ms/order",
    meta: indexZtmiVKQgnmMeta || {},
    alias: indexZtmiVKQgnmMeta?.alias || [],
    redirect: indexZtmiVKQgnmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/order/index.vue").then(m => m.default || m)
  },
  {
    name: indexZtmiVKQgnmMeta?.name ?? "order___ko",
    path: indexZtmiVKQgnmMeta?.path ?? "/ko/order",
    meta: indexZtmiVKQgnmMeta || {},
    alias: indexZtmiVKQgnmMeta?.alias || [],
    redirect: indexZtmiVKQgnmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/order/index.vue").then(m => m.default || m)
  },
  {
    name: indexZtmiVKQgnmMeta?.name ?? "order___vi",
    path: indexZtmiVKQgnmMeta?.path ?? "/vi/order",
    meta: indexZtmiVKQgnmMeta || {},
    alias: indexZtmiVKQgnmMeta?.alias || [],
    redirect: indexZtmiVKQgnmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/order/index.vue").then(m => m.default || m)
  },
  {
    name: indexZtmiVKQgnmMeta?.name ?? "order___it",
    path: indexZtmiVKQgnmMeta?.path ?? "/it/order",
    meta: indexZtmiVKQgnmMeta || {},
    alias: indexZtmiVKQgnmMeta?.alias || [],
    redirect: indexZtmiVKQgnmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/order/index.vue").then(m => m.default || m)
  },
  {
    name: indexZtmiVKQgnmMeta?.name ?? "order___de",
    path: indexZtmiVKQgnmMeta?.path ?? "/de/order",
    meta: indexZtmiVKQgnmMeta || {},
    alias: indexZtmiVKQgnmMeta?.alias || [],
    redirect: indexZtmiVKQgnmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/order/index.vue").then(m => m.default || m)
  },
  {
    name: indexZtmiVKQgnmMeta?.name ?? "order___es",
    path: indexZtmiVKQgnmMeta?.path ?? "/es/order",
    meta: indexZtmiVKQgnmMeta || {},
    alias: indexZtmiVKQgnmMeta?.alias || [],
    redirect: indexZtmiVKQgnmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/order/index.vue").then(m => m.default || m)
  },
  {
    name: indexZtmiVKQgnmMeta?.name ?? "order___hi",
    path: indexZtmiVKQgnmMeta?.path ?? "/hi/order",
    meta: indexZtmiVKQgnmMeta || {},
    alias: indexZtmiVKQgnmMeta?.alias || [],
    redirect: indexZtmiVKQgnmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/order/index.vue").then(m => m.default || m)
  },
  {
    name: indexZtmiVKQgnmMeta?.name ?? "order___fr",
    path: indexZtmiVKQgnmMeta?.path ?? "/fr/order",
    meta: indexZtmiVKQgnmMeta || {},
    alias: indexZtmiVKQgnmMeta?.alias || [],
    redirect: indexZtmiVKQgnmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/order/index.vue").then(m => m.default || m)
  },
  {
    name: indexZtmiVKQgnmMeta?.name ?? "order___ru",
    path: indexZtmiVKQgnmMeta?.path ?? "/ru/order",
    meta: indexZtmiVKQgnmMeta || {},
    alias: indexZtmiVKQgnmMeta?.alias || [],
    redirect: indexZtmiVKQgnmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/order/index.vue").then(m => m.default || m)
  },
  {
    name: indexZtmiVKQgnmMeta?.name ?? "order___bn",
    path: indexZtmiVKQgnmMeta?.path ?? "/bn/order",
    meta: indexZtmiVKQgnmMeta || {},
    alias: indexZtmiVKQgnmMeta?.alias || [],
    redirect: indexZtmiVKQgnmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/order/index.vue").then(m => m.default || m)
  },
  {
    name: indexZtmiVKQgnmMeta?.name ?? "order___pt",
    path: indexZtmiVKQgnmMeta?.path ?? "/pt/order",
    meta: indexZtmiVKQgnmMeta || {},
    alias: indexZtmiVKQgnmMeta?.alias || [],
    redirect: indexZtmiVKQgnmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/order/index.vue").then(m => m.default || m)
  },
  {
    name: indexZtmiVKQgnmMeta?.name ?? "order___km",
    path: indexZtmiVKQgnmMeta?.path ?? "/km/order",
    meta: indexZtmiVKQgnmMeta || {},
    alias: indexZtmiVKQgnmMeta?.alias || [],
    redirect: indexZtmiVKQgnmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/order/index.vue").then(m => m.default || m)
  },
  {
    name: chooseVxa9axX1xlMeta?.name ?? "pay-trxId-choose___en",
    path: chooseVxa9axX1xlMeta?.path ?? "/en/pay/:trxId()/choose",
    meta: chooseVxa9axX1xlMeta || {},
    alias: chooseVxa9axX1xlMeta?.alias || [],
    redirect: chooseVxa9axX1xlMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/pay/[trxId]/choose.vue").then(m => m.default || m)
  },
  {
    name: chooseVxa9axX1xlMeta?.name ?? "pay-trxId-choose___id",
    path: chooseVxa9axX1xlMeta?.path ?? "/id/pay/:trxId()/choose",
    meta: chooseVxa9axX1xlMeta || {},
    alias: chooseVxa9axX1xlMeta?.alias || [],
    redirect: chooseVxa9axX1xlMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/pay/[trxId]/choose.vue").then(m => m.default || m)
  },
  {
    name: chooseVxa9axX1xlMeta?.name ?? "pay-trxId-choose___tl",
    path: chooseVxa9axX1xlMeta?.path ?? "/tl/pay/:trxId()/choose",
    meta: chooseVxa9axX1xlMeta || {},
    alias: chooseVxa9axX1xlMeta?.alias || [],
    redirect: chooseVxa9axX1xlMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/pay/[trxId]/choose.vue").then(m => m.default || m)
  },
  {
    name: chooseVxa9axX1xlMeta?.name ?? "pay-trxId-choose___zh",
    path: chooseVxa9axX1xlMeta?.path ?? "/zh/pay/:trxId()/choose",
    meta: chooseVxa9axX1xlMeta || {},
    alias: chooseVxa9axX1xlMeta?.alias || [],
    redirect: chooseVxa9axX1xlMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/pay/[trxId]/choose.vue").then(m => m.default || m)
  },
  {
    name: chooseVxa9axX1xlMeta?.name ?? "pay-trxId-choose___zh-tw",
    path: chooseVxa9axX1xlMeta?.path ?? "/zh-tw/pay/:trxId()/choose",
    meta: chooseVxa9axX1xlMeta || {},
    alias: chooseVxa9axX1xlMeta?.alias || [],
    redirect: chooseVxa9axX1xlMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/pay/[trxId]/choose.vue").then(m => m.default || m)
  },
  {
    name: chooseVxa9axX1xlMeta?.name ?? "pay-trxId-choose___th",
    path: chooseVxa9axX1xlMeta?.path ?? "/th/pay/:trxId()/choose",
    meta: chooseVxa9axX1xlMeta || {},
    alias: chooseVxa9axX1xlMeta?.alias || [],
    redirect: chooseVxa9axX1xlMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/pay/[trxId]/choose.vue").then(m => m.default || m)
  },
  {
    name: chooseVxa9axX1xlMeta?.name ?? "pay-trxId-choose___ja",
    path: chooseVxa9axX1xlMeta?.path ?? "/ja/pay/:trxId()/choose",
    meta: chooseVxa9axX1xlMeta || {},
    alias: chooseVxa9axX1xlMeta?.alias || [],
    redirect: chooseVxa9axX1xlMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/pay/[trxId]/choose.vue").then(m => m.default || m)
  },
  {
    name: chooseVxa9axX1xlMeta?.name ?? "pay-trxId-choose___ms",
    path: chooseVxa9axX1xlMeta?.path ?? "/ms/pay/:trxId()/choose",
    meta: chooseVxa9axX1xlMeta || {},
    alias: chooseVxa9axX1xlMeta?.alias || [],
    redirect: chooseVxa9axX1xlMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/pay/[trxId]/choose.vue").then(m => m.default || m)
  },
  {
    name: chooseVxa9axX1xlMeta?.name ?? "pay-trxId-choose___ko",
    path: chooseVxa9axX1xlMeta?.path ?? "/ko/pay/:trxId()/choose",
    meta: chooseVxa9axX1xlMeta || {},
    alias: chooseVxa9axX1xlMeta?.alias || [],
    redirect: chooseVxa9axX1xlMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/pay/[trxId]/choose.vue").then(m => m.default || m)
  },
  {
    name: chooseVxa9axX1xlMeta?.name ?? "pay-trxId-choose___vi",
    path: chooseVxa9axX1xlMeta?.path ?? "/vi/pay/:trxId()/choose",
    meta: chooseVxa9axX1xlMeta || {},
    alias: chooseVxa9axX1xlMeta?.alias || [],
    redirect: chooseVxa9axX1xlMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/pay/[trxId]/choose.vue").then(m => m.default || m)
  },
  {
    name: chooseVxa9axX1xlMeta?.name ?? "pay-trxId-choose___it",
    path: chooseVxa9axX1xlMeta?.path ?? "/it/pay/:trxId()/choose",
    meta: chooseVxa9axX1xlMeta || {},
    alias: chooseVxa9axX1xlMeta?.alias || [],
    redirect: chooseVxa9axX1xlMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/pay/[trxId]/choose.vue").then(m => m.default || m)
  },
  {
    name: chooseVxa9axX1xlMeta?.name ?? "pay-trxId-choose___de",
    path: chooseVxa9axX1xlMeta?.path ?? "/de/pay/:trxId()/choose",
    meta: chooseVxa9axX1xlMeta || {},
    alias: chooseVxa9axX1xlMeta?.alias || [],
    redirect: chooseVxa9axX1xlMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/pay/[trxId]/choose.vue").then(m => m.default || m)
  },
  {
    name: chooseVxa9axX1xlMeta?.name ?? "pay-trxId-choose___es",
    path: chooseVxa9axX1xlMeta?.path ?? "/es/pay/:trxId()/choose",
    meta: chooseVxa9axX1xlMeta || {},
    alias: chooseVxa9axX1xlMeta?.alias || [],
    redirect: chooseVxa9axX1xlMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/pay/[trxId]/choose.vue").then(m => m.default || m)
  },
  {
    name: chooseVxa9axX1xlMeta?.name ?? "pay-trxId-choose___hi",
    path: chooseVxa9axX1xlMeta?.path ?? "/hi/pay/:trxId()/choose",
    meta: chooseVxa9axX1xlMeta || {},
    alias: chooseVxa9axX1xlMeta?.alias || [],
    redirect: chooseVxa9axX1xlMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/pay/[trxId]/choose.vue").then(m => m.default || m)
  },
  {
    name: chooseVxa9axX1xlMeta?.name ?? "pay-trxId-choose___fr",
    path: chooseVxa9axX1xlMeta?.path ?? "/fr/pay/:trxId()/choose",
    meta: chooseVxa9axX1xlMeta || {},
    alias: chooseVxa9axX1xlMeta?.alias || [],
    redirect: chooseVxa9axX1xlMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/pay/[trxId]/choose.vue").then(m => m.default || m)
  },
  {
    name: chooseVxa9axX1xlMeta?.name ?? "pay-trxId-choose___ru",
    path: chooseVxa9axX1xlMeta?.path ?? "/ru/pay/:trxId()/choose",
    meta: chooseVxa9axX1xlMeta || {},
    alias: chooseVxa9axX1xlMeta?.alias || [],
    redirect: chooseVxa9axX1xlMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/pay/[trxId]/choose.vue").then(m => m.default || m)
  },
  {
    name: chooseVxa9axX1xlMeta?.name ?? "pay-trxId-choose___bn",
    path: chooseVxa9axX1xlMeta?.path ?? "/bn/pay/:trxId()/choose",
    meta: chooseVxa9axX1xlMeta || {},
    alias: chooseVxa9axX1xlMeta?.alias || [],
    redirect: chooseVxa9axX1xlMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/pay/[trxId]/choose.vue").then(m => m.default || m)
  },
  {
    name: chooseVxa9axX1xlMeta?.name ?? "pay-trxId-choose___pt",
    path: chooseVxa9axX1xlMeta?.path ?? "/pt/pay/:trxId()/choose",
    meta: chooseVxa9axX1xlMeta || {},
    alias: chooseVxa9axX1xlMeta?.alias || [],
    redirect: chooseVxa9axX1xlMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/pay/[trxId]/choose.vue").then(m => m.default || m)
  },
  {
    name: chooseVxa9axX1xlMeta?.name ?? "pay-trxId-choose___km",
    path: chooseVxa9axX1xlMeta?.path ?? "/km/pay/:trxId()/choose",
    meta: chooseVxa9axX1xlMeta || {},
    alias: chooseVxa9axX1xlMeta?.alias || [],
    redirect: chooseVxa9axX1xlMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/pay/[trxId]/choose.vue").then(m => m.default || m)
  },
  {
    name: confirmationOPKl7x3dpeMeta?.name ?? "pay-trxId-confirmation___en",
    path: confirmationOPKl7x3dpeMeta?.path ?? "/en/pay/:trxId()/confirmation",
    meta: confirmationOPKl7x3dpeMeta || {},
    alias: confirmationOPKl7x3dpeMeta?.alias || [],
    redirect: confirmationOPKl7x3dpeMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/pay/[trxId]/confirmation.vue").then(m => m.default || m)
  },
  {
    name: confirmationOPKl7x3dpeMeta?.name ?? "pay-trxId-confirmation___id",
    path: confirmationOPKl7x3dpeMeta?.path ?? "/id/pay/:trxId()/confirmation",
    meta: confirmationOPKl7x3dpeMeta || {},
    alias: confirmationOPKl7x3dpeMeta?.alias || [],
    redirect: confirmationOPKl7x3dpeMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/pay/[trxId]/confirmation.vue").then(m => m.default || m)
  },
  {
    name: confirmationOPKl7x3dpeMeta?.name ?? "pay-trxId-confirmation___tl",
    path: confirmationOPKl7x3dpeMeta?.path ?? "/tl/pay/:trxId()/confirmation",
    meta: confirmationOPKl7x3dpeMeta || {},
    alias: confirmationOPKl7x3dpeMeta?.alias || [],
    redirect: confirmationOPKl7x3dpeMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/pay/[trxId]/confirmation.vue").then(m => m.default || m)
  },
  {
    name: confirmationOPKl7x3dpeMeta?.name ?? "pay-trxId-confirmation___zh",
    path: confirmationOPKl7x3dpeMeta?.path ?? "/zh/pay/:trxId()/confirmation",
    meta: confirmationOPKl7x3dpeMeta || {},
    alias: confirmationOPKl7x3dpeMeta?.alias || [],
    redirect: confirmationOPKl7x3dpeMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/pay/[trxId]/confirmation.vue").then(m => m.default || m)
  },
  {
    name: confirmationOPKl7x3dpeMeta?.name ?? "pay-trxId-confirmation___zh-tw",
    path: confirmationOPKl7x3dpeMeta?.path ?? "/zh-tw/pay/:trxId()/confirmation",
    meta: confirmationOPKl7x3dpeMeta || {},
    alias: confirmationOPKl7x3dpeMeta?.alias || [],
    redirect: confirmationOPKl7x3dpeMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/pay/[trxId]/confirmation.vue").then(m => m.default || m)
  },
  {
    name: confirmationOPKl7x3dpeMeta?.name ?? "pay-trxId-confirmation___th",
    path: confirmationOPKl7x3dpeMeta?.path ?? "/th/pay/:trxId()/confirmation",
    meta: confirmationOPKl7x3dpeMeta || {},
    alias: confirmationOPKl7x3dpeMeta?.alias || [],
    redirect: confirmationOPKl7x3dpeMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/pay/[trxId]/confirmation.vue").then(m => m.default || m)
  },
  {
    name: confirmationOPKl7x3dpeMeta?.name ?? "pay-trxId-confirmation___ja",
    path: confirmationOPKl7x3dpeMeta?.path ?? "/ja/pay/:trxId()/confirmation",
    meta: confirmationOPKl7x3dpeMeta || {},
    alias: confirmationOPKl7x3dpeMeta?.alias || [],
    redirect: confirmationOPKl7x3dpeMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/pay/[trxId]/confirmation.vue").then(m => m.default || m)
  },
  {
    name: confirmationOPKl7x3dpeMeta?.name ?? "pay-trxId-confirmation___ms",
    path: confirmationOPKl7x3dpeMeta?.path ?? "/ms/pay/:trxId()/confirmation",
    meta: confirmationOPKl7x3dpeMeta || {},
    alias: confirmationOPKl7x3dpeMeta?.alias || [],
    redirect: confirmationOPKl7x3dpeMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/pay/[trxId]/confirmation.vue").then(m => m.default || m)
  },
  {
    name: confirmationOPKl7x3dpeMeta?.name ?? "pay-trxId-confirmation___ko",
    path: confirmationOPKl7x3dpeMeta?.path ?? "/ko/pay/:trxId()/confirmation",
    meta: confirmationOPKl7x3dpeMeta || {},
    alias: confirmationOPKl7x3dpeMeta?.alias || [],
    redirect: confirmationOPKl7x3dpeMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/pay/[trxId]/confirmation.vue").then(m => m.default || m)
  },
  {
    name: confirmationOPKl7x3dpeMeta?.name ?? "pay-trxId-confirmation___vi",
    path: confirmationOPKl7x3dpeMeta?.path ?? "/vi/pay/:trxId()/confirmation",
    meta: confirmationOPKl7x3dpeMeta || {},
    alias: confirmationOPKl7x3dpeMeta?.alias || [],
    redirect: confirmationOPKl7x3dpeMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/pay/[trxId]/confirmation.vue").then(m => m.default || m)
  },
  {
    name: confirmationOPKl7x3dpeMeta?.name ?? "pay-trxId-confirmation___it",
    path: confirmationOPKl7x3dpeMeta?.path ?? "/it/pay/:trxId()/confirmation",
    meta: confirmationOPKl7x3dpeMeta || {},
    alias: confirmationOPKl7x3dpeMeta?.alias || [],
    redirect: confirmationOPKl7x3dpeMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/pay/[trxId]/confirmation.vue").then(m => m.default || m)
  },
  {
    name: confirmationOPKl7x3dpeMeta?.name ?? "pay-trxId-confirmation___de",
    path: confirmationOPKl7x3dpeMeta?.path ?? "/de/pay/:trxId()/confirmation",
    meta: confirmationOPKl7x3dpeMeta || {},
    alias: confirmationOPKl7x3dpeMeta?.alias || [],
    redirect: confirmationOPKl7x3dpeMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/pay/[trxId]/confirmation.vue").then(m => m.default || m)
  },
  {
    name: confirmationOPKl7x3dpeMeta?.name ?? "pay-trxId-confirmation___es",
    path: confirmationOPKl7x3dpeMeta?.path ?? "/es/pay/:trxId()/confirmation",
    meta: confirmationOPKl7x3dpeMeta || {},
    alias: confirmationOPKl7x3dpeMeta?.alias || [],
    redirect: confirmationOPKl7x3dpeMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/pay/[trxId]/confirmation.vue").then(m => m.default || m)
  },
  {
    name: confirmationOPKl7x3dpeMeta?.name ?? "pay-trxId-confirmation___hi",
    path: confirmationOPKl7x3dpeMeta?.path ?? "/hi/pay/:trxId()/confirmation",
    meta: confirmationOPKl7x3dpeMeta || {},
    alias: confirmationOPKl7x3dpeMeta?.alias || [],
    redirect: confirmationOPKl7x3dpeMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/pay/[trxId]/confirmation.vue").then(m => m.default || m)
  },
  {
    name: confirmationOPKl7x3dpeMeta?.name ?? "pay-trxId-confirmation___fr",
    path: confirmationOPKl7x3dpeMeta?.path ?? "/fr/pay/:trxId()/confirmation",
    meta: confirmationOPKl7x3dpeMeta || {},
    alias: confirmationOPKl7x3dpeMeta?.alias || [],
    redirect: confirmationOPKl7x3dpeMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/pay/[trxId]/confirmation.vue").then(m => m.default || m)
  },
  {
    name: confirmationOPKl7x3dpeMeta?.name ?? "pay-trxId-confirmation___ru",
    path: confirmationOPKl7x3dpeMeta?.path ?? "/ru/pay/:trxId()/confirmation",
    meta: confirmationOPKl7x3dpeMeta || {},
    alias: confirmationOPKl7x3dpeMeta?.alias || [],
    redirect: confirmationOPKl7x3dpeMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/pay/[trxId]/confirmation.vue").then(m => m.default || m)
  },
  {
    name: confirmationOPKl7x3dpeMeta?.name ?? "pay-trxId-confirmation___bn",
    path: confirmationOPKl7x3dpeMeta?.path ?? "/bn/pay/:trxId()/confirmation",
    meta: confirmationOPKl7x3dpeMeta || {},
    alias: confirmationOPKl7x3dpeMeta?.alias || [],
    redirect: confirmationOPKl7x3dpeMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/pay/[trxId]/confirmation.vue").then(m => m.default || m)
  },
  {
    name: confirmationOPKl7x3dpeMeta?.name ?? "pay-trxId-confirmation___pt",
    path: confirmationOPKl7x3dpeMeta?.path ?? "/pt/pay/:trxId()/confirmation",
    meta: confirmationOPKl7x3dpeMeta || {},
    alias: confirmationOPKl7x3dpeMeta?.alias || [],
    redirect: confirmationOPKl7x3dpeMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/pay/[trxId]/confirmation.vue").then(m => m.default || m)
  },
  {
    name: confirmationOPKl7x3dpeMeta?.name ?? "pay-trxId-confirmation___km",
    path: confirmationOPKl7x3dpeMeta?.path ?? "/km/pay/:trxId()/confirmation",
    meta: confirmationOPKl7x3dpeMeta || {},
    alias: confirmationOPKl7x3dpeMeta?.alias || [],
    redirect: confirmationOPKl7x3dpeMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/pay/[trxId]/confirmation.vue").then(m => m.default || m)
  },
  {
    name: index45SXYRSvQJMeta?.name ?? "promo___en",
    path: index45SXYRSvQJMeta?.path ?? "/en/promo",
    meta: index45SXYRSvQJMeta || {},
    alias: index45SXYRSvQJMeta?.alias || [],
    redirect: index45SXYRSvQJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/promo/index.vue").then(m => m.default || m)
  },
  {
    name: index45SXYRSvQJMeta?.name ?? "promo___id",
    path: index45SXYRSvQJMeta?.path ?? "/id/promo",
    meta: index45SXYRSvQJMeta || {},
    alias: index45SXYRSvQJMeta?.alias || [],
    redirect: index45SXYRSvQJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/promo/index.vue").then(m => m.default || m)
  },
  {
    name: index45SXYRSvQJMeta?.name ?? "promo___tl",
    path: index45SXYRSvQJMeta?.path ?? "/tl/promo",
    meta: index45SXYRSvQJMeta || {},
    alias: index45SXYRSvQJMeta?.alias || [],
    redirect: index45SXYRSvQJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/promo/index.vue").then(m => m.default || m)
  },
  {
    name: index45SXYRSvQJMeta?.name ?? "promo___zh",
    path: index45SXYRSvQJMeta?.path ?? "/zh/promo",
    meta: index45SXYRSvQJMeta || {},
    alias: index45SXYRSvQJMeta?.alias || [],
    redirect: index45SXYRSvQJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/promo/index.vue").then(m => m.default || m)
  },
  {
    name: index45SXYRSvQJMeta?.name ?? "promo___zh-tw",
    path: index45SXYRSvQJMeta?.path ?? "/zh-tw/promo",
    meta: index45SXYRSvQJMeta || {},
    alias: index45SXYRSvQJMeta?.alias || [],
    redirect: index45SXYRSvQJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/promo/index.vue").then(m => m.default || m)
  },
  {
    name: index45SXYRSvQJMeta?.name ?? "promo___th",
    path: index45SXYRSvQJMeta?.path ?? "/th/promo",
    meta: index45SXYRSvQJMeta || {},
    alias: index45SXYRSvQJMeta?.alias || [],
    redirect: index45SXYRSvQJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/promo/index.vue").then(m => m.default || m)
  },
  {
    name: index45SXYRSvQJMeta?.name ?? "promo___ja",
    path: index45SXYRSvQJMeta?.path ?? "/ja/promo",
    meta: index45SXYRSvQJMeta || {},
    alias: index45SXYRSvQJMeta?.alias || [],
    redirect: index45SXYRSvQJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/promo/index.vue").then(m => m.default || m)
  },
  {
    name: index45SXYRSvQJMeta?.name ?? "promo___ms",
    path: index45SXYRSvQJMeta?.path ?? "/ms/promo",
    meta: index45SXYRSvQJMeta || {},
    alias: index45SXYRSvQJMeta?.alias || [],
    redirect: index45SXYRSvQJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/promo/index.vue").then(m => m.default || m)
  },
  {
    name: index45SXYRSvQJMeta?.name ?? "promo___ko",
    path: index45SXYRSvQJMeta?.path ?? "/ko/promo",
    meta: index45SXYRSvQJMeta || {},
    alias: index45SXYRSvQJMeta?.alias || [],
    redirect: index45SXYRSvQJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/promo/index.vue").then(m => m.default || m)
  },
  {
    name: index45SXYRSvQJMeta?.name ?? "promo___vi",
    path: index45SXYRSvQJMeta?.path ?? "/vi/promo",
    meta: index45SXYRSvQJMeta || {},
    alias: index45SXYRSvQJMeta?.alias || [],
    redirect: index45SXYRSvQJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/promo/index.vue").then(m => m.default || m)
  },
  {
    name: index45SXYRSvQJMeta?.name ?? "promo___it",
    path: index45SXYRSvQJMeta?.path ?? "/it/promo",
    meta: index45SXYRSvQJMeta || {},
    alias: index45SXYRSvQJMeta?.alias || [],
    redirect: index45SXYRSvQJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/promo/index.vue").then(m => m.default || m)
  },
  {
    name: index45SXYRSvQJMeta?.name ?? "promo___de",
    path: index45SXYRSvQJMeta?.path ?? "/de/promo",
    meta: index45SXYRSvQJMeta || {},
    alias: index45SXYRSvQJMeta?.alias || [],
    redirect: index45SXYRSvQJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/promo/index.vue").then(m => m.default || m)
  },
  {
    name: index45SXYRSvQJMeta?.name ?? "promo___es",
    path: index45SXYRSvQJMeta?.path ?? "/es/promo",
    meta: index45SXYRSvQJMeta || {},
    alias: index45SXYRSvQJMeta?.alias || [],
    redirect: index45SXYRSvQJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/promo/index.vue").then(m => m.default || m)
  },
  {
    name: index45SXYRSvQJMeta?.name ?? "promo___hi",
    path: index45SXYRSvQJMeta?.path ?? "/hi/promo",
    meta: index45SXYRSvQJMeta || {},
    alias: index45SXYRSvQJMeta?.alias || [],
    redirect: index45SXYRSvQJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/promo/index.vue").then(m => m.default || m)
  },
  {
    name: index45SXYRSvQJMeta?.name ?? "promo___fr",
    path: index45SXYRSvQJMeta?.path ?? "/fr/promo",
    meta: index45SXYRSvQJMeta || {},
    alias: index45SXYRSvQJMeta?.alias || [],
    redirect: index45SXYRSvQJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/promo/index.vue").then(m => m.default || m)
  },
  {
    name: index45SXYRSvQJMeta?.name ?? "promo___ru",
    path: index45SXYRSvQJMeta?.path ?? "/ru/promo",
    meta: index45SXYRSvQJMeta || {},
    alias: index45SXYRSvQJMeta?.alias || [],
    redirect: index45SXYRSvQJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/promo/index.vue").then(m => m.default || m)
  },
  {
    name: index45SXYRSvQJMeta?.name ?? "promo___bn",
    path: index45SXYRSvQJMeta?.path ?? "/bn/promo",
    meta: index45SXYRSvQJMeta || {},
    alias: index45SXYRSvQJMeta?.alias || [],
    redirect: index45SXYRSvQJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/promo/index.vue").then(m => m.default || m)
  },
  {
    name: index45SXYRSvQJMeta?.name ?? "promo___pt",
    path: index45SXYRSvQJMeta?.path ?? "/pt/promo",
    meta: index45SXYRSvQJMeta || {},
    alias: index45SXYRSvQJMeta?.alias || [],
    redirect: index45SXYRSvQJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/promo/index.vue").then(m => m.default || m)
  },
  {
    name: index45SXYRSvQJMeta?.name ?? "promo___km",
    path: index45SXYRSvQJMeta?.path ?? "/km/promo",
    meta: index45SXYRSvQJMeta || {},
    alias: index45SXYRSvQJMeta?.alias || [],
    redirect: index45SXYRSvQJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/promo/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZdlPfNpBvoMeta?.name ?? "promo-view-slug___en",
    path: _91slug_93ZdlPfNpBvoMeta?.path ?? "/en/promo/view/:slug()",
    meta: _91slug_93ZdlPfNpBvoMeta || {},
    alias: _91slug_93ZdlPfNpBvoMeta?.alias || [],
    redirect: _91slug_93ZdlPfNpBvoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/promo/view/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZdlPfNpBvoMeta?.name ?? "promo-view-slug___id",
    path: _91slug_93ZdlPfNpBvoMeta?.path ?? "/id/promo/view/:slug()",
    meta: _91slug_93ZdlPfNpBvoMeta || {},
    alias: _91slug_93ZdlPfNpBvoMeta?.alias || [],
    redirect: _91slug_93ZdlPfNpBvoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/promo/view/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZdlPfNpBvoMeta?.name ?? "promo-view-slug___tl",
    path: _91slug_93ZdlPfNpBvoMeta?.path ?? "/tl/promo/view/:slug()",
    meta: _91slug_93ZdlPfNpBvoMeta || {},
    alias: _91slug_93ZdlPfNpBvoMeta?.alias || [],
    redirect: _91slug_93ZdlPfNpBvoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/promo/view/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZdlPfNpBvoMeta?.name ?? "promo-view-slug___zh",
    path: _91slug_93ZdlPfNpBvoMeta?.path ?? "/zh/promo/view/:slug()",
    meta: _91slug_93ZdlPfNpBvoMeta || {},
    alias: _91slug_93ZdlPfNpBvoMeta?.alias || [],
    redirect: _91slug_93ZdlPfNpBvoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/promo/view/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZdlPfNpBvoMeta?.name ?? "promo-view-slug___zh-tw",
    path: _91slug_93ZdlPfNpBvoMeta?.path ?? "/zh-tw/promo/view/:slug()",
    meta: _91slug_93ZdlPfNpBvoMeta || {},
    alias: _91slug_93ZdlPfNpBvoMeta?.alias || [],
    redirect: _91slug_93ZdlPfNpBvoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/promo/view/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZdlPfNpBvoMeta?.name ?? "promo-view-slug___th",
    path: _91slug_93ZdlPfNpBvoMeta?.path ?? "/th/promo/view/:slug()",
    meta: _91slug_93ZdlPfNpBvoMeta || {},
    alias: _91slug_93ZdlPfNpBvoMeta?.alias || [],
    redirect: _91slug_93ZdlPfNpBvoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/promo/view/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZdlPfNpBvoMeta?.name ?? "promo-view-slug___ja",
    path: _91slug_93ZdlPfNpBvoMeta?.path ?? "/ja/promo/view/:slug()",
    meta: _91slug_93ZdlPfNpBvoMeta || {},
    alias: _91slug_93ZdlPfNpBvoMeta?.alias || [],
    redirect: _91slug_93ZdlPfNpBvoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/promo/view/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZdlPfNpBvoMeta?.name ?? "promo-view-slug___ms",
    path: _91slug_93ZdlPfNpBvoMeta?.path ?? "/ms/promo/view/:slug()",
    meta: _91slug_93ZdlPfNpBvoMeta || {},
    alias: _91slug_93ZdlPfNpBvoMeta?.alias || [],
    redirect: _91slug_93ZdlPfNpBvoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/promo/view/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZdlPfNpBvoMeta?.name ?? "promo-view-slug___ko",
    path: _91slug_93ZdlPfNpBvoMeta?.path ?? "/ko/promo/view/:slug()",
    meta: _91slug_93ZdlPfNpBvoMeta || {},
    alias: _91slug_93ZdlPfNpBvoMeta?.alias || [],
    redirect: _91slug_93ZdlPfNpBvoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/promo/view/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZdlPfNpBvoMeta?.name ?? "promo-view-slug___vi",
    path: _91slug_93ZdlPfNpBvoMeta?.path ?? "/vi/promo/view/:slug()",
    meta: _91slug_93ZdlPfNpBvoMeta || {},
    alias: _91slug_93ZdlPfNpBvoMeta?.alias || [],
    redirect: _91slug_93ZdlPfNpBvoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/promo/view/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZdlPfNpBvoMeta?.name ?? "promo-view-slug___it",
    path: _91slug_93ZdlPfNpBvoMeta?.path ?? "/it/promo/view/:slug()",
    meta: _91slug_93ZdlPfNpBvoMeta || {},
    alias: _91slug_93ZdlPfNpBvoMeta?.alias || [],
    redirect: _91slug_93ZdlPfNpBvoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/promo/view/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZdlPfNpBvoMeta?.name ?? "promo-view-slug___de",
    path: _91slug_93ZdlPfNpBvoMeta?.path ?? "/de/promo/view/:slug()",
    meta: _91slug_93ZdlPfNpBvoMeta || {},
    alias: _91slug_93ZdlPfNpBvoMeta?.alias || [],
    redirect: _91slug_93ZdlPfNpBvoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/promo/view/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZdlPfNpBvoMeta?.name ?? "promo-view-slug___es",
    path: _91slug_93ZdlPfNpBvoMeta?.path ?? "/es/promo/view/:slug()",
    meta: _91slug_93ZdlPfNpBvoMeta || {},
    alias: _91slug_93ZdlPfNpBvoMeta?.alias || [],
    redirect: _91slug_93ZdlPfNpBvoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/promo/view/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZdlPfNpBvoMeta?.name ?? "promo-view-slug___hi",
    path: _91slug_93ZdlPfNpBvoMeta?.path ?? "/hi/promo/view/:slug()",
    meta: _91slug_93ZdlPfNpBvoMeta || {},
    alias: _91slug_93ZdlPfNpBvoMeta?.alias || [],
    redirect: _91slug_93ZdlPfNpBvoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/promo/view/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZdlPfNpBvoMeta?.name ?? "promo-view-slug___fr",
    path: _91slug_93ZdlPfNpBvoMeta?.path ?? "/fr/promo/view/:slug()",
    meta: _91slug_93ZdlPfNpBvoMeta || {},
    alias: _91slug_93ZdlPfNpBvoMeta?.alias || [],
    redirect: _91slug_93ZdlPfNpBvoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/promo/view/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZdlPfNpBvoMeta?.name ?? "promo-view-slug___ru",
    path: _91slug_93ZdlPfNpBvoMeta?.path ?? "/ru/promo/view/:slug()",
    meta: _91slug_93ZdlPfNpBvoMeta || {},
    alias: _91slug_93ZdlPfNpBvoMeta?.alias || [],
    redirect: _91slug_93ZdlPfNpBvoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/promo/view/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZdlPfNpBvoMeta?.name ?? "promo-view-slug___bn",
    path: _91slug_93ZdlPfNpBvoMeta?.path ?? "/bn/promo/view/:slug()",
    meta: _91slug_93ZdlPfNpBvoMeta || {},
    alias: _91slug_93ZdlPfNpBvoMeta?.alias || [],
    redirect: _91slug_93ZdlPfNpBvoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/promo/view/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZdlPfNpBvoMeta?.name ?? "promo-view-slug___pt",
    path: _91slug_93ZdlPfNpBvoMeta?.path ?? "/pt/promo/view/:slug()",
    meta: _91slug_93ZdlPfNpBvoMeta || {},
    alias: _91slug_93ZdlPfNpBvoMeta?.alias || [],
    redirect: _91slug_93ZdlPfNpBvoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/promo/view/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZdlPfNpBvoMeta?.name ?? "promo-view-slug___km",
    path: _91slug_93ZdlPfNpBvoMeta?.path ?? "/km/promo/view/:slug()",
    meta: _91slug_93ZdlPfNpBvoMeta || {},
    alias: _91slug_93ZdlPfNpBvoMeta?.alias || [],
    redirect: _91slug_93ZdlPfNpBvoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/promo/view/[slug].vue").then(m => m.default || m)
  },
  {
    name: special_45dealsnsdu79FbGBMeta?.name ?? "promo-view-special-deals___en",
    path: special_45dealsnsdu79FbGBMeta?.path ?? "/en/promo/view/special-deals",
    meta: special_45dealsnsdu79FbGBMeta || {},
    alias: special_45dealsnsdu79FbGBMeta?.alias || [],
    redirect: special_45dealsnsdu79FbGBMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/promo/view/special-deals.vue").then(m => m.default || m)
  },
  {
    name: special_45dealsnsdu79FbGBMeta?.name ?? "promo-view-special-deals___id",
    path: special_45dealsnsdu79FbGBMeta?.path ?? "/id/promo/view/special-deals",
    meta: special_45dealsnsdu79FbGBMeta || {},
    alias: special_45dealsnsdu79FbGBMeta?.alias || [],
    redirect: special_45dealsnsdu79FbGBMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/promo/view/special-deals.vue").then(m => m.default || m)
  },
  {
    name: special_45dealsnsdu79FbGBMeta?.name ?? "promo-view-special-deals___tl",
    path: special_45dealsnsdu79FbGBMeta?.path ?? "/tl/promo/view/special-deals",
    meta: special_45dealsnsdu79FbGBMeta || {},
    alias: special_45dealsnsdu79FbGBMeta?.alias || [],
    redirect: special_45dealsnsdu79FbGBMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/promo/view/special-deals.vue").then(m => m.default || m)
  },
  {
    name: special_45dealsnsdu79FbGBMeta?.name ?? "promo-view-special-deals___zh",
    path: special_45dealsnsdu79FbGBMeta?.path ?? "/zh/promo/view/special-deals",
    meta: special_45dealsnsdu79FbGBMeta || {},
    alias: special_45dealsnsdu79FbGBMeta?.alias || [],
    redirect: special_45dealsnsdu79FbGBMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/promo/view/special-deals.vue").then(m => m.default || m)
  },
  {
    name: special_45dealsnsdu79FbGBMeta?.name ?? "promo-view-special-deals___zh-tw",
    path: special_45dealsnsdu79FbGBMeta?.path ?? "/zh-tw/promo/view/special-deals",
    meta: special_45dealsnsdu79FbGBMeta || {},
    alias: special_45dealsnsdu79FbGBMeta?.alias || [],
    redirect: special_45dealsnsdu79FbGBMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/promo/view/special-deals.vue").then(m => m.default || m)
  },
  {
    name: special_45dealsnsdu79FbGBMeta?.name ?? "promo-view-special-deals___th",
    path: special_45dealsnsdu79FbGBMeta?.path ?? "/th/promo/view/special-deals",
    meta: special_45dealsnsdu79FbGBMeta || {},
    alias: special_45dealsnsdu79FbGBMeta?.alias || [],
    redirect: special_45dealsnsdu79FbGBMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/promo/view/special-deals.vue").then(m => m.default || m)
  },
  {
    name: special_45dealsnsdu79FbGBMeta?.name ?? "promo-view-special-deals___ja",
    path: special_45dealsnsdu79FbGBMeta?.path ?? "/ja/promo/view/special-deals",
    meta: special_45dealsnsdu79FbGBMeta || {},
    alias: special_45dealsnsdu79FbGBMeta?.alias || [],
    redirect: special_45dealsnsdu79FbGBMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/promo/view/special-deals.vue").then(m => m.default || m)
  },
  {
    name: special_45dealsnsdu79FbGBMeta?.name ?? "promo-view-special-deals___ms",
    path: special_45dealsnsdu79FbGBMeta?.path ?? "/ms/promo/view/special-deals",
    meta: special_45dealsnsdu79FbGBMeta || {},
    alias: special_45dealsnsdu79FbGBMeta?.alias || [],
    redirect: special_45dealsnsdu79FbGBMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/promo/view/special-deals.vue").then(m => m.default || m)
  },
  {
    name: special_45dealsnsdu79FbGBMeta?.name ?? "promo-view-special-deals___ko",
    path: special_45dealsnsdu79FbGBMeta?.path ?? "/ko/promo/view/special-deals",
    meta: special_45dealsnsdu79FbGBMeta || {},
    alias: special_45dealsnsdu79FbGBMeta?.alias || [],
    redirect: special_45dealsnsdu79FbGBMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/promo/view/special-deals.vue").then(m => m.default || m)
  },
  {
    name: special_45dealsnsdu79FbGBMeta?.name ?? "promo-view-special-deals___vi",
    path: special_45dealsnsdu79FbGBMeta?.path ?? "/vi/promo/view/special-deals",
    meta: special_45dealsnsdu79FbGBMeta || {},
    alias: special_45dealsnsdu79FbGBMeta?.alias || [],
    redirect: special_45dealsnsdu79FbGBMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/promo/view/special-deals.vue").then(m => m.default || m)
  },
  {
    name: special_45dealsnsdu79FbGBMeta?.name ?? "promo-view-special-deals___it",
    path: special_45dealsnsdu79FbGBMeta?.path ?? "/it/promo/view/special-deals",
    meta: special_45dealsnsdu79FbGBMeta || {},
    alias: special_45dealsnsdu79FbGBMeta?.alias || [],
    redirect: special_45dealsnsdu79FbGBMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/promo/view/special-deals.vue").then(m => m.default || m)
  },
  {
    name: special_45dealsnsdu79FbGBMeta?.name ?? "promo-view-special-deals___de",
    path: special_45dealsnsdu79FbGBMeta?.path ?? "/de/promo/view/special-deals",
    meta: special_45dealsnsdu79FbGBMeta || {},
    alias: special_45dealsnsdu79FbGBMeta?.alias || [],
    redirect: special_45dealsnsdu79FbGBMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/promo/view/special-deals.vue").then(m => m.default || m)
  },
  {
    name: special_45dealsnsdu79FbGBMeta?.name ?? "promo-view-special-deals___es",
    path: special_45dealsnsdu79FbGBMeta?.path ?? "/es/promo/view/special-deals",
    meta: special_45dealsnsdu79FbGBMeta || {},
    alias: special_45dealsnsdu79FbGBMeta?.alias || [],
    redirect: special_45dealsnsdu79FbGBMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/promo/view/special-deals.vue").then(m => m.default || m)
  },
  {
    name: special_45dealsnsdu79FbGBMeta?.name ?? "promo-view-special-deals___hi",
    path: special_45dealsnsdu79FbGBMeta?.path ?? "/hi/promo/view/special-deals",
    meta: special_45dealsnsdu79FbGBMeta || {},
    alias: special_45dealsnsdu79FbGBMeta?.alias || [],
    redirect: special_45dealsnsdu79FbGBMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/promo/view/special-deals.vue").then(m => m.default || m)
  },
  {
    name: special_45dealsnsdu79FbGBMeta?.name ?? "promo-view-special-deals___fr",
    path: special_45dealsnsdu79FbGBMeta?.path ?? "/fr/promo/view/special-deals",
    meta: special_45dealsnsdu79FbGBMeta || {},
    alias: special_45dealsnsdu79FbGBMeta?.alias || [],
    redirect: special_45dealsnsdu79FbGBMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/promo/view/special-deals.vue").then(m => m.default || m)
  },
  {
    name: special_45dealsnsdu79FbGBMeta?.name ?? "promo-view-special-deals___ru",
    path: special_45dealsnsdu79FbGBMeta?.path ?? "/ru/promo/view/special-deals",
    meta: special_45dealsnsdu79FbGBMeta || {},
    alias: special_45dealsnsdu79FbGBMeta?.alias || [],
    redirect: special_45dealsnsdu79FbGBMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/promo/view/special-deals.vue").then(m => m.default || m)
  },
  {
    name: special_45dealsnsdu79FbGBMeta?.name ?? "promo-view-special-deals___bn",
    path: special_45dealsnsdu79FbGBMeta?.path ?? "/bn/promo/view/special-deals",
    meta: special_45dealsnsdu79FbGBMeta || {},
    alias: special_45dealsnsdu79FbGBMeta?.alias || [],
    redirect: special_45dealsnsdu79FbGBMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/promo/view/special-deals.vue").then(m => m.default || m)
  },
  {
    name: special_45dealsnsdu79FbGBMeta?.name ?? "promo-view-special-deals___pt",
    path: special_45dealsnsdu79FbGBMeta?.path ?? "/pt/promo/view/special-deals",
    meta: special_45dealsnsdu79FbGBMeta || {},
    alias: special_45dealsnsdu79FbGBMeta?.alias || [],
    redirect: special_45dealsnsdu79FbGBMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/promo/view/special-deals.vue").then(m => m.default || m)
  },
  {
    name: special_45dealsnsdu79FbGBMeta?.name ?? "promo-view-special-deals___km",
    path: special_45dealsnsdu79FbGBMeta?.path ?? "/km/promo/view/special-deals",
    meta: special_45dealsnsdu79FbGBMeta || {},
    alias: special_45dealsnsdu79FbGBMeta?.alias || [],
    redirect: special_45dealsnsdu79FbGBMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/promo/view/special-deals.vue").then(m => m.default || m)
  },
  {
    name: indexc5deQbmapLMeta?.name ?? "reset___en",
    path: indexc5deQbmapLMeta?.path ?? "/en/reset",
    meta: indexc5deQbmapLMeta || {},
    alias: indexc5deQbmapLMeta?.alias || [],
    redirect: indexc5deQbmapLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/reset/index.vue").then(m => m.default || m)
  },
  {
    name: indexc5deQbmapLMeta?.name ?? "reset___id",
    path: indexc5deQbmapLMeta?.path ?? "/id/reset",
    meta: indexc5deQbmapLMeta || {},
    alias: indexc5deQbmapLMeta?.alias || [],
    redirect: indexc5deQbmapLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/reset/index.vue").then(m => m.default || m)
  },
  {
    name: indexc5deQbmapLMeta?.name ?? "reset___tl",
    path: indexc5deQbmapLMeta?.path ?? "/tl/reset",
    meta: indexc5deQbmapLMeta || {},
    alias: indexc5deQbmapLMeta?.alias || [],
    redirect: indexc5deQbmapLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/reset/index.vue").then(m => m.default || m)
  },
  {
    name: indexc5deQbmapLMeta?.name ?? "reset___zh",
    path: indexc5deQbmapLMeta?.path ?? "/zh/reset",
    meta: indexc5deQbmapLMeta || {},
    alias: indexc5deQbmapLMeta?.alias || [],
    redirect: indexc5deQbmapLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/reset/index.vue").then(m => m.default || m)
  },
  {
    name: indexc5deQbmapLMeta?.name ?? "reset___zh-tw",
    path: indexc5deQbmapLMeta?.path ?? "/zh-tw/reset",
    meta: indexc5deQbmapLMeta || {},
    alias: indexc5deQbmapLMeta?.alias || [],
    redirect: indexc5deQbmapLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/reset/index.vue").then(m => m.default || m)
  },
  {
    name: indexc5deQbmapLMeta?.name ?? "reset___th",
    path: indexc5deQbmapLMeta?.path ?? "/th/reset",
    meta: indexc5deQbmapLMeta || {},
    alias: indexc5deQbmapLMeta?.alias || [],
    redirect: indexc5deQbmapLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/reset/index.vue").then(m => m.default || m)
  },
  {
    name: indexc5deQbmapLMeta?.name ?? "reset___ja",
    path: indexc5deQbmapLMeta?.path ?? "/ja/reset",
    meta: indexc5deQbmapLMeta || {},
    alias: indexc5deQbmapLMeta?.alias || [],
    redirect: indexc5deQbmapLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/reset/index.vue").then(m => m.default || m)
  },
  {
    name: indexc5deQbmapLMeta?.name ?? "reset___ms",
    path: indexc5deQbmapLMeta?.path ?? "/ms/reset",
    meta: indexc5deQbmapLMeta || {},
    alias: indexc5deQbmapLMeta?.alias || [],
    redirect: indexc5deQbmapLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/reset/index.vue").then(m => m.default || m)
  },
  {
    name: indexc5deQbmapLMeta?.name ?? "reset___ko",
    path: indexc5deQbmapLMeta?.path ?? "/ko/reset",
    meta: indexc5deQbmapLMeta || {},
    alias: indexc5deQbmapLMeta?.alias || [],
    redirect: indexc5deQbmapLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/reset/index.vue").then(m => m.default || m)
  },
  {
    name: indexc5deQbmapLMeta?.name ?? "reset___vi",
    path: indexc5deQbmapLMeta?.path ?? "/vi/reset",
    meta: indexc5deQbmapLMeta || {},
    alias: indexc5deQbmapLMeta?.alias || [],
    redirect: indexc5deQbmapLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/reset/index.vue").then(m => m.default || m)
  },
  {
    name: indexc5deQbmapLMeta?.name ?? "reset___it",
    path: indexc5deQbmapLMeta?.path ?? "/it/reset",
    meta: indexc5deQbmapLMeta || {},
    alias: indexc5deQbmapLMeta?.alias || [],
    redirect: indexc5deQbmapLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/reset/index.vue").then(m => m.default || m)
  },
  {
    name: indexc5deQbmapLMeta?.name ?? "reset___de",
    path: indexc5deQbmapLMeta?.path ?? "/de/reset",
    meta: indexc5deQbmapLMeta || {},
    alias: indexc5deQbmapLMeta?.alias || [],
    redirect: indexc5deQbmapLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/reset/index.vue").then(m => m.default || m)
  },
  {
    name: indexc5deQbmapLMeta?.name ?? "reset___es",
    path: indexc5deQbmapLMeta?.path ?? "/es/reset",
    meta: indexc5deQbmapLMeta || {},
    alias: indexc5deQbmapLMeta?.alias || [],
    redirect: indexc5deQbmapLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/reset/index.vue").then(m => m.default || m)
  },
  {
    name: indexc5deQbmapLMeta?.name ?? "reset___hi",
    path: indexc5deQbmapLMeta?.path ?? "/hi/reset",
    meta: indexc5deQbmapLMeta || {},
    alias: indexc5deQbmapLMeta?.alias || [],
    redirect: indexc5deQbmapLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/reset/index.vue").then(m => m.default || m)
  },
  {
    name: indexc5deQbmapLMeta?.name ?? "reset___fr",
    path: indexc5deQbmapLMeta?.path ?? "/fr/reset",
    meta: indexc5deQbmapLMeta || {},
    alias: indexc5deQbmapLMeta?.alias || [],
    redirect: indexc5deQbmapLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/reset/index.vue").then(m => m.default || m)
  },
  {
    name: indexc5deQbmapLMeta?.name ?? "reset___ru",
    path: indexc5deQbmapLMeta?.path ?? "/ru/reset",
    meta: indexc5deQbmapLMeta || {},
    alias: indexc5deQbmapLMeta?.alias || [],
    redirect: indexc5deQbmapLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/reset/index.vue").then(m => m.default || m)
  },
  {
    name: indexc5deQbmapLMeta?.name ?? "reset___bn",
    path: indexc5deQbmapLMeta?.path ?? "/bn/reset",
    meta: indexc5deQbmapLMeta || {},
    alias: indexc5deQbmapLMeta?.alias || [],
    redirect: indexc5deQbmapLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/reset/index.vue").then(m => m.default || m)
  },
  {
    name: indexc5deQbmapLMeta?.name ?? "reset___pt",
    path: indexc5deQbmapLMeta?.path ?? "/pt/reset",
    meta: indexc5deQbmapLMeta || {},
    alias: indexc5deQbmapLMeta?.alias || [],
    redirect: indexc5deQbmapLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/reset/index.vue").then(m => m.default || m)
  },
  {
    name: indexc5deQbmapLMeta?.name ?? "reset___km",
    path: indexc5deQbmapLMeta?.path ?? "/km/reset",
    meta: indexc5deQbmapLMeta || {},
    alias: indexc5deQbmapLMeta?.alias || [],
    redirect: indexc5deQbmapLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/reset/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93d32fzd0qB7Meta?.name ?? "reset-pass-id___en",
    path: _91id_93d32fzd0qB7Meta?.path ?? "/en/reset/pass/:id()",
    meta: _91id_93d32fzd0qB7Meta || {},
    alias: _91id_93d32fzd0qB7Meta?.alias || [],
    redirect: _91id_93d32fzd0qB7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/reset/pass/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93d32fzd0qB7Meta?.name ?? "reset-pass-id___id",
    path: _91id_93d32fzd0qB7Meta?.path ?? "/id/reset/pass/:id()",
    meta: _91id_93d32fzd0qB7Meta || {},
    alias: _91id_93d32fzd0qB7Meta?.alias || [],
    redirect: _91id_93d32fzd0qB7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/reset/pass/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93d32fzd0qB7Meta?.name ?? "reset-pass-id___tl",
    path: _91id_93d32fzd0qB7Meta?.path ?? "/tl/reset/pass/:id()",
    meta: _91id_93d32fzd0qB7Meta || {},
    alias: _91id_93d32fzd0qB7Meta?.alias || [],
    redirect: _91id_93d32fzd0qB7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/reset/pass/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93d32fzd0qB7Meta?.name ?? "reset-pass-id___zh",
    path: _91id_93d32fzd0qB7Meta?.path ?? "/zh/reset/pass/:id()",
    meta: _91id_93d32fzd0qB7Meta || {},
    alias: _91id_93d32fzd0qB7Meta?.alias || [],
    redirect: _91id_93d32fzd0qB7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/reset/pass/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93d32fzd0qB7Meta?.name ?? "reset-pass-id___zh-tw",
    path: _91id_93d32fzd0qB7Meta?.path ?? "/zh-tw/reset/pass/:id()",
    meta: _91id_93d32fzd0qB7Meta || {},
    alias: _91id_93d32fzd0qB7Meta?.alias || [],
    redirect: _91id_93d32fzd0qB7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/reset/pass/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93d32fzd0qB7Meta?.name ?? "reset-pass-id___th",
    path: _91id_93d32fzd0qB7Meta?.path ?? "/th/reset/pass/:id()",
    meta: _91id_93d32fzd0qB7Meta || {},
    alias: _91id_93d32fzd0qB7Meta?.alias || [],
    redirect: _91id_93d32fzd0qB7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/reset/pass/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93d32fzd0qB7Meta?.name ?? "reset-pass-id___ja",
    path: _91id_93d32fzd0qB7Meta?.path ?? "/ja/reset/pass/:id()",
    meta: _91id_93d32fzd0qB7Meta || {},
    alias: _91id_93d32fzd0qB7Meta?.alias || [],
    redirect: _91id_93d32fzd0qB7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/reset/pass/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93d32fzd0qB7Meta?.name ?? "reset-pass-id___ms",
    path: _91id_93d32fzd0qB7Meta?.path ?? "/ms/reset/pass/:id()",
    meta: _91id_93d32fzd0qB7Meta || {},
    alias: _91id_93d32fzd0qB7Meta?.alias || [],
    redirect: _91id_93d32fzd0qB7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/reset/pass/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93d32fzd0qB7Meta?.name ?? "reset-pass-id___ko",
    path: _91id_93d32fzd0qB7Meta?.path ?? "/ko/reset/pass/:id()",
    meta: _91id_93d32fzd0qB7Meta || {},
    alias: _91id_93d32fzd0qB7Meta?.alias || [],
    redirect: _91id_93d32fzd0qB7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/reset/pass/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93d32fzd0qB7Meta?.name ?? "reset-pass-id___vi",
    path: _91id_93d32fzd0qB7Meta?.path ?? "/vi/reset/pass/:id()",
    meta: _91id_93d32fzd0qB7Meta || {},
    alias: _91id_93d32fzd0qB7Meta?.alias || [],
    redirect: _91id_93d32fzd0qB7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/reset/pass/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93d32fzd0qB7Meta?.name ?? "reset-pass-id___it",
    path: _91id_93d32fzd0qB7Meta?.path ?? "/it/reset/pass/:id()",
    meta: _91id_93d32fzd0qB7Meta || {},
    alias: _91id_93d32fzd0qB7Meta?.alias || [],
    redirect: _91id_93d32fzd0qB7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/reset/pass/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93d32fzd0qB7Meta?.name ?? "reset-pass-id___de",
    path: _91id_93d32fzd0qB7Meta?.path ?? "/de/reset/pass/:id()",
    meta: _91id_93d32fzd0qB7Meta || {},
    alias: _91id_93d32fzd0qB7Meta?.alias || [],
    redirect: _91id_93d32fzd0qB7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/reset/pass/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93d32fzd0qB7Meta?.name ?? "reset-pass-id___es",
    path: _91id_93d32fzd0qB7Meta?.path ?? "/es/reset/pass/:id()",
    meta: _91id_93d32fzd0qB7Meta || {},
    alias: _91id_93d32fzd0qB7Meta?.alias || [],
    redirect: _91id_93d32fzd0qB7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/reset/pass/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93d32fzd0qB7Meta?.name ?? "reset-pass-id___hi",
    path: _91id_93d32fzd0qB7Meta?.path ?? "/hi/reset/pass/:id()",
    meta: _91id_93d32fzd0qB7Meta || {},
    alias: _91id_93d32fzd0qB7Meta?.alias || [],
    redirect: _91id_93d32fzd0qB7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/reset/pass/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93d32fzd0qB7Meta?.name ?? "reset-pass-id___fr",
    path: _91id_93d32fzd0qB7Meta?.path ?? "/fr/reset/pass/:id()",
    meta: _91id_93d32fzd0qB7Meta || {},
    alias: _91id_93d32fzd0qB7Meta?.alias || [],
    redirect: _91id_93d32fzd0qB7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/reset/pass/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93d32fzd0qB7Meta?.name ?? "reset-pass-id___ru",
    path: _91id_93d32fzd0qB7Meta?.path ?? "/ru/reset/pass/:id()",
    meta: _91id_93d32fzd0qB7Meta || {},
    alias: _91id_93d32fzd0qB7Meta?.alias || [],
    redirect: _91id_93d32fzd0qB7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/reset/pass/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93d32fzd0qB7Meta?.name ?? "reset-pass-id___bn",
    path: _91id_93d32fzd0qB7Meta?.path ?? "/bn/reset/pass/:id()",
    meta: _91id_93d32fzd0qB7Meta || {},
    alias: _91id_93d32fzd0qB7Meta?.alias || [],
    redirect: _91id_93d32fzd0qB7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/reset/pass/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93d32fzd0qB7Meta?.name ?? "reset-pass-id___pt",
    path: _91id_93d32fzd0qB7Meta?.path ?? "/pt/reset/pass/:id()",
    meta: _91id_93d32fzd0qB7Meta || {},
    alias: _91id_93d32fzd0qB7Meta?.alias || [],
    redirect: _91id_93d32fzd0qB7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/reset/pass/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93d32fzd0qB7Meta?.name ?? "reset-pass-id___km",
    path: _91id_93d32fzd0qB7Meta?.path ?? "/km/reset/pass/:id()",
    meta: _91id_93d32fzd0qB7Meta || {},
    alias: _91id_93d32fzd0qB7Meta?.alias || [],
    redirect: _91id_93d32fzd0qB7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/reset/pass/[id].vue").then(m => m.default || m)
  },
  {
    name: verifyb8gtl2X2mrMeta?.name ?? "reset-verify___en",
    path: verifyb8gtl2X2mrMeta?.path ?? "/en/reset/verify",
    meta: verifyb8gtl2X2mrMeta || {},
    alias: verifyb8gtl2X2mrMeta?.alias || [],
    redirect: verifyb8gtl2X2mrMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/reset/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyb8gtl2X2mrMeta?.name ?? "reset-verify___id",
    path: verifyb8gtl2X2mrMeta?.path ?? "/id/reset/verify",
    meta: verifyb8gtl2X2mrMeta || {},
    alias: verifyb8gtl2X2mrMeta?.alias || [],
    redirect: verifyb8gtl2X2mrMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/reset/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyb8gtl2X2mrMeta?.name ?? "reset-verify___tl",
    path: verifyb8gtl2X2mrMeta?.path ?? "/tl/reset/verify",
    meta: verifyb8gtl2X2mrMeta || {},
    alias: verifyb8gtl2X2mrMeta?.alias || [],
    redirect: verifyb8gtl2X2mrMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/reset/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyb8gtl2X2mrMeta?.name ?? "reset-verify___zh",
    path: verifyb8gtl2X2mrMeta?.path ?? "/zh/reset/verify",
    meta: verifyb8gtl2X2mrMeta || {},
    alias: verifyb8gtl2X2mrMeta?.alias || [],
    redirect: verifyb8gtl2X2mrMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/reset/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyb8gtl2X2mrMeta?.name ?? "reset-verify___zh-tw",
    path: verifyb8gtl2X2mrMeta?.path ?? "/zh-tw/reset/verify",
    meta: verifyb8gtl2X2mrMeta || {},
    alias: verifyb8gtl2X2mrMeta?.alias || [],
    redirect: verifyb8gtl2X2mrMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/reset/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyb8gtl2X2mrMeta?.name ?? "reset-verify___th",
    path: verifyb8gtl2X2mrMeta?.path ?? "/th/reset/verify",
    meta: verifyb8gtl2X2mrMeta || {},
    alias: verifyb8gtl2X2mrMeta?.alias || [],
    redirect: verifyb8gtl2X2mrMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/reset/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyb8gtl2X2mrMeta?.name ?? "reset-verify___ja",
    path: verifyb8gtl2X2mrMeta?.path ?? "/ja/reset/verify",
    meta: verifyb8gtl2X2mrMeta || {},
    alias: verifyb8gtl2X2mrMeta?.alias || [],
    redirect: verifyb8gtl2X2mrMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/reset/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyb8gtl2X2mrMeta?.name ?? "reset-verify___ms",
    path: verifyb8gtl2X2mrMeta?.path ?? "/ms/reset/verify",
    meta: verifyb8gtl2X2mrMeta || {},
    alias: verifyb8gtl2X2mrMeta?.alias || [],
    redirect: verifyb8gtl2X2mrMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/reset/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyb8gtl2X2mrMeta?.name ?? "reset-verify___ko",
    path: verifyb8gtl2X2mrMeta?.path ?? "/ko/reset/verify",
    meta: verifyb8gtl2X2mrMeta || {},
    alias: verifyb8gtl2X2mrMeta?.alias || [],
    redirect: verifyb8gtl2X2mrMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/reset/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyb8gtl2X2mrMeta?.name ?? "reset-verify___vi",
    path: verifyb8gtl2X2mrMeta?.path ?? "/vi/reset/verify",
    meta: verifyb8gtl2X2mrMeta || {},
    alias: verifyb8gtl2X2mrMeta?.alias || [],
    redirect: verifyb8gtl2X2mrMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/reset/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyb8gtl2X2mrMeta?.name ?? "reset-verify___it",
    path: verifyb8gtl2X2mrMeta?.path ?? "/it/reset/verify",
    meta: verifyb8gtl2X2mrMeta || {},
    alias: verifyb8gtl2X2mrMeta?.alias || [],
    redirect: verifyb8gtl2X2mrMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/reset/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyb8gtl2X2mrMeta?.name ?? "reset-verify___de",
    path: verifyb8gtl2X2mrMeta?.path ?? "/de/reset/verify",
    meta: verifyb8gtl2X2mrMeta || {},
    alias: verifyb8gtl2X2mrMeta?.alias || [],
    redirect: verifyb8gtl2X2mrMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/reset/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyb8gtl2X2mrMeta?.name ?? "reset-verify___es",
    path: verifyb8gtl2X2mrMeta?.path ?? "/es/reset/verify",
    meta: verifyb8gtl2X2mrMeta || {},
    alias: verifyb8gtl2X2mrMeta?.alias || [],
    redirect: verifyb8gtl2X2mrMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/reset/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyb8gtl2X2mrMeta?.name ?? "reset-verify___hi",
    path: verifyb8gtl2X2mrMeta?.path ?? "/hi/reset/verify",
    meta: verifyb8gtl2X2mrMeta || {},
    alias: verifyb8gtl2X2mrMeta?.alias || [],
    redirect: verifyb8gtl2X2mrMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/reset/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyb8gtl2X2mrMeta?.name ?? "reset-verify___fr",
    path: verifyb8gtl2X2mrMeta?.path ?? "/fr/reset/verify",
    meta: verifyb8gtl2X2mrMeta || {},
    alias: verifyb8gtl2X2mrMeta?.alias || [],
    redirect: verifyb8gtl2X2mrMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/reset/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyb8gtl2X2mrMeta?.name ?? "reset-verify___ru",
    path: verifyb8gtl2X2mrMeta?.path ?? "/ru/reset/verify",
    meta: verifyb8gtl2X2mrMeta || {},
    alias: verifyb8gtl2X2mrMeta?.alias || [],
    redirect: verifyb8gtl2X2mrMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/reset/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyb8gtl2X2mrMeta?.name ?? "reset-verify___bn",
    path: verifyb8gtl2X2mrMeta?.path ?? "/bn/reset/verify",
    meta: verifyb8gtl2X2mrMeta || {},
    alias: verifyb8gtl2X2mrMeta?.alias || [],
    redirect: verifyb8gtl2X2mrMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/reset/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyb8gtl2X2mrMeta?.name ?? "reset-verify___pt",
    path: verifyb8gtl2X2mrMeta?.path ?? "/pt/reset/verify",
    meta: verifyb8gtl2X2mrMeta || {},
    alias: verifyb8gtl2X2mrMeta?.alias || [],
    redirect: verifyb8gtl2X2mrMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/reset/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyb8gtl2X2mrMeta?.name ?? "reset-verify___km",
    path: verifyb8gtl2X2mrMeta?.path ?? "/km/reset/verify",
    meta: verifyb8gtl2X2mrMeta || {},
    alias: verifyb8gtl2X2mrMeta?.alias || [],
    redirect: verifyb8gtl2X2mrMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/reset/verify.vue").then(m => m.default || m)
  },
  {
    name: indexI7wQ2nd8wsMeta?.name ?? "retrieve___en",
    path: indexI7wQ2nd8wsMeta?.path ?? "/en/retrieve",
    meta: indexI7wQ2nd8wsMeta || {},
    alias: indexI7wQ2nd8wsMeta?.alias || [],
    redirect: indexI7wQ2nd8wsMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/retrieve/index.vue").then(m => m.default || m)
  },
  {
    name: indexI7wQ2nd8wsMeta?.name ?? "retrieve___id",
    path: indexI7wQ2nd8wsMeta?.path ?? "/id/retrieve",
    meta: indexI7wQ2nd8wsMeta || {},
    alias: indexI7wQ2nd8wsMeta?.alias || [],
    redirect: indexI7wQ2nd8wsMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/retrieve/index.vue").then(m => m.default || m)
  },
  {
    name: indexI7wQ2nd8wsMeta?.name ?? "retrieve___tl",
    path: indexI7wQ2nd8wsMeta?.path ?? "/tl/retrieve",
    meta: indexI7wQ2nd8wsMeta || {},
    alias: indexI7wQ2nd8wsMeta?.alias || [],
    redirect: indexI7wQ2nd8wsMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/retrieve/index.vue").then(m => m.default || m)
  },
  {
    name: indexI7wQ2nd8wsMeta?.name ?? "retrieve___zh",
    path: indexI7wQ2nd8wsMeta?.path ?? "/zh/retrieve",
    meta: indexI7wQ2nd8wsMeta || {},
    alias: indexI7wQ2nd8wsMeta?.alias || [],
    redirect: indexI7wQ2nd8wsMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/retrieve/index.vue").then(m => m.default || m)
  },
  {
    name: indexI7wQ2nd8wsMeta?.name ?? "retrieve___zh-tw",
    path: indexI7wQ2nd8wsMeta?.path ?? "/zh-tw/retrieve",
    meta: indexI7wQ2nd8wsMeta || {},
    alias: indexI7wQ2nd8wsMeta?.alias || [],
    redirect: indexI7wQ2nd8wsMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/retrieve/index.vue").then(m => m.default || m)
  },
  {
    name: indexI7wQ2nd8wsMeta?.name ?? "retrieve___th",
    path: indexI7wQ2nd8wsMeta?.path ?? "/th/retrieve",
    meta: indexI7wQ2nd8wsMeta || {},
    alias: indexI7wQ2nd8wsMeta?.alias || [],
    redirect: indexI7wQ2nd8wsMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/retrieve/index.vue").then(m => m.default || m)
  },
  {
    name: indexI7wQ2nd8wsMeta?.name ?? "retrieve___ja",
    path: indexI7wQ2nd8wsMeta?.path ?? "/ja/retrieve",
    meta: indexI7wQ2nd8wsMeta || {},
    alias: indexI7wQ2nd8wsMeta?.alias || [],
    redirect: indexI7wQ2nd8wsMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/retrieve/index.vue").then(m => m.default || m)
  },
  {
    name: indexI7wQ2nd8wsMeta?.name ?? "retrieve___ms",
    path: indexI7wQ2nd8wsMeta?.path ?? "/ms/retrieve",
    meta: indexI7wQ2nd8wsMeta || {},
    alias: indexI7wQ2nd8wsMeta?.alias || [],
    redirect: indexI7wQ2nd8wsMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/retrieve/index.vue").then(m => m.default || m)
  },
  {
    name: indexI7wQ2nd8wsMeta?.name ?? "retrieve___ko",
    path: indexI7wQ2nd8wsMeta?.path ?? "/ko/retrieve",
    meta: indexI7wQ2nd8wsMeta || {},
    alias: indexI7wQ2nd8wsMeta?.alias || [],
    redirect: indexI7wQ2nd8wsMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/retrieve/index.vue").then(m => m.default || m)
  },
  {
    name: indexI7wQ2nd8wsMeta?.name ?? "retrieve___vi",
    path: indexI7wQ2nd8wsMeta?.path ?? "/vi/retrieve",
    meta: indexI7wQ2nd8wsMeta || {},
    alias: indexI7wQ2nd8wsMeta?.alias || [],
    redirect: indexI7wQ2nd8wsMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/retrieve/index.vue").then(m => m.default || m)
  },
  {
    name: indexI7wQ2nd8wsMeta?.name ?? "retrieve___it",
    path: indexI7wQ2nd8wsMeta?.path ?? "/it/retrieve",
    meta: indexI7wQ2nd8wsMeta || {},
    alias: indexI7wQ2nd8wsMeta?.alias || [],
    redirect: indexI7wQ2nd8wsMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/retrieve/index.vue").then(m => m.default || m)
  },
  {
    name: indexI7wQ2nd8wsMeta?.name ?? "retrieve___de",
    path: indexI7wQ2nd8wsMeta?.path ?? "/de/retrieve",
    meta: indexI7wQ2nd8wsMeta || {},
    alias: indexI7wQ2nd8wsMeta?.alias || [],
    redirect: indexI7wQ2nd8wsMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/retrieve/index.vue").then(m => m.default || m)
  },
  {
    name: indexI7wQ2nd8wsMeta?.name ?? "retrieve___es",
    path: indexI7wQ2nd8wsMeta?.path ?? "/es/retrieve",
    meta: indexI7wQ2nd8wsMeta || {},
    alias: indexI7wQ2nd8wsMeta?.alias || [],
    redirect: indexI7wQ2nd8wsMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/retrieve/index.vue").then(m => m.default || m)
  },
  {
    name: indexI7wQ2nd8wsMeta?.name ?? "retrieve___hi",
    path: indexI7wQ2nd8wsMeta?.path ?? "/hi/retrieve",
    meta: indexI7wQ2nd8wsMeta || {},
    alias: indexI7wQ2nd8wsMeta?.alias || [],
    redirect: indexI7wQ2nd8wsMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/retrieve/index.vue").then(m => m.default || m)
  },
  {
    name: indexI7wQ2nd8wsMeta?.name ?? "retrieve___fr",
    path: indexI7wQ2nd8wsMeta?.path ?? "/fr/retrieve",
    meta: indexI7wQ2nd8wsMeta || {},
    alias: indexI7wQ2nd8wsMeta?.alias || [],
    redirect: indexI7wQ2nd8wsMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/retrieve/index.vue").then(m => m.default || m)
  },
  {
    name: indexI7wQ2nd8wsMeta?.name ?? "retrieve___ru",
    path: indexI7wQ2nd8wsMeta?.path ?? "/ru/retrieve",
    meta: indexI7wQ2nd8wsMeta || {},
    alias: indexI7wQ2nd8wsMeta?.alias || [],
    redirect: indexI7wQ2nd8wsMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/retrieve/index.vue").then(m => m.default || m)
  },
  {
    name: indexI7wQ2nd8wsMeta?.name ?? "retrieve___bn",
    path: indexI7wQ2nd8wsMeta?.path ?? "/bn/retrieve",
    meta: indexI7wQ2nd8wsMeta || {},
    alias: indexI7wQ2nd8wsMeta?.alias || [],
    redirect: indexI7wQ2nd8wsMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/retrieve/index.vue").then(m => m.default || m)
  },
  {
    name: indexI7wQ2nd8wsMeta?.name ?? "retrieve___pt",
    path: indexI7wQ2nd8wsMeta?.path ?? "/pt/retrieve",
    meta: indexI7wQ2nd8wsMeta || {},
    alias: indexI7wQ2nd8wsMeta?.alias || [],
    redirect: indexI7wQ2nd8wsMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/retrieve/index.vue").then(m => m.default || m)
  },
  {
    name: indexI7wQ2nd8wsMeta?.name ?? "retrieve___km",
    path: indexI7wQ2nd8wsMeta?.path ?? "/km/retrieve",
    meta: indexI7wQ2nd8wsMeta || {},
    alias: indexI7wQ2nd8wsMeta?.alias || [],
    redirect: indexI7wQ2nd8wsMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/retrieve/index.vue").then(m => m.default || m)
  },
  {
    name: listpGuopMuAdqMeta?.name ?? "retrieve-list___en",
    path: listpGuopMuAdqMeta?.path ?? "/en/retrieve/list",
    meta: listpGuopMuAdqMeta || {},
    alias: listpGuopMuAdqMeta?.alias || [],
    redirect: listpGuopMuAdqMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/retrieve/list.vue").then(m => m.default || m)
  },
  {
    name: listpGuopMuAdqMeta?.name ?? "retrieve-list___id",
    path: listpGuopMuAdqMeta?.path ?? "/id/retrieve/list",
    meta: listpGuopMuAdqMeta || {},
    alias: listpGuopMuAdqMeta?.alias || [],
    redirect: listpGuopMuAdqMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/retrieve/list.vue").then(m => m.default || m)
  },
  {
    name: listpGuopMuAdqMeta?.name ?? "retrieve-list___tl",
    path: listpGuopMuAdqMeta?.path ?? "/tl/retrieve/list",
    meta: listpGuopMuAdqMeta || {},
    alias: listpGuopMuAdqMeta?.alias || [],
    redirect: listpGuopMuAdqMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/retrieve/list.vue").then(m => m.default || m)
  },
  {
    name: listpGuopMuAdqMeta?.name ?? "retrieve-list___zh",
    path: listpGuopMuAdqMeta?.path ?? "/zh/retrieve/list",
    meta: listpGuopMuAdqMeta || {},
    alias: listpGuopMuAdqMeta?.alias || [],
    redirect: listpGuopMuAdqMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/retrieve/list.vue").then(m => m.default || m)
  },
  {
    name: listpGuopMuAdqMeta?.name ?? "retrieve-list___zh-tw",
    path: listpGuopMuAdqMeta?.path ?? "/zh-tw/retrieve/list",
    meta: listpGuopMuAdqMeta || {},
    alias: listpGuopMuAdqMeta?.alias || [],
    redirect: listpGuopMuAdqMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/retrieve/list.vue").then(m => m.default || m)
  },
  {
    name: listpGuopMuAdqMeta?.name ?? "retrieve-list___th",
    path: listpGuopMuAdqMeta?.path ?? "/th/retrieve/list",
    meta: listpGuopMuAdqMeta || {},
    alias: listpGuopMuAdqMeta?.alias || [],
    redirect: listpGuopMuAdqMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/retrieve/list.vue").then(m => m.default || m)
  },
  {
    name: listpGuopMuAdqMeta?.name ?? "retrieve-list___ja",
    path: listpGuopMuAdqMeta?.path ?? "/ja/retrieve/list",
    meta: listpGuopMuAdqMeta || {},
    alias: listpGuopMuAdqMeta?.alias || [],
    redirect: listpGuopMuAdqMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/retrieve/list.vue").then(m => m.default || m)
  },
  {
    name: listpGuopMuAdqMeta?.name ?? "retrieve-list___ms",
    path: listpGuopMuAdqMeta?.path ?? "/ms/retrieve/list",
    meta: listpGuopMuAdqMeta || {},
    alias: listpGuopMuAdqMeta?.alias || [],
    redirect: listpGuopMuAdqMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/retrieve/list.vue").then(m => m.default || m)
  },
  {
    name: listpGuopMuAdqMeta?.name ?? "retrieve-list___ko",
    path: listpGuopMuAdqMeta?.path ?? "/ko/retrieve/list",
    meta: listpGuopMuAdqMeta || {},
    alias: listpGuopMuAdqMeta?.alias || [],
    redirect: listpGuopMuAdqMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/retrieve/list.vue").then(m => m.default || m)
  },
  {
    name: listpGuopMuAdqMeta?.name ?? "retrieve-list___vi",
    path: listpGuopMuAdqMeta?.path ?? "/vi/retrieve/list",
    meta: listpGuopMuAdqMeta || {},
    alias: listpGuopMuAdqMeta?.alias || [],
    redirect: listpGuopMuAdqMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/retrieve/list.vue").then(m => m.default || m)
  },
  {
    name: listpGuopMuAdqMeta?.name ?? "retrieve-list___it",
    path: listpGuopMuAdqMeta?.path ?? "/it/retrieve/list",
    meta: listpGuopMuAdqMeta || {},
    alias: listpGuopMuAdqMeta?.alias || [],
    redirect: listpGuopMuAdqMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/retrieve/list.vue").then(m => m.default || m)
  },
  {
    name: listpGuopMuAdqMeta?.name ?? "retrieve-list___de",
    path: listpGuopMuAdqMeta?.path ?? "/de/retrieve/list",
    meta: listpGuopMuAdqMeta || {},
    alias: listpGuopMuAdqMeta?.alias || [],
    redirect: listpGuopMuAdqMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/retrieve/list.vue").then(m => m.default || m)
  },
  {
    name: listpGuopMuAdqMeta?.name ?? "retrieve-list___es",
    path: listpGuopMuAdqMeta?.path ?? "/es/retrieve/list",
    meta: listpGuopMuAdqMeta || {},
    alias: listpGuopMuAdqMeta?.alias || [],
    redirect: listpGuopMuAdqMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/retrieve/list.vue").then(m => m.default || m)
  },
  {
    name: listpGuopMuAdqMeta?.name ?? "retrieve-list___hi",
    path: listpGuopMuAdqMeta?.path ?? "/hi/retrieve/list",
    meta: listpGuopMuAdqMeta || {},
    alias: listpGuopMuAdqMeta?.alias || [],
    redirect: listpGuopMuAdqMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/retrieve/list.vue").then(m => m.default || m)
  },
  {
    name: listpGuopMuAdqMeta?.name ?? "retrieve-list___fr",
    path: listpGuopMuAdqMeta?.path ?? "/fr/retrieve/list",
    meta: listpGuopMuAdqMeta || {},
    alias: listpGuopMuAdqMeta?.alias || [],
    redirect: listpGuopMuAdqMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/retrieve/list.vue").then(m => m.default || m)
  },
  {
    name: listpGuopMuAdqMeta?.name ?? "retrieve-list___ru",
    path: listpGuopMuAdqMeta?.path ?? "/ru/retrieve/list",
    meta: listpGuopMuAdqMeta || {},
    alias: listpGuopMuAdqMeta?.alias || [],
    redirect: listpGuopMuAdqMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/retrieve/list.vue").then(m => m.default || m)
  },
  {
    name: listpGuopMuAdqMeta?.name ?? "retrieve-list___bn",
    path: listpGuopMuAdqMeta?.path ?? "/bn/retrieve/list",
    meta: listpGuopMuAdqMeta || {},
    alias: listpGuopMuAdqMeta?.alias || [],
    redirect: listpGuopMuAdqMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/retrieve/list.vue").then(m => m.default || m)
  },
  {
    name: listpGuopMuAdqMeta?.name ?? "retrieve-list___pt",
    path: listpGuopMuAdqMeta?.path ?? "/pt/retrieve/list",
    meta: listpGuopMuAdqMeta || {},
    alias: listpGuopMuAdqMeta?.alias || [],
    redirect: listpGuopMuAdqMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/retrieve/list.vue").then(m => m.default || m)
  },
  {
    name: listpGuopMuAdqMeta?.name ?? "retrieve-list___km",
    path: listpGuopMuAdqMeta?.path ?? "/km/retrieve/list",
    meta: listpGuopMuAdqMeta || {},
    alias: listpGuopMuAdqMeta?.alias || [],
    redirect: listpGuopMuAdqMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/retrieve/list.vue").then(m => m.default || m)
  },
  {
    name: indexaQHliZDDu0Meta?.name ?? "signup___en",
    path: indexaQHliZDDu0Meta?.path ?? "/en/signup",
    meta: indexaQHliZDDu0Meta || {},
    alias: indexaQHliZDDu0Meta?.alias || [],
    redirect: indexaQHliZDDu0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/signup/index.vue").then(m => m.default || m)
  },
  {
    name: indexaQHliZDDu0Meta?.name ?? "signup___id",
    path: indexaQHliZDDu0Meta?.path ?? "/id/signup",
    meta: indexaQHliZDDu0Meta || {},
    alias: indexaQHliZDDu0Meta?.alias || [],
    redirect: indexaQHliZDDu0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/signup/index.vue").then(m => m.default || m)
  },
  {
    name: indexaQHliZDDu0Meta?.name ?? "signup___tl",
    path: indexaQHliZDDu0Meta?.path ?? "/tl/signup",
    meta: indexaQHliZDDu0Meta || {},
    alias: indexaQHliZDDu0Meta?.alias || [],
    redirect: indexaQHliZDDu0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/signup/index.vue").then(m => m.default || m)
  },
  {
    name: indexaQHliZDDu0Meta?.name ?? "signup___zh",
    path: indexaQHliZDDu0Meta?.path ?? "/zh/signup",
    meta: indexaQHliZDDu0Meta || {},
    alias: indexaQHliZDDu0Meta?.alias || [],
    redirect: indexaQHliZDDu0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/signup/index.vue").then(m => m.default || m)
  },
  {
    name: indexaQHliZDDu0Meta?.name ?? "signup___zh-tw",
    path: indexaQHliZDDu0Meta?.path ?? "/zh-tw/signup",
    meta: indexaQHliZDDu0Meta || {},
    alias: indexaQHliZDDu0Meta?.alias || [],
    redirect: indexaQHliZDDu0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/signup/index.vue").then(m => m.default || m)
  },
  {
    name: indexaQHliZDDu0Meta?.name ?? "signup___th",
    path: indexaQHliZDDu0Meta?.path ?? "/th/signup",
    meta: indexaQHliZDDu0Meta || {},
    alias: indexaQHliZDDu0Meta?.alias || [],
    redirect: indexaQHliZDDu0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/signup/index.vue").then(m => m.default || m)
  },
  {
    name: indexaQHliZDDu0Meta?.name ?? "signup___ja",
    path: indexaQHliZDDu0Meta?.path ?? "/ja/signup",
    meta: indexaQHliZDDu0Meta || {},
    alias: indexaQHliZDDu0Meta?.alias || [],
    redirect: indexaQHliZDDu0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/signup/index.vue").then(m => m.default || m)
  },
  {
    name: indexaQHliZDDu0Meta?.name ?? "signup___ms",
    path: indexaQHliZDDu0Meta?.path ?? "/ms/signup",
    meta: indexaQHliZDDu0Meta || {},
    alias: indexaQHliZDDu0Meta?.alias || [],
    redirect: indexaQHliZDDu0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/signup/index.vue").then(m => m.default || m)
  },
  {
    name: indexaQHliZDDu0Meta?.name ?? "signup___ko",
    path: indexaQHliZDDu0Meta?.path ?? "/ko/signup",
    meta: indexaQHliZDDu0Meta || {},
    alias: indexaQHliZDDu0Meta?.alias || [],
    redirect: indexaQHliZDDu0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/signup/index.vue").then(m => m.default || m)
  },
  {
    name: indexaQHliZDDu0Meta?.name ?? "signup___vi",
    path: indexaQHliZDDu0Meta?.path ?? "/vi/signup",
    meta: indexaQHliZDDu0Meta || {},
    alias: indexaQHliZDDu0Meta?.alias || [],
    redirect: indexaQHliZDDu0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/signup/index.vue").then(m => m.default || m)
  },
  {
    name: indexaQHliZDDu0Meta?.name ?? "signup___it",
    path: indexaQHliZDDu0Meta?.path ?? "/it/signup",
    meta: indexaQHliZDDu0Meta || {},
    alias: indexaQHliZDDu0Meta?.alias || [],
    redirect: indexaQHliZDDu0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/signup/index.vue").then(m => m.default || m)
  },
  {
    name: indexaQHliZDDu0Meta?.name ?? "signup___de",
    path: indexaQHliZDDu0Meta?.path ?? "/de/signup",
    meta: indexaQHliZDDu0Meta || {},
    alias: indexaQHliZDDu0Meta?.alias || [],
    redirect: indexaQHliZDDu0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/signup/index.vue").then(m => m.default || m)
  },
  {
    name: indexaQHliZDDu0Meta?.name ?? "signup___es",
    path: indexaQHliZDDu0Meta?.path ?? "/es/signup",
    meta: indexaQHliZDDu0Meta || {},
    alias: indexaQHliZDDu0Meta?.alias || [],
    redirect: indexaQHliZDDu0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/signup/index.vue").then(m => m.default || m)
  },
  {
    name: indexaQHliZDDu0Meta?.name ?? "signup___hi",
    path: indexaQHliZDDu0Meta?.path ?? "/hi/signup",
    meta: indexaQHliZDDu0Meta || {},
    alias: indexaQHliZDDu0Meta?.alias || [],
    redirect: indexaQHliZDDu0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/signup/index.vue").then(m => m.default || m)
  },
  {
    name: indexaQHliZDDu0Meta?.name ?? "signup___fr",
    path: indexaQHliZDDu0Meta?.path ?? "/fr/signup",
    meta: indexaQHliZDDu0Meta || {},
    alias: indexaQHliZDDu0Meta?.alias || [],
    redirect: indexaQHliZDDu0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/signup/index.vue").then(m => m.default || m)
  },
  {
    name: indexaQHliZDDu0Meta?.name ?? "signup___ru",
    path: indexaQHliZDDu0Meta?.path ?? "/ru/signup",
    meta: indexaQHliZDDu0Meta || {},
    alias: indexaQHliZDDu0Meta?.alias || [],
    redirect: indexaQHliZDDu0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/signup/index.vue").then(m => m.default || m)
  },
  {
    name: indexaQHliZDDu0Meta?.name ?? "signup___bn",
    path: indexaQHliZDDu0Meta?.path ?? "/bn/signup",
    meta: indexaQHliZDDu0Meta || {},
    alias: indexaQHliZDDu0Meta?.alias || [],
    redirect: indexaQHliZDDu0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/signup/index.vue").then(m => m.default || m)
  },
  {
    name: indexaQHliZDDu0Meta?.name ?? "signup___pt",
    path: indexaQHliZDDu0Meta?.path ?? "/pt/signup",
    meta: indexaQHliZDDu0Meta || {},
    alias: indexaQHliZDDu0Meta?.alias || [],
    redirect: indexaQHliZDDu0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/signup/index.vue").then(m => m.default || m)
  },
  {
    name: indexaQHliZDDu0Meta?.name ?? "signup___km",
    path: indexaQHliZDDu0Meta?.path ?? "/km/signup",
    meta: indexaQHliZDDu0Meta || {},
    alias: indexaQHliZDDu0Meta?.alias || [],
    redirect: indexaQHliZDDu0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/signup/index.vue").then(m => m.default || m)
  },
  {
    name: setup_45accountNy6ihyASIcMeta?.name ?? "signup-setup-account___en",
    path: setup_45accountNy6ihyASIcMeta?.path ?? "/en/signup/setup-account",
    meta: setup_45accountNy6ihyASIcMeta || {},
    alias: setup_45accountNy6ihyASIcMeta?.alias || [],
    redirect: setup_45accountNy6ihyASIcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/signup/setup-account.vue").then(m => m.default || m)
  },
  {
    name: setup_45accountNy6ihyASIcMeta?.name ?? "signup-setup-account___id",
    path: setup_45accountNy6ihyASIcMeta?.path ?? "/id/signup/setup-account",
    meta: setup_45accountNy6ihyASIcMeta || {},
    alias: setup_45accountNy6ihyASIcMeta?.alias || [],
    redirect: setup_45accountNy6ihyASIcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/signup/setup-account.vue").then(m => m.default || m)
  },
  {
    name: setup_45accountNy6ihyASIcMeta?.name ?? "signup-setup-account___tl",
    path: setup_45accountNy6ihyASIcMeta?.path ?? "/tl/signup/setup-account",
    meta: setup_45accountNy6ihyASIcMeta || {},
    alias: setup_45accountNy6ihyASIcMeta?.alias || [],
    redirect: setup_45accountNy6ihyASIcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/signup/setup-account.vue").then(m => m.default || m)
  },
  {
    name: setup_45accountNy6ihyASIcMeta?.name ?? "signup-setup-account___zh",
    path: setup_45accountNy6ihyASIcMeta?.path ?? "/zh/signup/setup-account",
    meta: setup_45accountNy6ihyASIcMeta || {},
    alias: setup_45accountNy6ihyASIcMeta?.alias || [],
    redirect: setup_45accountNy6ihyASIcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/signup/setup-account.vue").then(m => m.default || m)
  },
  {
    name: setup_45accountNy6ihyASIcMeta?.name ?? "signup-setup-account___zh-tw",
    path: setup_45accountNy6ihyASIcMeta?.path ?? "/zh-tw/signup/setup-account",
    meta: setup_45accountNy6ihyASIcMeta || {},
    alias: setup_45accountNy6ihyASIcMeta?.alias || [],
    redirect: setup_45accountNy6ihyASIcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/signup/setup-account.vue").then(m => m.default || m)
  },
  {
    name: setup_45accountNy6ihyASIcMeta?.name ?? "signup-setup-account___th",
    path: setup_45accountNy6ihyASIcMeta?.path ?? "/th/signup/setup-account",
    meta: setup_45accountNy6ihyASIcMeta || {},
    alias: setup_45accountNy6ihyASIcMeta?.alias || [],
    redirect: setup_45accountNy6ihyASIcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/signup/setup-account.vue").then(m => m.default || m)
  },
  {
    name: setup_45accountNy6ihyASIcMeta?.name ?? "signup-setup-account___ja",
    path: setup_45accountNy6ihyASIcMeta?.path ?? "/ja/signup/setup-account",
    meta: setup_45accountNy6ihyASIcMeta || {},
    alias: setup_45accountNy6ihyASIcMeta?.alias || [],
    redirect: setup_45accountNy6ihyASIcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/signup/setup-account.vue").then(m => m.default || m)
  },
  {
    name: setup_45accountNy6ihyASIcMeta?.name ?? "signup-setup-account___ms",
    path: setup_45accountNy6ihyASIcMeta?.path ?? "/ms/signup/setup-account",
    meta: setup_45accountNy6ihyASIcMeta || {},
    alias: setup_45accountNy6ihyASIcMeta?.alias || [],
    redirect: setup_45accountNy6ihyASIcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/signup/setup-account.vue").then(m => m.default || m)
  },
  {
    name: setup_45accountNy6ihyASIcMeta?.name ?? "signup-setup-account___ko",
    path: setup_45accountNy6ihyASIcMeta?.path ?? "/ko/signup/setup-account",
    meta: setup_45accountNy6ihyASIcMeta || {},
    alias: setup_45accountNy6ihyASIcMeta?.alias || [],
    redirect: setup_45accountNy6ihyASIcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/signup/setup-account.vue").then(m => m.default || m)
  },
  {
    name: setup_45accountNy6ihyASIcMeta?.name ?? "signup-setup-account___vi",
    path: setup_45accountNy6ihyASIcMeta?.path ?? "/vi/signup/setup-account",
    meta: setup_45accountNy6ihyASIcMeta || {},
    alias: setup_45accountNy6ihyASIcMeta?.alias || [],
    redirect: setup_45accountNy6ihyASIcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/signup/setup-account.vue").then(m => m.default || m)
  },
  {
    name: setup_45accountNy6ihyASIcMeta?.name ?? "signup-setup-account___it",
    path: setup_45accountNy6ihyASIcMeta?.path ?? "/it/signup/setup-account",
    meta: setup_45accountNy6ihyASIcMeta || {},
    alias: setup_45accountNy6ihyASIcMeta?.alias || [],
    redirect: setup_45accountNy6ihyASIcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/signup/setup-account.vue").then(m => m.default || m)
  },
  {
    name: setup_45accountNy6ihyASIcMeta?.name ?? "signup-setup-account___de",
    path: setup_45accountNy6ihyASIcMeta?.path ?? "/de/signup/setup-account",
    meta: setup_45accountNy6ihyASIcMeta || {},
    alias: setup_45accountNy6ihyASIcMeta?.alias || [],
    redirect: setup_45accountNy6ihyASIcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/signup/setup-account.vue").then(m => m.default || m)
  },
  {
    name: setup_45accountNy6ihyASIcMeta?.name ?? "signup-setup-account___es",
    path: setup_45accountNy6ihyASIcMeta?.path ?? "/es/signup/setup-account",
    meta: setup_45accountNy6ihyASIcMeta || {},
    alias: setup_45accountNy6ihyASIcMeta?.alias || [],
    redirect: setup_45accountNy6ihyASIcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/signup/setup-account.vue").then(m => m.default || m)
  },
  {
    name: setup_45accountNy6ihyASIcMeta?.name ?? "signup-setup-account___hi",
    path: setup_45accountNy6ihyASIcMeta?.path ?? "/hi/signup/setup-account",
    meta: setup_45accountNy6ihyASIcMeta || {},
    alias: setup_45accountNy6ihyASIcMeta?.alias || [],
    redirect: setup_45accountNy6ihyASIcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/signup/setup-account.vue").then(m => m.default || m)
  },
  {
    name: setup_45accountNy6ihyASIcMeta?.name ?? "signup-setup-account___fr",
    path: setup_45accountNy6ihyASIcMeta?.path ?? "/fr/signup/setup-account",
    meta: setup_45accountNy6ihyASIcMeta || {},
    alias: setup_45accountNy6ihyASIcMeta?.alias || [],
    redirect: setup_45accountNy6ihyASIcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/signup/setup-account.vue").then(m => m.default || m)
  },
  {
    name: setup_45accountNy6ihyASIcMeta?.name ?? "signup-setup-account___ru",
    path: setup_45accountNy6ihyASIcMeta?.path ?? "/ru/signup/setup-account",
    meta: setup_45accountNy6ihyASIcMeta || {},
    alias: setup_45accountNy6ihyASIcMeta?.alias || [],
    redirect: setup_45accountNy6ihyASIcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/signup/setup-account.vue").then(m => m.default || m)
  },
  {
    name: setup_45accountNy6ihyASIcMeta?.name ?? "signup-setup-account___bn",
    path: setup_45accountNy6ihyASIcMeta?.path ?? "/bn/signup/setup-account",
    meta: setup_45accountNy6ihyASIcMeta || {},
    alias: setup_45accountNy6ihyASIcMeta?.alias || [],
    redirect: setup_45accountNy6ihyASIcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/signup/setup-account.vue").then(m => m.default || m)
  },
  {
    name: setup_45accountNy6ihyASIcMeta?.name ?? "signup-setup-account___pt",
    path: setup_45accountNy6ihyASIcMeta?.path ?? "/pt/signup/setup-account",
    meta: setup_45accountNy6ihyASIcMeta || {},
    alias: setup_45accountNy6ihyASIcMeta?.alias || [],
    redirect: setup_45accountNy6ihyASIcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/signup/setup-account.vue").then(m => m.default || m)
  },
  {
    name: setup_45accountNy6ihyASIcMeta?.name ?? "signup-setup-account___km",
    path: setup_45accountNy6ihyASIcMeta?.path ?? "/km/signup/setup-account",
    meta: setup_45accountNy6ihyASIcMeta || {},
    alias: setup_45accountNy6ihyASIcMeta?.alias || [],
    redirect: setup_45accountNy6ihyASIcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/signup/setup-account.vue").then(m => m.default || m)
  },
  {
    name: verifyTSeTbB3oi1Meta?.name ?? "signup-verify___en",
    path: verifyTSeTbB3oi1Meta?.path ?? "/en/signup/verify",
    meta: verifyTSeTbB3oi1Meta || {},
    alias: verifyTSeTbB3oi1Meta?.alias || [],
    redirect: verifyTSeTbB3oi1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/signup/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyTSeTbB3oi1Meta?.name ?? "signup-verify___id",
    path: verifyTSeTbB3oi1Meta?.path ?? "/id/signup/verify",
    meta: verifyTSeTbB3oi1Meta || {},
    alias: verifyTSeTbB3oi1Meta?.alias || [],
    redirect: verifyTSeTbB3oi1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/signup/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyTSeTbB3oi1Meta?.name ?? "signup-verify___tl",
    path: verifyTSeTbB3oi1Meta?.path ?? "/tl/signup/verify",
    meta: verifyTSeTbB3oi1Meta || {},
    alias: verifyTSeTbB3oi1Meta?.alias || [],
    redirect: verifyTSeTbB3oi1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/signup/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyTSeTbB3oi1Meta?.name ?? "signup-verify___zh",
    path: verifyTSeTbB3oi1Meta?.path ?? "/zh/signup/verify",
    meta: verifyTSeTbB3oi1Meta || {},
    alias: verifyTSeTbB3oi1Meta?.alias || [],
    redirect: verifyTSeTbB3oi1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/signup/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyTSeTbB3oi1Meta?.name ?? "signup-verify___zh-tw",
    path: verifyTSeTbB3oi1Meta?.path ?? "/zh-tw/signup/verify",
    meta: verifyTSeTbB3oi1Meta || {},
    alias: verifyTSeTbB3oi1Meta?.alias || [],
    redirect: verifyTSeTbB3oi1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/signup/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyTSeTbB3oi1Meta?.name ?? "signup-verify___th",
    path: verifyTSeTbB3oi1Meta?.path ?? "/th/signup/verify",
    meta: verifyTSeTbB3oi1Meta || {},
    alias: verifyTSeTbB3oi1Meta?.alias || [],
    redirect: verifyTSeTbB3oi1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/signup/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyTSeTbB3oi1Meta?.name ?? "signup-verify___ja",
    path: verifyTSeTbB3oi1Meta?.path ?? "/ja/signup/verify",
    meta: verifyTSeTbB3oi1Meta || {},
    alias: verifyTSeTbB3oi1Meta?.alias || [],
    redirect: verifyTSeTbB3oi1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/signup/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyTSeTbB3oi1Meta?.name ?? "signup-verify___ms",
    path: verifyTSeTbB3oi1Meta?.path ?? "/ms/signup/verify",
    meta: verifyTSeTbB3oi1Meta || {},
    alias: verifyTSeTbB3oi1Meta?.alias || [],
    redirect: verifyTSeTbB3oi1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/signup/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyTSeTbB3oi1Meta?.name ?? "signup-verify___ko",
    path: verifyTSeTbB3oi1Meta?.path ?? "/ko/signup/verify",
    meta: verifyTSeTbB3oi1Meta || {},
    alias: verifyTSeTbB3oi1Meta?.alias || [],
    redirect: verifyTSeTbB3oi1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/signup/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyTSeTbB3oi1Meta?.name ?? "signup-verify___vi",
    path: verifyTSeTbB3oi1Meta?.path ?? "/vi/signup/verify",
    meta: verifyTSeTbB3oi1Meta || {},
    alias: verifyTSeTbB3oi1Meta?.alias || [],
    redirect: verifyTSeTbB3oi1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/signup/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyTSeTbB3oi1Meta?.name ?? "signup-verify___it",
    path: verifyTSeTbB3oi1Meta?.path ?? "/it/signup/verify",
    meta: verifyTSeTbB3oi1Meta || {},
    alias: verifyTSeTbB3oi1Meta?.alias || [],
    redirect: verifyTSeTbB3oi1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/signup/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyTSeTbB3oi1Meta?.name ?? "signup-verify___de",
    path: verifyTSeTbB3oi1Meta?.path ?? "/de/signup/verify",
    meta: verifyTSeTbB3oi1Meta || {},
    alias: verifyTSeTbB3oi1Meta?.alias || [],
    redirect: verifyTSeTbB3oi1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/signup/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyTSeTbB3oi1Meta?.name ?? "signup-verify___es",
    path: verifyTSeTbB3oi1Meta?.path ?? "/es/signup/verify",
    meta: verifyTSeTbB3oi1Meta || {},
    alias: verifyTSeTbB3oi1Meta?.alias || [],
    redirect: verifyTSeTbB3oi1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/signup/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyTSeTbB3oi1Meta?.name ?? "signup-verify___hi",
    path: verifyTSeTbB3oi1Meta?.path ?? "/hi/signup/verify",
    meta: verifyTSeTbB3oi1Meta || {},
    alias: verifyTSeTbB3oi1Meta?.alias || [],
    redirect: verifyTSeTbB3oi1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/signup/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyTSeTbB3oi1Meta?.name ?? "signup-verify___fr",
    path: verifyTSeTbB3oi1Meta?.path ?? "/fr/signup/verify",
    meta: verifyTSeTbB3oi1Meta || {},
    alias: verifyTSeTbB3oi1Meta?.alias || [],
    redirect: verifyTSeTbB3oi1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/signup/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyTSeTbB3oi1Meta?.name ?? "signup-verify___ru",
    path: verifyTSeTbB3oi1Meta?.path ?? "/ru/signup/verify",
    meta: verifyTSeTbB3oi1Meta || {},
    alias: verifyTSeTbB3oi1Meta?.alias || [],
    redirect: verifyTSeTbB3oi1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/signup/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyTSeTbB3oi1Meta?.name ?? "signup-verify___bn",
    path: verifyTSeTbB3oi1Meta?.path ?? "/bn/signup/verify",
    meta: verifyTSeTbB3oi1Meta || {},
    alias: verifyTSeTbB3oi1Meta?.alias || [],
    redirect: verifyTSeTbB3oi1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/signup/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyTSeTbB3oi1Meta?.name ?? "signup-verify___pt",
    path: verifyTSeTbB3oi1Meta?.path ?? "/pt/signup/verify",
    meta: verifyTSeTbB3oi1Meta || {},
    alias: verifyTSeTbB3oi1Meta?.alias || [],
    redirect: verifyTSeTbB3oi1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/signup/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyTSeTbB3oi1Meta?.name ?? "signup-verify___km",
    path: verifyTSeTbB3oi1Meta?.path ?? "/km/signup/verify",
    meta: verifyTSeTbB3oi1Meta || {},
    alias: verifyTSeTbB3oi1Meta?.alias || [],
    redirect: verifyTSeTbB3oi1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/signup/verify.vue").then(m => m.default || m)
  },
  {
    name: conditionOyyjgMj6mtMeta?.name ?? "terms-condition___en",
    path: conditionOyyjgMj6mtMeta?.path ?? "/en/terms/condition",
    meta: conditionOyyjgMj6mtMeta || {},
    alias: conditionOyyjgMj6mtMeta?.alias || [],
    redirect: conditionOyyjgMj6mtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/terms/condition.vue").then(m => m.default || m)
  },
  {
    name: conditionOyyjgMj6mtMeta?.name ?? "terms-condition___id",
    path: conditionOyyjgMj6mtMeta?.path ?? "/id/terms/condition",
    meta: conditionOyyjgMj6mtMeta || {},
    alias: conditionOyyjgMj6mtMeta?.alias || [],
    redirect: conditionOyyjgMj6mtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/terms/condition.vue").then(m => m.default || m)
  },
  {
    name: conditionOyyjgMj6mtMeta?.name ?? "terms-condition___tl",
    path: conditionOyyjgMj6mtMeta?.path ?? "/tl/terms/condition",
    meta: conditionOyyjgMj6mtMeta || {},
    alias: conditionOyyjgMj6mtMeta?.alias || [],
    redirect: conditionOyyjgMj6mtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/terms/condition.vue").then(m => m.default || m)
  },
  {
    name: conditionOyyjgMj6mtMeta?.name ?? "terms-condition___zh",
    path: conditionOyyjgMj6mtMeta?.path ?? "/zh/terms/condition",
    meta: conditionOyyjgMj6mtMeta || {},
    alias: conditionOyyjgMj6mtMeta?.alias || [],
    redirect: conditionOyyjgMj6mtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/terms/condition.vue").then(m => m.default || m)
  },
  {
    name: conditionOyyjgMj6mtMeta?.name ?? "terms-condition___zh-tw",
    path: conditionOyyjgMj6mtMeta?.path ?? "/zh-tw/terms/condition",
    meta: conditionOyyjgMj6mtMeta || {},
    alias: conditionOyyjgMj6mtMeta?.alias || [],
    redirect: conditionOyyjgMj6mtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/terms/condition.vue").then(m => m.default || m)
  },
  {
    name: conditionOyyjgMj6mtMeta?.name ?? "terms-condition___th",
    path: conditionOyyjgMj6mtMeta?.path ?? "/th/terms/condition",
    meta: conditionOyyjgMj6mtMeta || {},
    alias: conditionOyyjgMj6mtMeta?.alias || [],
    redirect: conditionOyyjgMj6mtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/terms/condition.vue").then(m => m.default || m)
  },
  {
    name: conditionOyyjgMj6mtMeta?.name ?? "terms-condition___ja",
    path: conditionOyyjgMj6mtMeta?.path ?? "/ja/terms/condition",
    meta: conditionOyyjgMj6mtMeta || {},
    alias: conditionOyyjgMj6mtMeta?.alias || [],
    redirect: conditionOyyjgMj6mtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/terms/condition.vue").then(m => m.default || m)
  },
  {
    name: conditionOyyjgMj6mtMeta?.name ?? "terms-condition___ms",
    path: conditionOyyjgMj6mtMeta?.path ?? "/ms/terms/condition",
    meta: conditionOyyjgMj6mtMeta || {},
    alias: conditionOyyjgMj6mtMeta?.alias || [],
    redirect: conditionOyyjgMj6mtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/terms/condition.vue").then(m => m.default || m)
  },
  {
    name: conditionOyyjgMj6mtMeta?.name ?? "terms-condition___ko",
    path: conditionOyyjgMj6mtMeta?.path ?? "/ko/terms/condition",
    meta: conditionOyyjgMj6mtMeta || {},
    alias: conditionOyyjgMj6mtMeta?.alias || [],
    redirect: conditionOyyjgMj6mtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/terms/condition.vue").then(m => m.default || m)
  },
  {
    name: conditionOyyjgMj6mtMeta?.name ?? "terms-condition___vi",
    path: conditionOyyjgMj6mtMeta?.path ?? "/vi/terms/condition",
    meta: conditionOyyjgMj6mtMeta || {},
    alias: conditionOyyjgMj6mtMeta?.alias || [],
    redirect: conditionOyyjgMj6mtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/terms/condition.vue").then(m => m.default || m)
  },
  {
    name: conditionOyyjgMj6mtMeta?.name ?? "terms-condition___it",
    path: conditionOyyjgMj6mtMeta?.path ?? "/it/terms/condition",
    meta: conditionOyyjgMj6mtMeta || {},
    alias: conditionOyyjgMj6mtMeta?.alias || [],
    redirect: conditionOyyjgMj6mtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/terms/condition.vue").then(m => m.default || m)
  },
  {
    name: conditionOyyjgMj6mtMeta?.name ?? "terms-condition___de",
    path: conditionOyyjgMj6mtMeta?.path ?? "/de/terms/condition",
    meta: conditionOyyjgMj6mtMeta || {},
    alias: conditionOyyjgMj6mtMeta?.alias || [],
    redirect: conditionOyyjgMj6mtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/terms/condition.vue").then(m => m.default || m)
  },
  {
    name: conditionOyyjgMj6mtMeta?.name ?? "terms-condition___es",
    path: conditionOyyjgMj6mtMeta?.path ?? "/es/terms/condition",
    meta: conditionOyyjgMj6mtMeta || {},
    alias: conditionOyyjgMj6mtMeta?.alias || [],
    redirect: conditionOyyjgMj6mtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/terms/condition.vue").then(m => m.default || m)
  },
  {
    name: conditionOyyjgMj6mtMeta?.name ?? "terms-condition___hi",
    path: conditionOyyjgMj6mtMeta?.path ?? "/hi/terms/condition",
    meta: conditionOyyjgMj6mtMeta || {},
    alias: conditionOyyjgMj6mtMeta?.alias || [],
    redirect: conditionOyyjgMj6mtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/terms/condition.vue").then(m => m.default || m)
  },
  {
    name: conditionOyyjgMj6mtMeta?.name ?? "terms-condition___fr",
    path: conditionOyyjgMj6mtMeta?.path ?? "/fr/terms/condition",
    meta: conditionOyyjgMj6mtMeta || {},
    alias: conditionOyyjgMj6mtMeta?.alias || [],
    redirect: conditionOyyjgMj6mtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/terms/condition.vue").then(m => m.default || m)
  },
  {
    name: conditionOyyjgMj6mtMeta?.name ?? "terms-condition___ru",
    path: conditionOyyjgMj6mtMeta?.path ?? "/ru/terms/condition",
    meta: conditionOyyjgMj6mtMeta || {},
    alias: conditionOyyjgMj6mtMeta?.alias || [],
    redirect: conditionOyyjgMj6mtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/terms/condition.vue").then(m => m.default || m)
  },
  {
    name: conditionOyyjgMj6mtMeta?.name ?? "terms-condition___bn",
    path: conditionOyyjgMj6mtMeta?.path ?? "/bn/terms/condition",
    meta: conditionOyyjgMj6mtMeta || {},
    alias: conditionOyyjgMj6mtMeta?.alias || [],
    redirect: conditionOyyjgMj6mtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/terms/condition.vue").then(m => m.default || m)
  },
  {
    name: conditionOyyjgMj6mtMeta?.name ?? "terms-condition___pt",
    path: conditionOyyjgMj6mtMeta?.path ?? "/pt/terms/condition",
    meta: conditionOyyjgMj6mtMeta || {},
    alias: conditionOyyjgMj6mtMeta?.alias || [],
    redirect: conditionOyyjgMj6mtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/terms/condition.vue").then(m => m.default || m)
  },
  {
    name: conditionOyyjgMj6mtMeta?.name ?? "terms-condition___km",
    path: conditionOyyjgMj6mtMeta?.path ?? "/km/terms/condition",
    meta: conditionOyyjgMj6mtMeta || {},
    alias: conditionOyyjgMj6mtMeta?.alias || [],
    redirect: conditionOyyjgMj6mtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/terms/condition.vue").then(m => m.default || m)
  },
  {
    name: uses5hATvK7yDoMeta?.name ?? "terms-uses___en",
    path: uses5hATvK7yDoMeta?.path ?? "/en/terms/uses",
    meta: uses5hATvK7yDoMeta || {},
    alias: uses5hATvK7yDoMeta?.alias || [],
    redirect: uses5hATvK7yDoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/terms/uses.vue").then(m => m.default || m)
  },
  {
    name: uses5hATvK7yDoMeta?.name ?? "terms-uses___id",
    path: uses5hATvK7yDoMeta?.path ?? "/id/terms/uses",
    meta: uses5hATvK7yDoMeta || {},
    alias: uses5hATvK7yDoMeta?.alias || [],
    redirect: uses5hATvK7yDoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/terms/uses.vue").then(m => m.default || m)
  },
  {
    name: uses5hATvK7yDoMeta?.name ?? "terms-uses___tl",
    path: uses5hATvK7yDoMeta?.path ?? "/tl/terms/uses",
    meta: uses5hATvK7yDoMeta || {},
    alias: uses5hATvK7yDoMeta?.alias || [],
    redirect: uses5hATvK7yDoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/terms/uses.vue").then(m => m.default || m)
  },
  {
    name: uses5hATvK7yDoMeta?.name ?? "terms-uses___zh",
    path: uses5hATvK7yDoMeta?.path ?? "/zh/terms/uses",
    meta: uses5hATvK7yDoMeta || {},
    alias: uses5hATvK7yDoMeta?.alias || [],
    redirect: uses5hATvK7yDoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/terms/uses.vue").then(m => m.default || m)
  },
  {
    name: uses5hATvK7yDoMeta?.name ?? "terms-uses___zh-tw",
    path: uses5hATvK7yDoMeta?.path ?? "/zh-tw/terms/uses",
    meta: uses5hATvK7yDoMeta || {},
    alias: uses5hATvK7yDoMeta?.alias || [],
    redirect: uses5hATvK7yDoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/terms/uses.vue").then(m => m.default || m)
  },
  {
    name: uses5hATvK7yDoMeta?.name ?? "terms-uses___th",
    path: uses5hATvK7yDoMeta?.path ?? "/th/terms/uses",
    meta: uses5hATvK7yDoMeta || {},
    alias: uses5hATvK7yDoMeta?.alias || [],
    redirect: uses5hATvK7yDoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/terms/uses.vue").then(m => m.default || m)
  },
  {
    name: uses5hATvK7yDoMeta?.name ?? "terms-uses___ja",
    path: uses5hATvK7yDoMeta?.path ?? "/ja/terms/uses",
    meta: uses5hATvK7yDoMeta || {},
    alias: uses5hATvK7yDoMeta?.alias || [],
    redirect: uses5hATvK7yDoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/terms/uses.vue").then(m => m.default || m)
  },
  {
    name: uses5hATvK7yDoMeta?.name ?? "terms-uses___ms",
    path: uses5hATvK7yDoMeta?.path ?? "/ms/terms/uses",
    meta: uses5hATvK7yDoMeta || {},
    alias: uses5hATvK7yDoMeta?.alias || [],
    redirect: uses5hATvK7yDoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/terms/uses.vue").then(m => m.default || m)
  },
  {
    name: uses5hATvK7yDoMeta?.name ?? "terms-uses___ko",
    path: uses5hATvK7yDoMeta?.path ?? "/ko/terms/uses",
    meta: uses5hATvK7yDoMeta || {},
    alias: uses5hATvK7yDoMeta?.alias || [],
    redirect: uses5hATvK7yDoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/terms/uses.vue").then(m => m.default || m)
  },
  {
    name: uses5hATvK7yDoMeta?.name ?? "terms-uses___vi",
    path: uses5hATvK7yDoMeta?.path ?? "/vi/terms/uses",
    meta: uses5hATvK7yDoMeta || {},
    alias: uses5hATvK7yDoMeta?.alias || [],
    redirect: uses5hATvK7yDoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/terms/uses.vue").then(m => m.default || m)
  },
  {
    name: uses5hATvK7yDoMeta?.name ?? "terms-uses___it",
    path: uses5hATvK7yDoMeta?.path ?? "/it/terms/uses",
    meta: uses5hATvK7yDoMeta || {},
    alias: uses5hATvK7yDoMeta?.alias || [],
    redirect: uses5hATvK7yDoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/terms/uses.vue").then(m => m.default || m)
  },
  {
    name: uses5hATvK7yDoMeta?.name ?? "terms-uses___de",
    path: uses5hATvK7yDoMeta?.path ?? "/de/terms/uses",
    meta: uses5hATvK7yDoMeta || {},
    alias: uses5hATvK7yDoMeta?.alias || [],
    redirect: uses5hATvK7yDoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/terms/uses.vue").then(m => m.default || m)
  },
  {
    name: uses5hATvK7yDoMeta?.name ?? "terms-uses___es",
    path: uses5hATvK7yDoMeta?.path ?? "/es/terms/uses",
    meta: uses5hATvK7yDoMeta || {},
    alias: uses5hATvK7yDoMeta?.alias || [],
    redirect: uses5hATvK7yDoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/terms/uses.vue").then(m => m.default || m)
  },
  {
    name: uses5hATvK7yDoMeta?.name ?? "terms-uses___hi",
    path: uses5hATvK7yDoMeta?.path ?? "/hi/terms/uses",
    meta: uses5hATvK7yDoMeta || {},
    alias: uses5hATvK7yDoMeta?.alias || [],
    redirect: uses5hATvK7yDoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/terms/uses.vue").then(m => m.default || m)
  },
  {
    name: uses5hATvK7yDoMeta?.name ?? "terms-uses___fr",
    path: uses5hATvK7yDoMeta?.path ?? "/fr/terms/uses",
    meta: uses5hATvK7yDoMeta || {},
    alias: uses5hATvK7yDoMeta?.alias || [],
    redirect: uses5hATvK7yDoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/terms/uses.vue").then(m => m.default || m)
  },
  {
    name: uses5hATvK7yDoMeta?.name ?? "terms-uses___ru",
    path: uses5hATvK7yDoMeta?.path ?? "/ru/terms/uses",
    meta: uses5hATvK7yDoMeta || {},
    alias: uses5hATvK7yDoMeta?.alias || [],
    redirect: uses5hATvK7yDoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/terms/uses.vue").then(m => m.default || m)
  },
  {
    name: uses5hATvK7yDoMeta?.name ?? "terms-uses___bn",
    path: uses5hATvK7yDoMeta?.path ?? "/bn/terms/uses",
    meta: uses5hATvK7yDoMeta || {},
    alias: uses5hATvK7yDoMeta?.alias || [],
    redirect: uses5hATvK7yDoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/terms/uses.vue").then(m => m.default || m)
  },
  {
    name: uses5hATvK7yDoMeta?.name ?? "terms-uses___pt",
    path: uses5hATvK7yDoMeta?.path ?? "/pt/terms/uses",
    meta: uses5hATvK7yDoMeta || {},
    alias: uses5hATvK7yDoMeta?.alias || [],
    redirect: uses5hATvK7yDoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/terms/uses.vue").then(m => m.default || m)
  },
  {
    name: uses5hATvK7yDoMeta?.name ?? "terms-uses___km",
    path: uses5hATvK7yDoMeta?.path ?? "/km/terms/uses",
    meta: uses5hATvK7yDoMeta || {},
    alias: uses5hATvK7yDoMeta?.alias || [],
    redirect: uses5hATvK7yDoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/forerunner/pages/terms/uses.vue").then(m => m.default || m)
  }
]