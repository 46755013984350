// @ts-expect-error
import userConfig from '@airpaz/apz-js/dist/master/user-config-map';
import { get } from 'lodash-es';

import { Pinia } from 'pinia';
import { useConfigStore } from '../composables/service/config';
import {
  defineNuxtPlugin,
  useNuxtApp,
  useRequestHeaders,
  useAirpazCookie,
  useAppConfig,
  useRoute,
  useDevice,
} from '#imports';

const storeLangMap = {
  en: 'us',
  th: 'th',
  zh: 'cn',
  'zh-tw': 'tw',
  id: 'id',
  ja: 'jp',
  ko: 'kr',
  vi: 'vn',
  hi: 'in',
  it: 'it',
  ms: 'my',
  tl: 'ph',
  fr: 'fr',
  de: 'de',
  es: 'es',
  ru: 'ru',
  bn: 'in',
  pt: 'pt',
  km: 'km',
};

const getStoreUrlByLanguage = (language: string) => {
  const country = get(storeLangMap, language, 'us');

  return {
    appstoreUrl: `https://apps.apple.com/${country}/app/airpaz-flights-hotels/1066890405`,
    playstoreUrl: `https://play.google.com/store/apps/details?id=com.atnetwork.airpazdev&hl=${language}&gl=${country}`,
  };
};

const getDefaultConfigByCountry = (country: string) => {
  return userConfig.find((el: any) => el.country === country) as
    | { country: string; lang: string; currency: string }
    | undefined;
};

export default defineNuxtPlugin(async () => {
  const nuxtApp = useNuxtApp();
  const config = useConfigStore(nuxtApp.$pinia as Pinia);
  const route = useRoute();
  const device = useDevice();

  if (nuxtApp.ssrContext) {
    const headers = useRequestHeaders();

    let country =
      useAirpazCookie('force_country').value ||
      headers.HTTP_CF_IPCOUNTRY ||
      headers['cf-ipcountry'] ||
      useAirpazCookie('preferred_country').value ||
      useAppConfig().defaults.country;

    country = country.toUpperCase();

    if (['XX', 'T1'].includes(country) && !device.isCrawler) {
      const preferredCountry = useAirpazCookie('preferred_country').value;

      if (preferredCountry) {
        country = preferredCountry;
      } else if (!route.fullPath.includes('choose-country')) {
        let path = `/${useAppConfig().defaults.language}/choose-country`;
        const referer = encodeURIComponent(route.fullPath);
        path += referer ? `?referer=${referer}` : '';
        navigateTo(path);
      } else {
        return;
      }
    }

    const defaultConfigByCountry = getDefaultConfigByCountry(country);

    let currency =
      useAirpazCookie('Currency').value || defaultConfigByCountry?.currency || useAppConfig().defaults.currency;

    try {
      if (route.query && route.query.sharedFlight) {
        const sharedObj = JSON.parse(
          decodeURIComponent(
            safeAtob((route.query.sharedFlight as string).replace(/-/g, '+').replace(/_/g, '/'))
              .split('')
              .map((char) => '%' + ('00' + char.charCodeAt(0).toString(16)).slice(-2))
              .join(''),
          ),
        );
        if (sharedObj && sharedObj.a && currency !== sharedObj.a) {
          currency = sharedObj.a;
        }
      }
    } catch (error) {}

    const { appstoreUrl, playstoreUrl } = getStoreUrlByLanguage(nuxtApp.$i18n.locale.value.toLowerCase());

    // @ts-expect-error
    const currencies = (await import('@airpaz/apz-js/dist/master/en/currency-list.js')).default;

    if (!currencies[currency]) {
      currency = defaultConfigByCountry?.currency || useAppConfig().defaults.currency;
    }

    const currencyConfig = currencies[currency];

    config.$patch({
      ip: nuxtApp.ssrContext.event.node.req.socket.remoteAddress,
      country,
      currency,
      currencyConfig,
      appstoreUrl,
      playstoreUrl,
    });
  } else {
    config.syncFeatureFlags();
  }
});
