import { JitsuClient } from '@jitsu/sdk-js';
import dayjs from 'dayjs';
import Mutex from '@/lib/mutex';
import {
  nextTick,
  useRuntimeConfig,
  useRouter,
  useRoute,
  useAirpazCookie,
  useConfigStore,
  useTrafficCookie,
  useTrafficSession,
  useDevice,
  defineNuxtPlugin,
  defineTrafficSource,
  delay,
  useRouteBaseName,
  useNuxtApp,
} from '#imports';

let jitsuJts: JitsuClient;
let jitsuReady = false;

const mutex = new Mutex();

export default defineNuxtPlugin(() => {
  const nuxtApp = useNuxtApp();
  const runtimeConfig = useRuntimeConfig();
  const router = useRouter();
  const auid = useAUID();
  const sessionId = useSessionId();
  const config = useConfigStore();
  const route = useRoute();
  const trafficCookie = useTrafficCookie();
  const trafficSession = useTrafficSession();
  const userCoordsCookie = useAirpazCookie('usr_coords');
  const getRouteBaseName = useRouteBaseName();
  const device = useDevice();

  async function jitsuTrack(eventType: string, params?: any) {
    try {
      const [longitude, latitude] = userCoordsCookie.value?.split(',') ?? [];

      params = {
        occured_at: dayjs().unix() || '0000-00-00 00:00:00',
        session_id: sessionId.value,
        auid: auid.value,
        country: config.country,
        fingerprint: '',
        currency: config.currency,
        language: nuxtApp.$i18n.locale.value,
        user_id: '',
        user_email: '',
        user_latitude: latitude ?? '',
        user_longitude: longitude ?? '',
        page_url: window.location.href,
        referrer: '',
        campaign_id: trafficCookie.ads?.value?.a || '',
        campaign_ads_group: trafficCookie.ads?.value?.b || '',
        referral_ads_parameter: trafficSession.ads.value || '',
        referral_affiliate_parameter: trafficSession.affiliate.value?.id || '',
        traffic_source: defineTrafficSource(trafficCookie.ads.value, trafficCookie.affiliate.value).source || 'DIRECT',
        utm: createUtmObject(trafficCookie.utm.value ?? route.query),
        utm_session: createUtmObject(trafficSession.utm.value ?? route.query),
        source_type: '',
        screen_name: getRouteBaseName(route) === 'index' ? 'home' : (getRouteBaseName(route) as string),
        user_agent: navigator.userAgent,
        platform: 'web',
        referral: defineTrafficSource(trafficSession.ads.value, trafficSession.affiliate.value).source || 'DIRECT',
        device_ui: device.isMobile ? 'mobile' : 'desktop',
        device_id: '',
        device_ip_longitude: '',
        device_ip_latitude: '',
        event_type: eventType,
        event_name: params.event_name ?? eventType,
        ...params,
      };

      if (trafficCookie.ads.value?.surl) {
        params.surl = trafficCookie.ads.value.surl;
        params.referer = trafficCookie.ads.value.referer || 'airpaz.link';
      } else if (trafficCookie.affiliate.value?.surl) {
        params.surl = trafficCookie.affiliate.value.surl;
      }
    } catch (e) {}

    if (process.dev) {
      console.warn('[jitsu]', { eventType, params });

      return;
    }

    if (!jitsuReady) {
      await mutex.lock();
    }

    if (!jitsuReady) {
      await delay(3000);

      const { jitsuClient } = await import('@jitsu/sdk-js');

      jitsuJts = jitsuClient({
        key: runtimeConfig.public.jitsuJtsToken,
        tracking_host: runtimeConfig.public.jitsuJtsHost,
        randomize_url: true,
      });

      jitsuReady = true;

      while (mutex.isLocked()) {
        mutex.unlock();
      }
    }

    await jitsuJts?.track(eventType, params).catch(() => null);
  }

  router.isReady().then(() => {
    router.afterEach((from, to) => {
      nextTick(() => {
        const payload: Record<string, any> = { screen_name: to.name };

        if (['login', 'signup'].includes(getRouteBaseName(to) ?? '')) payload.utm_source = getRouteBaseName(from);

        if (getRouteBaseName(to) === 'signup-setup-account') payload.utm_source = to.query.source;

        jitsuTrack('page-view', payload);
      });
    });
  });

  return {
    provide: {
      jitsuTrack,
    },
  };
});

const createUtmObject = (params: any) =>
  Object.entries(params).reduce((utm, [key, value]) => {
    if (key.startsWith('utm_')) {
      key = key.replace('utm_', '');

      if (key === 'source' && (value === 'fun' || value === 'funnel')) {
        value = params.utm_campaign_medium || params.utm_medium;
      }

      // @ts-expect-error
      utm[key] = value;
    }

    return utm;
  }, {});
