class Mutex {
  private locked = false;
  private queue: (() => void)[] = [];

  isLocked() {
    return this.locked;
  }

  lock() {
    return new Promise<void>((resolve) => {
      const acquireLock = () => {
        if (!this.locked) {
          this.locked = true;
          resolve();
        } else {
          this.queue.push(acquireLock);
        }
      };
      acquireLock();
    });
  }

  unlock() {
    if (this.locked) {
      this.locked = false;
      const next = this.queue.shift();
      if (next) {
        next();
      }
    }
  }
}

export default Mutex;
