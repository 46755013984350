import dayjs from 'dayjs';
import jwtDecode from 'jwt-decode';
import { set } from 'lodash-es';
import { FetchOptions } from 'ofetch';
import { ExpiredAuthSessionError } from '../../utils/errors';
import { useNuxtApp, callWithNuxt, NuxtApp } from '#app';
import { Service } from '@/types/service';
import {
  defineStore,
  ref,
  nextTick,
  useRuntimeConfig,
  useAirpazCookie,
  useNavigation,
  useRoute,
  useSwitchCurrency,
  isStringEqual,
  stringifyQuery,
} from '#imports';

import { User, AuthStrategy } from '@/types/auth';
import { UserContact, UserTraveler, UserSocialMediaType } from '@/types/user';

export const useAuthService = () => {
  const { $apifront } = useNuxtApp();

  const login = (body: { email?: string; password: string; mobile?: string }) =>
    $apifront<
      Service<{
        email?: string;
        mobile?: string;
        type: string;
        level?: number;
        name: string;
        lang: string;
        currency: string;
        token: string;
        refreshToken: string;
        expiresIn: number;
      }>
    >('/member/login', {
      method: 'post',
      body: { ...body, userAgent: window.navigator.userAgent },
      withCSRF: true,
      v2: true,
    });

  const loginSocialMedia = (body: { smId: string }) =>
    $apifront<Service<{ token: string; refreshToken: string; expiresIn: number }>>('/member/login', {
      method: 'post',
      body,
      withCSRF: true,
    });

  const logout = () => $apifront('/member/signout', { method: 'post', checkAuth: true, v2: true });

  const refreshToken = (refreshToken: string) =>
    $apifront<Service<{ token: string; refreshToken: string; expiresIn: number }>>('/member/refresh-token', {
      method: 'post',
      body: { refreshToken },
      v2: true,
    });

  const resetPasswordRequest = (body: { email?: string; mobile?: string; mobileCode?: string; lang: string }) =>
    $apifront<Service<{ expiredAt: string; tzOffset: number; allowResendAt: string }>>('/member/reset-pass/request', {
      method: 'post',
      body,
      withCSRF: true,
      v2: true,
    });

  const resetPassword = (body?: { code: string; password: string; userAgent: string }) =>
    $apifront<Service<{ token: string; refreshToken: string; expiresIn: number }>>('/member/reset-password', {
      method: 'post',
      body,
      withCSRF: true,
      v2: true,
    });

  const signup = (body: { email?: string; mobile?: string; mobileCode?: string; lang: string }) =>
    $apifront<Service<{ expiredAt: string; tzOffset: number; allowResendAt: string }>>('/member/request-signup', {
      method: 'post',
      body,
      withCSRF: true,
      v2: true,
    });

  const resendActivationCode = (body: { email?: string; mobile?: string; mobileCode?: string; lang: string }) =>
    $apifront<Service<{ expiresAt: string; tzOffset: number; allowResendAt: string }>>(
      '/member/resend-activation-code',
      {
        method: 'post',
        body,
        withCSRF: true,
        v2: true,
      },
    );

  const resendActivationCodeMember = (body: { email?: string; mobile?: string; lang: string }, checkAuth = true) =>
    $apifront<Service<{ expiresAt: string; tzOffset: number; allowResendAt: string }>>(
      '/member/resend-login-method-verification',
      {
        method: 'post',
        body,
        withCSRF: true,
        v2: true,
        checkAuth,
      },
    );

  const resendActivationCodeReset = (body: { email?: string; mobile?: string; mobileCode?: string; lang: string }) =>
    $apifront<Service<{ expiresAt: string; tzOffset: number; allowResendAt: string }>>(
      '/member/reset-pass/resend-code',
      {
        method: 'post',
        body,
        withCSRF: true,
        v2: true,
      },
    );

  const verifyActivationCode = (body: { code: string; identifier: string }) =>
    $apifront<Service<{ status: boolean }>>('/member/verify-activation-code', {
      method: 'post',
      body,
      withCSRF: true,
      v2: true,
    });

  const verifyActivationCodeMember = (body: { code: string; identifier: string }, checkAuth = true) =>
    $apifront<Service<{ status: boolean }>>('/member/verify-login-method', {
      method: 'post',
      body,
      withCSRF: true,
      v2: true,
      checkAuth,
    });

  const verifyActivationCodeReset = (body: { code: string; identifier: string }) =>
    $apifront<Service<{ status: boolean }>>('/member/reset-pass/verify', {
      method: 'post',
      body,
      withCSRF: true,
      v2: true,
    });

  const finalizeSignup = (body: {
    type: string;
    code: string;
    email: string;
    mobile: string;
    mobileCode: string;
    name: string;
    title: string;
    password: string;
    country: string;
    lang: string;
    isSubscribe: boolean;
  }) =>
    $apifront<Service<{ status: boolean }>>('/member/signup', {
      method: 'post',
      body,
      withCSRF: true,
      v2: true,
    });

  const finalizeResetPassword = (body?: { code: string; identifier: string; password: string }) =>
    $apifront<
      Service<
        Pick<User, 'name' | 'email' | 'mobile' | 'type' | 'level' | 'lang' | 'currency'> & {
          token: string;
          refreshToken: string;
          expiresIn: number;
        }
      >
    >('/member/reset-pass', {
      method: 'post',
      body,
      withCSRF: true,
      v2: true,
    });

  const requestVerifyMember = (body: { email?: string; mobile?: string; lang: string }, checkAuth = true) =>
    $apifront<Service<{ expiresAt: string; tzOffset: number; allowResendAt: string }>>('/member/request-login-method', {
      method: 'post',
      body,
      withCSRF: true,
      v2: true,
      checkAuth,
    });

  const getUser = () => $apifront<Service<User>>('/member/get', { checkAuth: true });

  const getContacts = () => $apifront<Service<UserContact[]>>('/member/contact-list', { checkAuth: true });

  const addContact = (body: Omit<UserContact, 'status' | 'id'>) =>
    $apifront<Service<boolean>>('/member/contact-add', {
      method: 'post',
      body,
      withCSRF: true,
      checkAuth: true,
    });

  const updateContact = (body: Omit<UserContact, 'status'>) =>
    $apifront<Service<boolean>>('/member/contact-update', {
      method: 'put',
      body,
      withCSRF: true,
      checkAuth: true,
    });

  const deleteContact = (body: { id: string }) =>
    $apifront<Service<boolean>>(`/member/contact-delete`, {
      method: 'delete',
      body,
      withCSRF: true,
      checkAuth: true,
    });

  const getPassengers = () => $apifront<Service<UserTraveler[]>>('/member/passenger-list', { checkAuth: true });

  const addPassengers = (body: Omit<UserTraveler, 'status' | 'id'>) =>
    $apifront<Service<boolean>>('/member/passenger-add', {
      method: 'post',
      body,
      withCSRF: true,
      checkAuth: true,
    });

  const updatePassengers = (body: Omit<UserTraveler, 'status'>) =>
    $apifront<Service<boolean>>('/member/passenger-update', {
      method: 'put',
      body,
      withCSRF: true,
      checkAuth: true,
    });

  const deletePassengers = (body: { id: string }) =>
    $apifront<Service<boolean>>(`/member/passenger-delete`, {
      method: 'delete',
      body,
      withCSRF: true,
      checkAuth: true,
    });

  const bindAccount = (body: { email?: string; smId: string; accessToken: string; tokenId: string }) =>
    $apifront<Service<boolean>>('/member/bind', {
      method: 'post',
      body,
      withCSRF: true,
      checkAuth: true,
    });

  const unbindAccount = (sm: UserSocialMediaType) =>
    $apifront<Service<boolean>>('/member/unbind?sm=' + sm, {
      method: 'delete',
      withCSRF: true,
      checkAuth: true,
    });

  return {
    login,
    loginSocialMedia,
    logout,
    refreshToken,
    getUser,
    resetPasswordRequest,
    resetPassword,
    verifyActivationCode,
    finalizeResetPassword,
    signup,
    resendActivationCode,
    finalizeSignup,
    resendActivationCodeMember,
    verifyActivationCodeMember,
    resendActivationCodeReset,
    verifyActivationCodeReset,
    requestVerifyMember,
    getContacts,
    addContact,
    updateContact,
    deleteContact,
    getPassengers,
    addPassengers,
    updatePassengers,
    deletePassengers,
    bindAccount,
    unbindAccount,
  };
};

export const useAuth = defineStore('auth', () => {
  const runtimeConfig = useRuntimeConfig();
  const switchCurrency = useSwitchCurrency();
  const { navigate } = useNavigation();

  const oath2ProviderOptions = {
    facebook: {
      endpoint: 'https://facebook.com/v13.0/dialog/oauth',
      client_id: runtimeConfig.public.facebookClientId,
      scope: ['public_profile', 'email'],
    },
    google: {
      endpoint: 'https://accounts.google.com/o/oauth2/v2/auth',
      client_id: runtimeConfig.public.googleClientId,
      scope: [
        'https://www.googleapis.com/auth/userinfo.email',
        'https://www.googleapis.com/auth/userinfo.profile',
        'openid',
      ],
      code_challenge_method: '',
      response_type: 'id_token token',
    },
    apple: {
      endpoint: 'https://appleid.apple.com/auth/authorize',
      client_id: runtimeConfig.public.appleClientId,
      scope: [],
      response_type: 'id_token code',
      response_mode: 'fragment',
    },
  };

  const isBusy = ref(true);
  const loggedIn = ref(false);
  const user = ref<(User & { isAgent: boolean }) | null>(null);
  const redirectTo = useAirpazCookie<string | null>('auth.redirect');

  async function fetchUser() {
    const { result } = await useAuthService().getUser();

    user.value = { ...result, isAgent: isStringEqual(result.type, 'AGENT') };
    loggedIn.value = true;
    isBusy.value = false;
  }

  function signInWith(strategy: 'google' | 'facebook' | 'apple') {
    const { endpoint, ...options } = oath2ProviderOptions[strategy];

    const opts = {
      protocol: 'oauth2',
      response_type: 'token',
      access_type: '',
      redirect_uri: window.location.origin + '/callback',
      code_challenge_method: 'implicit',
      state: _randomString(),
      nonce: _randomString(),
      ...options,
      scope: options.scope.join(' '),
    };

    setStrategy(strategy);
    setStrategyState(strategy, opts.state);

    const oauthUrl = endpoint + '?' + stringifyQuery(opts);

    window.location.replace(oauthUrl);
  }

  async function signIn(params: {
    email?: string;
    password: string;
    smId?: string;
    accessToken?: string;
    tokenId?: string;
    userAgent?: string;
    mobile?: string;
    mobileCode?: string;
  }) {
    const { result } = await useAuthService().login(params);

    await setTokens(result.token, result.refreshToken);

    setStrategy('airpaz');

    await nextTick(async () => {
      await fetchUser();

      if (user.value!.isAgent && user.value!.currency) switchCurrency(user.value!.currency);

      const redirect = redirectTo.value;
      redirectTo.value = null;

      await navigate(redirect || '/', { externalAirpaz: true });
    });
  }

  async function signOut() {
    loggedIn.value = false;
    user.value = null;
    redirectTo.value = null;

    await useAuthService()
      .logout()
      .catch(() => null);
    await resetTokens();
    resetStrategy();

    await navigate('/', { externalAirpaz: true });
  }

  async function reset() {
    loggedIn.value = false;
    user.value = null;
    redirectTo.value = null;

    await resetTokens();
    resetStrategy();
  }

  async function resetAccount(params: { email: string; password: string }) {
    loggedIn.value = false;
    user.value = null;

    await useAuthService()
      .logout()
      .catch(() => null);
    await resetTokens();
    resetStrategy();

    await signIn(params);
  }

  return {
    isBusy,
    loggedIn,
    user,
    redirectTo,
    signIn,
    signInWith,
    signOut,
    fetchUser,
    reset,
    resetAccount,
  };
});

export const navigateSignIn = (query?: { [key: string]: any }, disableRedirectTo = false) => {
  const auth = useAuth();

  auth.redirectTo = disableRedirectTo ? '/' : useRoute().fullPath.replace(/^\/(\w\w|\w\w-\w\w)(\/?|\/.*)$/gi, '$2');

  return useNavigation().navigate({ path: '/login', query }, { externalAirpaz: true });
};

const _randomString = () => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  let result = '';
  const charactersLength = characters.length;
  for (let i = 0; i < 10; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const _getTokenExpiration = (token: string, fallbackExpirationDays = 30) => {
  const fallbackExpiration = dayjs().add(fallbackExpirationDays, 'day').valueOf();
  let tokenExpiration;

  try {
    tokenExpiration = jwtDecode<any>(token).exp * 1000 || fallbackExpiration;
  } catch (error) {
    tokenExpiration = fallbackExpiration;
  }

  return tokenExpiration;
};

export const getStrategyState = (strategy: AuthStrategy) => useAirpazCookie(`${strategy}.state`);

export const setStrategyState = (strategy: AuthStrategy, state: string) => {
  getStrategyState(strategy).value = state;
};

export const resetStrategyState = (strategy: AuthStrategy) => {
  getStrategyState(strategy).value = null;
};

export const getStrategy = () => useAirpazCookie<AuthStrategy | null>('auth.strategy');

export const setStrategy = (strategy: AuthStrategy) => {
  getStrategy().value = strategy;
};

export const resetStrategy = () => {
  getStrategy().value = 'airpaz';
};

export const getTokens = (nuxtApp: NuxtApp = useNuxtApp()) => {
  return callWithNuxt(nuxtApp, () => {
    const token = useAirpazCookie('auth._token.airpaz');
    const tokenExpiration = useAirpazCookie<number | null>('auth._token_expiration.airpaz');
    const refreshToken = useAirpazCookie('auth._refresh_token.airpaz');
    const refreshTokenExpiration = useAirpazCookie<number | null>('auth._refresh_token_expiration.airpaz');

    return {
      token,
      tokenExpiration,
      refreshToken,
      refreshTokenExpiration,
    };
  });
};

export const setTokens = async (newToken: string, newRefreshToken: string, nuxtApp: NuxtApp = useNuxtApp()) => {
  const { token, tokenExpiration, refreshToken, refreshTokenExpiration } = await getTokens(nuxtApp);

  token.value = 'Bearer ' + newToken;
  tokenExpiration.value = _getTokenExpiration(newToken);
  refreshToken.value = newRefreshToken;
  refreshTokenExpiration.value = _getTokenExpiration(newRefreshToken, 15);
};

export const resetTokens = async (nuxtApp: NuxtApp = useNuxtApp()) => {
  const { token, tokenExpiration, refreshToken, refreshTokenExpiration } = await getTokens(nuxtApp);

  token.value = null;
  tokenExpiration.value = null;
  refreshToken.value = null;
  refreshTokenExpiration.value = null;
};

export const injectAuthorizationHeader = (options: FetchOptions, token: string) => {
  if (token) {
    set(options, ['headers', 'Authorization'], token);
  }

  return options;
};

export const isTokenValid = (token: string | null, expiration: number | null) => {
  const now = dayjs().valueOf();

  if (!token) {
    return false;
  }

  if (!expiration) {
    expiration = _getTokenExpiration(token);
  }

  // Give us some slack to help the token from expiring between validation and usage
  const timeSlackMillis = 500;
  expiration -= timeSlackMillis;

  // Token is still valid
  if (now < expiration) {
    return true;
  }

  return false;
};

export const refreshTokens = async (nuxtApp: NuxtApp = useNuxtApp()) => {
  const { token, refreshToken, refreshTokenExpiration } = await getTokens(nuxtApp);

  if (!token.value || !refreshToken.value) {
    return;
  }

  if (!isTokenValid(refreshToken.value, Number(refreshTokenExpiration.value))) {
    await resetTokens(nuxtApp);

    throw new ExpiredAuthSessionError('Invalid refresh token on refreshing');
  }

  const { result } = await useAuthService().refreshToken(refreshToken.value);

  await setTokens(result.token, result.refreshToken, nuxtApp);
};
