<script setup lang="ts">
import { useHead, useRuntimeConfig, useDevice, useRouteBaseName, useRoute } from '#imports';

const runtimeConfig = useRuntimeConfig();
const device = useDevice();
const route = useRoute();
const getRouteBaseName = useRouteBaseName();

useHead(() => {
  return {
    meta: [
      {
        name: 'viewport',
        content:
          'width=device-width, initial-scale=1, viewport-fit=cover' + (device.isMobile ? ', maximum-scale=1' : ''),
      },
    ],
    link: [
      {
        rel: 'dns-prefetch',
        href: runtimeConfig.public.apiBase.replace('/v1', ''),
      },
      {
        rel: 'dns-prefetch',
        href: runtimeConfig.public.cdnUrl,
      },
      {
        rel: 'preconnect',
        href: 'https://www.googletagmanager.com',
      },
    ],
  };
});
</script>

<template>
  <NuxtLayout
    :name="getRouteBaseName(route)?.includes('aftersales') ? 'mobile' : $device.isMobile ? 'mobile' : 'default'"
  >
    <NuxtPage />
    <ApzToast
      :full-width="$device.isMobile"
      :class="[$device.isMobile ? '!top-[72px]' : '!top-[87px]']"
    />
  </NuxtLayout>
</template>
