import { getTokens, resetTokens, useAuth } from '../composables/service/auth';
import { defineNuxtPlugin, callWithNuxt } from '#app';
import {
  watch,
  addRouteMiddleware,
  useLocalePath,
  useRoute,
  useRuntimeConfig,
  useRouteBaseName,
  useDevice,
  navigateTo,
  onNuxtReady,
} from '#imports';

export default defineNuxtPlugin((nuxtApp) => {
  // Skip plugin when rendering error page
  if (nuxtApp.payload.error) {
    return {};
  }

  const route = useRoute();
  const auth = useAuth();
  const device = useDevice();
  const localePath = useLocalePath();
  const runtimeConfig = useRuntimeConfig();
  const getRouteBaseName = useRouteBaseName();

  addRouteMiddleware(
    'auth',
    (to) => {
      if (to.meta.auth && !auth.loggedIn) {
        return localePath('login');
      }
    },
    { global: true },
  );

  watch(
    () => auth.loggedIn,
    async (loggedIn) => {
      if (!loggedIn && route.meta.auth) {
        await navigateTo(localePath('login'), { replace: true });

        if (nuxtApp.$sentry) {
          // @ts-expect-error
          nuxtApp.$sentry.setUser({ is_member: false, is_agent: false });
        }
      }
    },
  );

  async function initAuth() {
    const { token, tokenExpiration, refreshToken, refreshTokenExpiration } = await getTokens(nuxtApp as any);

    if (!token.value) {
      if (tokenExpiration.value || refreshToken.value || refreshTokenExpiration.value) {
        await resetTokens(nuxtApp as any);
      }

      auth.isBusy = false;

      return;
    }

    try {
      await callWithNuxt(nuxtApp, auth.fetchUser);
    } catch (e) {
      await resetTokens(nuxtApp as any);
    }
  }

  function initGoogleOneTapApi() {
    // @ts-expect-error
    if (window.google || getRouteBaseName(route) !== 'index' || device.isCrawler) {
      return;
    }

    const script = document.createElement('script');

    script.src = 'https://accounts.google.com/gsi/client';
    script.defer = true;
    script.onload = function () {
      // @ts-expect-error
      if (!auth.loggedIn && typeof window.google?.accounts !== 'undefined') {
        // @ts-expect-error
        window.google?.accounts.id.initialize({
          client_id: runtimeConfig.public.googleClientId,
          cancel_on_tap_outside: true,
          state_cookie_domain: process.dev ? '' : '.airpaz.com',
          callback: async (res: any) => {
            let parsedCredential: any;

            try {
              const jwtDecode = (await import('jwt-decode')).default;

              parsedCredential = jwtDecode(res.credential);

              await auth.signIn({
                email: '',
                password: '',
                smId: 'go' + parsedCredential.sub,
                tokenId: res.credential,
                userAgent: navigator.userAgent,
              });
            } catch (e) {}
          },
        });

        // @ts-expect-error
        window.google.accounts.id.prompt();
      }
    };

    document.head.appendChild(script);
  }

  onNuxtReady(initGoogleOneTapApi);

  if (!auth.loggedIn) {
    return initAuth();
  }
});
